<template>
  <div class="page-container">
    <div class="cards-container">
      <div class="img-container">
        <NText :strong="true">{{ team.name }}</NText>
        <img :src="`https://cdn.ssref.net/req/202312151/tlogo/ncaa/${team.code}-2024.png`">
      </div>
      <DataTable
        :data="averagesTableData"
        :columns="averagesTableColumns"
        :loading="averagesTableLoading"
      />
    </div>
  </div>
</template>

<script setup>
/* eslint-disable */
import { h, ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { NText, NRadio, NSpace } from 'naive-ui';

import { teams } from '@/assets/cbb_data';
import { scatterChart } from '@/charts/scatter-chart';
import { formatName, formatTeam } from '@/util/util_nba';
import DataTable from '@/components/DataTable';

const route = useRoute();
const { id } = route.params;
const team = teams.find((team) => team.code === id);

const apiData = ref({});
const averagesTableData = ref([]);
const averagesTableColumns = ref([]);
const averagesTableLoading = ref(true);

const setAveragesTable = () => {
  averagesTableData.value = apiData.value?.averages;
  averagesTableColumns.value = [
    { title: 'Stat', key: 'stat', align: 'right' },
    { title: 'Season (Avg)', key: 'avg', align: 'center' },
    { title: 'Last 10 (Avg)', key: 'L10Avg', align: 'center' },
    { title: 'Last 5 (Avg)', key: 'L5Avg', align: 'center' },
  ];
  averagesTableLoading.value = false;
};

const api = async () => {
  const endpoint = process.env.VUE_APP_INTERNAL_API_ENDPOINT;
  const params = new URLSearchParams({
    id: team.code,
  });

  const url = `${endpoint}/api/cbb/team?${params}`;
  const response = await fetch(url);

  return await response.json();
};

const tryFetch = async () => {
  const data = await api();
  apiData.value = data;
  setAveragesTable();
};

onMounted(async () => {
  await tryFetch();
});
</script>

<style scoped>
img {
  margin-left: 10px;
}
.img-container {
  height: 125px;
  border-bottom: 2px solid #3f3f3f;
}
</style>