export const teams = [
  { code: 'abilene-christian', name: 'Abilene Christian', logo: 'https://1000logos.net/wp-content/uploads/2021/06/Abilene-Christian-Wildcats-logo.png' },
  { code: 'air-force', name: 'Air Force', logo: 'https://1000logos.net/wp-content/uploads/2021/06/Air-Force-Falcons-logo.png' },
  { code: 'akron', name: 'Akron' },
  { code: 'alabama', name: 'Alabama' },
  { code: 'alabama-am', name: 'Alabama A&M' },
  { code: 'alabama-state', name: 'Alabama State' },
  { code: 'albany-ny', name: 'Albany (NY)', conference: 'American East' },
  { code: 'alcorn-state', name: 'Alcorn State', conference: 'Southwestern Athletic' },
  { code: 'american', name: 'American' },
  { code: 'appalachian-state', name: 'Appalachian State' },
  { code: 'arizona', name: 'Arizona' },
  { code: 'arizona-state', name: 'Arizona State' },
  { code: 'arkansas', name: 'Arkansas' },
  { code: 'arkansas-state', name: 'Arkansas State' },
  { code: 'arkansas-pine-bluff', name: 'Arkansas-Pine Bluff' },
  { code: 'army', name: 'Army' },
  { code: 'auburn', name: 'Auburn' },
  { code: 'austin-peay', name: 'Austin Peay' },

  { code: 'ball-state', name: 'Ball State' },
  { code: 'baylor', name: 'Baylor' },
  { code: 'bellarmine', name: 'Bellarmine' },
  { code: 'belmont', name: 'Belmont' },
  { code: 'bethune-cookman', name: 'Bethune-Cookman' },
  { code: 'binghamton', name: 'Binghamton' },
  { code: 'boise-state', name: 'Boise State' },
  { code: 'boston-college', name: 'Boston College' },
  { code: 'boston-university', name: 'Boston University' },
  { code: 'bowling-green-state', name: 'Bowling Green State' },
  { code: 'bradley', name: 'Bradley' },
  { code: 'brigham-young', name: 'Brigham Young' },
  { code: 'brown', name: 'Brown' },
  { code: 'bryant', name: 'Bryant' },
  { code: 'bucknell', name: 'Bucknell' },
  { code: 'buffalo', name: 'Buffalo' },
  { code: 'butler', name: 'Butler' },

  { code: 'cal-poly', name: 'Cal Poly' },
  { code: 'cal-state-bakersfield', name: 'Cal State Bakersfield' },
  { code: 'cal-state-fullerton', name: 'Cal State Fullerton' },
  { code: 'cal-state-northridge', name: 'Cal State Northridge' },
  { code: 'california', name: 'California' },
  { code: 'california-baptist', name: 'California Baptist' },
  { code: 'campbell', name: 'Campbell' },
  { code: 'canisius', name: 'Canisius' },
  { code: 'central-arkansas', name: 'Central Arkansas' },
  { code: 'central-connecticut-state', name: 'Central Connecticut State' },
  { code: 'central-michigan', name: 'Central Michigan' },
  { code: 'charleston-southern', name: 'Charleston Southern' },
  { code: 'charlotte', name: 'Charlotte' },
  { code: 'chattanooga', name: 'Chattanooga' },
  { code: 'chicago-state', name: 'Chicago State' },
  { code: 'cincinnati', name: 'Cincinnati' },
  { code: 'clemson', name: 'Clemson' },
  { code: 'cleveland-state', name: 'Cleveland State' },
  { code: 'coastal-carolina', name: 'Coastal Carolina' },
  { code: 'colgate', name: 'Colgate' },
  { code: 'college-of-charleston', name: 'College of Charleston' },
  { code: 'colorado', name: 'Colorado' },
  { code: 'colorado-state', name: 'Colorado State' },
  { code: 'columbia', name: 'Columbia' },
  { code: 'connecticut', name: 'Connecticut' },
  { code: 'coppin-state', name: 'Coppin State' },
  { code: 'cornell', name: 'Cornell' },
  { code: 'creighton', name: 'Creighton' },

  { code: 'dartmouth', name: 'Dartmouth' },
  { code: 'davidson', name: 'Davidson' },
  { code: 'dayton', name: 'Dayton' },
  { code: 'delaware', name: 'Delaware' },
  { code: 'delaware-state', name: 'Delaware State' },
  { code: 'denver', name: 'Denver' },
  { code: 'depaul', name: 'DePaul' },
  { code: 'detroit-mercy', name: 'Detroit Mercy' },
  { code: 'drake', name: 'Drake' },
  { code: 'drexel', name: 'Drexel' },
  { code: 'duke', name: 'Duke' },
  { code: 'duquesne', name: 'Duquesne' },

  { code: 'east-carolina', name: 'East Carolina' },
  { code: 'east-tennessee-state', name: 'East Tennessee State' },
  { code: 'eastern-illinois', name: 'Eastern Illinois' },
  { code: 'eastern-kentucky', name: 'Eastern Kentucky' },
  { code: 'eastern-michigan', name: 'Eastern Michigan' },
  { code: 'eastern-washington', name: 'Eastern Washington' },
  { code: 'elon', name: 'Elon' },
  { code: 'evansville', name: 'Evansville' },

  { code: 'fairfield', name: 'Fairfield' },
  { code: 'fairleigh-dickinson', name: 'FDU' },
  { code: 'florida', name: 'Florida' },
  { code: 'florida-am', name: 'Florida A&M' },
  { code: 'florida-atlantic', name: 'Florida Atlantic' },
  { code: 'florida-gulf-coast', name: 'Florida Gulf Coast' },
  { code: 'florida-international', name: 'Florida International' },
  { code: 'florida-state', name: 'Florida State' },
  { code: 'fordham', name: 'Fordham' },
  { code: 'fresno-state', name: 'Fresno State' },
  { code: 'furman', name: 'Furman' },

  { code: 'gardner-webb', name: 'Gardner-Webb' },
  { code: 'george-mason', name: 'George Mason' },
  { code: 'george-washington', name: 'George Washington' },
  { code: 'georgetown', name: 'Georgetown' },
  { code: 'georgia', name: 'Georgia' },
  { code: 'georgia-southern', name: 'Georgia Southern' },
  { code: 'georgia-state', name: 'Georgia State' },
  { code: 'georgia-tech', name: 'Georgia Tech' },
  { code: 'gonzaga', name: 'Gonzaga' },
  { code: 'grambling', name: 'Grambling' },
  { code: 'grand-canyon', name: 'Grand Canyon' },
  { code: 'green-bay', name: 'Green Bay' },

  { code: 'hampton', name: 'Hampton' },
  { code: 'harvard', name: 'Harvard' },
  { code: 'hawaii', name: 'Hawaii' },
  { code: 'high-point', name: 'High Point' },
  { code: 'hofstra', name: 'Hofstra' },
  { code: 'holy-cross', name: 'Holy Cross' },
  { code: 'houston', name: 'Houston' },
  { code: 'houston-baptist', name: 'Houston Christian' },
  { code: 'howard', name: 'Howard' },

  { code: 'idaho', name: 'Idaho' },
  { code: 'idaho-state', name: 'Idaho State' },
  { code: 'illinois', name: 'Illinois' },
  { code: 'illinois-state', name: 'Illinois State' },
  { code: 'illinois-chicago', name: 'Illinois-Chicago' },
  { code: 'incarnate-word', name: 'Incarnate Word' },
  { code: 'indiana', name: 'Indiana' },
  { code: 'indiana-state', name: 'Indiana State' },
  { code: 'iona', name: 'Iona' },
  { code: 'iowa', name: 'Iowa' },
  { code: 'iowa-state', name: 'Iowa State' },
  { code: 'iupui', name: 'IUPUI' },

  { code: 'jackson-state', name: 'Jackson State' },
  { code: 'jacksonville', name: 'Jacksonville' },
  { code: 'jacksonville-state', name: 'Jacksonville State' },
  { code: 'james-madison', name: 'James Madison' },

  { code: 'kansas', name: 'Kansas' },
  { code: 'missouri-kansas-city', name: 'Kansas City' },
  { code: 'kansas-state', name: 'Kansas State' },
  { code: 'kennesaw-state', name: 'Kennesaw State' },
  { code: 'kent-state', name: 'Kent State' },
  { code: 'kentucky', name: 'Kentucky' },

  { code: 'la-salle', name: 'La Salle' },
  { code: 'lafayette', name: 'Lafayette' },
  { code: 'lamar', name: 'Lamar' },
  { code: 'le-moyne', name: 'Le Moyne' },
  { code: 'lehigh', name: 'Lehigh' },
  { code: 'liberty', name: 'Liberty' },
  { code: 'lindenwood', name: 'Lindenwood' },
  { code: 'lipscomb', name: 'Lipscomb' },
  { code: 'arkansas-little-rock', name: 'Little Rock' },
  { code: 'long-beach-state', name: 'Long Beach State' },
  { code: 'long-island-university', name: 'Long Island University' },
  { code: 'longwood', name: 'Longwood' },
  { code: 'louisiana-lafayette', name: 'Louisiana' },
  { code: 'louisiana-state', name: 'Louisiana State' },
  { code: 'louisiana-tech', name: 'Louisiana Tech' },
  { code: 'louisiana-monroe', name: 'Louisiana-Monroe' },
  { code: 'louisville', name: 'Louisville' },
  { code: 'loyola-il', name: 'Loyola (IL)' },
  { code: 'loyola-md', name: 'Loyola (MD)' },
  { code: 'loyola-marymount', name: 'Loyola Marymount' },

  { code: 'maine', name: 'Maine' },
  { code: 'manhattan', name: 'Manhattan' },
  { code: 'marist', name: 'Marist' },
  { code: 'marquette', name: 'Marquette' },
  { code: 'marshall', name: 'Marshall' },
  { code: 'maryland', name: 'Maryland' },
  { code: 'maryland-baltimore-county', name: 'Maryland-Baltimore County' },
  { code: 'maryland-eastern-shore', name: 'Maryland-Eastern Shore' },
  { code: 'massachusetts', name: 'Massachusetts' },
  { code: 'massachusetts-lowell', name: 'Massachusetts-Lowell' },
  { code: 'mcneese-state', name: 'McNeese State' },
  { code: 'memphis', name: 'Memphis' },
  { code: 'mercer', name: 'Mercer' },
  { code: 'merrimack', name: 'Merrimack' },
  { code: 'miami-fl', name: 'Miami (FL)' },
  { code: 'miami-oh', name: 'Miami (OH)' },
  { code: 'michigan', name: 'Michigan' },
  { code: 'michigan-state', name: 'Michigan State' },
  { code: 'middle-tennessee', name: 'Middle Tennessee' },
  { code: 'milwaukee', name: 'Milwaukee' },
  { code: 'minnesota', name: 'Minnesota' },
  { code: 'mississippi', name: 'Mississippi' },
  { code: 'mississippi-state', name: 'Mississippi State' },
  { code: 'mississippi-valley-state', name: 'Mississippi Valley State' },
  { code: 'missouri', name: 'Missouri' },
  { code: 'missouri-state', name: 'Missouri State' },
  { code: 'monmouth', name: 'Monmouth' },
  { code: 'montana', name: 'Montana' },
  { code: 'montana-state', name: 'Montana State' },
  { code: 'morehead-state', name: 'Morehead State' },
  { code: 'morgan-state', name: 'Morgan State' },
  { code: 'mount-st-marys', name: "Mount St. Mary's" },
  { code: 'murray-state', name: 'Murray State' },

  { code: 'navy', name: 'Navy' },
  { code: 'north-carolina-state', name: 'NC State' },
  { code: 'nebraska', name: 'Nebraska' },
  { code: 'nevada', name: 'Nevada' },
  { code: 'nevada-las-vegas', name: 'Nevada-Las Vegas' },
  { code: 'new-hampshire', name: 'New Hampshire' },
  { code: 'new-mexico', name: 'New Mexico' },
  { code: 'new-mexico-state', name: 'New Mexico State' },
  { code: 'new-orleans', name: 'New Orleans' },
  { code: 'niagara', name: 'Niagara' },
  { code: 'nicholls-state', name: 'Nicholls State' },
  { code: 'njit', name: 'NJIT' },
  { code: 'norfolk-state', name: 'Norfolk State' },
  { code: 'north-alabama', name: 'North Alabama' },
  { code: 'north-carolina', name: 'North Carolina' },
  { code: 'north-carolina-at', name: 'North Carolina A&T' },
  { code: 'north-carolina-central', name: 'North Carolina Central' },
  { code: 'north-dakota', name: 'North Dakota' },
  { code: 'north-dakota-state', name: 'North Dakota State' },
  { code: 'north-florida', name: 'North Florida' },
  { code: 'north-texas', name: 'North Texas' },
  { code: 'northeastern', name: 'Northeastern' },
  { code: 'northern-arizona', name: 'Northern Arizona' },
  { code: 'northern-colorado', name: 'Northern Colorado' },
  { code: 'northern-illinois', name: 'Northern Illinois' },
  { code: 'northern-iowa', name: 'Northern Iowa' },
  { code: 'northern-kentucky', name: 'Northern Kentucky' },
  { code: 'northwestern', name: 'Northwestern' },
  { code: 'northwestern-state', name: 'Northwestern State' },
  { code: 'notre-dame', name: 'Notre Dame' },

  { code: 'oakland', name: 'Oakland' },
  { code: 'ohio', name: 'Ohio' },
  { code: 'ohio-state', name: 'Ohio State' },
  { code: 'oklahoma', name: 'Oklahoma' },
  { code: 'oklahoma-state', name: 'Oklahoma State' },
  { code: 'old-dominion', name: 'Old Dominion' },
  { code: 'nebraska-omaha', name: 'Omaha' },
  { code: 'oral-roberts', name: 'Oral Roberts' },
  { code: 'oregon', name: 'Oregon' },
  { code: 'oregon-state', name: 'Oregon State' },

  { code: 'pacific', name: 'Pacific' },
  { code: 'penn-state', name: 'Penn State' },
  { code: 'pennsylvania', name: 'Pennsylvania' },
  { code: 'pepperdine', name: 'Pepperdine' },
  { code: 'pittsburgh', name: 'Pittsburgh' },
  { code: 'portland', name: 'Portland' },
  { code: 'portland-state', name: 'Portland State' },
  { code: 'prairie-view', name: 'Prairie View' },
  { code: 'presbyterian', name: 'Presbyterian' },
  { code: 'princeton', name: 'Princeton' },
  { code: 'providence', name: 'Providence' },
  { code: 'purdue', name: 'Purdue' },
  { code: 'ipfw', name: 'Purdue Fort Wayne' },

  { code: 'queens-nc', name: 'Queens (NC)' },
  { code: 'quinnipiac', name: 'Quinnipiac' },

  { code: 'radford', name: 'Radford' },
  { code: 'rhode-island', name: 'Rhode Island' },
  { code: 'rice', name: 'Rice' },
  { code: 'richmond', name: 'Richmond' },
  { code: 'rider', name: 'Rider' },
  { code: 'robert-morris', name: 'Robert Morris' },
  { code: 'rutgers', name: 'Rutgers' },

  { code: 'sacramento-state', name: 'Sacramento State' },
  { code: 'sacred-heart', name: 'Sacred Heart' },
  { code: 'saint-francis-pa', name: 'Saint Francis (PA)' },
  { code: 'saint-josephs', name: "Saint Joseph's" },
  { code: 'saint-louis', name: 'Saint Louis' },
  { code: 'saint-marys-ca', name: "Saint Mary's (CA)" },
  { code: 'saint-peters', name: "Saint Peter's" },
  { code: 'sam-houston-state', name: 'Sam Houston' },
  { code: 'samford', name: 'Samford' },
  { code: 'san-diego', name: 'San Diego' },
  { code: 'san-diego-state', name: 'San Diego State' },
  { code: 'san-francisco', name: 'San Francisco' },
  { code: 'san-jose-state', name: 'San Jose State' },
  { code: 'santa-clara', name: 'Santa Clara' },
  { code: 'seattle', name: 'Seattle' },
  { code: 'seton-hall', name: 'Seton Hall' },
  { code: 'siena', name: 'Siena' },
  { code: 'south-alabama', name: 'South Alabama' },
  { code: 'south-carolina', name: 'South Carolina' },
  { code: 'south-carolina-state', name: 'South Carolina State' },
  { code: 'south-carolina-upstate', name: 'South Carolina Upstate' },
  { code: 'south-dakota', name: 'South Dakota' },
  { code: 'south-dakota-state', name: 'South Dakota State' },
  { code: 'south-florida', name: 'South Florida' },
  { code: 'southeast-missouri-state', name: 'Southeast Missouri State' },
  { code: 'southeastern-louisiana', name: 'Southeastern Louisiana' },
  { code: 'southern', name: 'Southern' },
  { code: 'southern-california', name: 'Southern California' },
  { code: 'southern-illinois', name: 'Southern Illinois' },
  { code: 'southern-illinois-edwardsville', name: 'SIU Edwardsville' },
  { code: 'southern-indiana', name: 'Southern Indiana' },
  { code: 'southern-methodist', name: 'Southern Methodist' },
  { code: 'southern-mississippi', name: 'Southern Mississippi' },
  { code: 'southern-utah', name: 'Southern Utah' },
  { code: 'st-bonaventure', name: 'St. Bonaventure' },
  { code: 'st-johns-ny', name: "St. John's (NY)" },
  { code: 'st-thomas-mn', name: 'St. Thomas' },
  { code: 'stanford', name: 'Stanford' },
  { code: 'stephen-f-austin', name: 'Stephen F. Austin' },
  { code: 'stetson', name: 'Stetson' },
  { code: 'stonehill', name: 'Stonehill' },
  { code: 'stony-brook', name: 'Stony Brook' },
  { code: 'syracuse', name: 'Syracuse' },

  { code: 'tarleton-state', name: 'Tarleton State' },
  { code: 'texas-christian', name: 'TCU' },
  { code: 'temple', name: 'Temple' },
  { code: 'tennessee', name: 'Tennessee' },
  { code: 'tennessee-state', name: 'Tennessee State' },
  { code: 'tennessee-tech', name: 'Tennessee Tech' },
  { code: 'tennessee-martin', name: 'Tennessee-Martin' },
  { code: 'texas', name: 'Texas' },
  { code: 'texas-am', name: 'Texas A&M' },
  { code: 'texas-am-commerce', name: 'Texas A&M-Commerce' },
  { code: 'texas-am-corpus-christi', name: 'Texas A&M-Corpus Christi' },
  { code: 'texas-southern', name: 'Texas Southern' },
  { code: 'texas-state', name: 'Texas State' },
  { code: 'texas-tech', name: 'Texas Tech' },
  { code: 'texas-pan-american', name: 'Texas-Rio Grande Valley' },
  { code: 'citadel', name: 'The Citadel' },
  { code: 'toledo', name: 'Toledo' },
  { code: 'towson', name: 'Towson' },
  { code: 'troy', name: 'Troy' },
  { code: 'tulane', name: 'Tulane' },
  { code: 'tulsa', name: 'Tulsa' },
  { code: 'alabama-birmingham', name: 'UAB' },
  { code: 'california-davis', name: 'UC Davis' },
  { code: 'california-irvine', name: 'UC Irvine' },
  { code: 'california-riverside', name: 'UC Riverside' },
  { code: 'california-san-diego', name: 'UC San Diego' },
  { code: 'california-santa-barbara', name: 'UC Santa Barbara' },
  { code: 'central-florida', name: 'UCF' },

  { code: 'ucla', name: 'UCLA' },
  { code: 'north-carolina-asheville', name: 'UNC Asheville' },
  { code: 'north-carolina-greensboro', name: 'UNC Greensboro' },
  { code: 'north-carolina-wilmington', name: 'UNC Wilmington' },
  { code: 'texas-arlington', name: 'UT Arlington' },
  { code: 'utah', name: 'Utah' },
  { code: 'utah-state', name: 'Utah State' },
  { code: 'dixie-state', name: 'Utah Tech' },
  { code: 'utah-valley', name: 'Utah Valley' },
  { code: 'texas-el-paso', name: 'UTEP' },
  { code: 'texas-san-antonio', name: 'UTSA' },

  { code: 'valparaiso', name: 'Valparaiso' },
  { code: 'vanderbilt', name: 'Vanderbilt' },
  { code: 'vermont', name: 'Vermont' },
  { code: 'villanova', name: 'Villanova' },
  { code: 'virginia', name: 'Virginia' },
  { code: 'virginia-commonwealth', name: 'Virginia Commonwealth' },
  { code: 'virginia-military-institute', name: 'VMI' },
  { code: 'virginia-tech', name: 'Virginia Tech' },

  { code: 'wagner', name: 'Wagner' },
  { code: 'wake-forest', name: 'Wake Forest' },
  { code: 'washington', name: 'Washington' },
  { code: 'washington-state', name: 'Washington State' },
  { code: 'weber-state', name: 'Weber State' },
  { code: 'west-virginia', name: 'West Virginia' },
  { code: 'western-carolina', name: 'Western Carolina' },
  { code: 'western-illinois', name: 'Western Illinois' },
  { code: 'western-kentucky', name: 'Western Kentucky' },
  { code: 'western-michigan', name: 'Western Michigan' },
  { code: 'wichita-state', name: 'Wichita State' },
  { code: 'william-mary', name: 'William & Mary' },
  { code: 'winthrop', name: 'Winthrop' },
  { code: 'wisconsin', name: 'Wisconsin' },
  { code: 'wofford', name: 'Wofford' },
  { code: 'wright-state', name: 'Wright State' },
  { code: 'wyoming', name: 'Wyoming' },

  { code: 'xavier', name: 'Xavier' },

  { code: 'yale', name: 'Yale' },
  { code: 'youngstown-state', name: 'Youngstown State' },
];
