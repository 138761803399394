<template>
  <div class="page-container">
    <div class="lines-container">
      <div class="no-print">
        <NText v-if="message" type="error">${{ message }}</NText>
        <NText v-else-if="FETCH_ON" :strong="true" type="success">LIVE</NText>
        <NText v-else :strong="true" type="error">TEST DATA | Development Mode</NText>
      </div>
      <DataTable
        class="no-print"
        :data="tableData"
        :columns="tableColumns"
        :loading="tableLoading"
      />
      <div class="space-top">
        <NH4>Overs</NH4>
        <DataTable
          :data="tableDataLineReportOvers"
          :columns="tableColumnsLineReport"
          :loading="tableLoadingLineReport"
          :render-cell="renderFormattedCellOvers"
        />
        <NH4>Unders</NH4>
        <DataTable
          :data="tableDataLineReportUnders"
          :columns="tableColumnsLineReport"
          :loading="tableLoadingLineReport"
          :render-cell="renderFormattedCellUnders"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
/* eslint-disable */
import { h, ref, onMounted } from 'vue';
import { NText, NCard, NButton, NH4 } from 'naive-ui';
import DataTable from '@/components/DataTable';

import { testData } from '@/assets/test_nba_odds_data';
import { data as todaysData } from '@/assets/mlb_events_today';

const FETCH_ON = false;
const REGIONS = 'us';
const ODDS_FORMAT = 'american';
const BOOK_MAKERS = 'draftkings';

const message = ref('');

const title = ref('Lines Report');
const tableData = ref([]);
const tableColumns = ref([]);
const tableLoading = ref(true);

const tableLoadingLineReport = ref(false);
const tableColumnsLineReport = ref([]);
const tableDataLineReportOvers = ref([]);
const tableDataLineReportUnders = ref([]);

tableColumns.value = [
  { title: 'sport_title', key: 'sport_title', align: 'center' },
  { title: 'commence_time', key: 'commence_time', align: 'center' },
  { title: 'away_team', key: 'away_team', align: 'center' },
  { title: 'home_team', key: 'home_team', align: 'center' },
  {
    title: 'actions',
    key: 'actions',
    render(row) {
      return h(
        NButton,
        { strong: true, tertiary: true, size: 'small', onClick: async () => await fetchProp(row) },
        { default: () => 'Fetch' }
      );
    },
  },
];

tableColumnsLineReport.value = [
  { title: 'Rank', key: 'rank', align: 'center' },
  { title: 'Name', key: 'player', align: 'right' },
  { title: 'Line', key: 'line', align: 'center' },
  { title: 'Prop', key: 'market', align: 'center' },
  { title: 'Odds', key: 'price', align: 'center' },
  { title: 'Average', key: 'average', align: 'center' },
  { title: 'Last 3', key: 'hitRateLast3', align: 'center' },
  { title: 'Opponent', key: 'opponentRankVsPosition', align: 'center' },
  { title: 'Injured?', key: 'playerStatus', align: 'center' },
];

const renderFormattedCellOvers = (value, rowData, column) => {
  if (column.key === 'opponentRankVsPosition') {
    let className = 'bad';
    if (parseInt(value) > 20) {
      className = 'good';
    } else if (parseInt(value) > 15) {
      className = 'medium';
    }
    return h(NText, { depth: 1, class: className }, { default: () => value });
  }

  if (column.key === 'hitRateLast3') {
    let className = 'bad';
    if (parseInt(value) > 75) {
      className = 'good';
    } else if (parseInt(value) > 60) {
      className = 'medium';
    }
    return h(NText, { depth: 1, class: className }, { default: () => value });
  }

  if (column.key === 'average') {
    const className = rowData.aboveAverage === 'YES' ? 'good' : 'bad';
    return h(NText, { depth: 1, class: className }, { default: () => value });
  }

  if (column.key === 'playerStatus') {
    let className = '';
    if (value === 'Active') { className = 'good' }
    else if (value === 'GTD') { className = 'medium' }
    else if (value === 'OUT') { className = 'bad'}
    return h(NText, { depth: 1, class: className }, { default: () => value });
  }

  return h(NText, { depth: 1 }, { default: () => value });
};

const renderFormattedCellUnders = (value, rowData, column) => {
  if (column.key === 'opponentRankVsPosition') {
    let className = 'bad';
    if (parseInt(value) < 10) {
      className = 'good';
    } else if (parseInt(value) < 20) {
      className = 'medium';
    }
    return h(NText, { depth: 1, class: className }, { default: () => value });
  }

  if (column.key === 'hitRateLast3') {
    let className = 'bad';
    if (parseInt(value) < 20) {
      className = 'good';
    } else if (parseInt(value) < 40) {
      className = 'medium';
    }
    return h(NText, { depth: 1, class: className }, { default: () => value });
  }

  if (column.key === 'average') {
    const className = rowData.belowAverage === 'YES' ? 'good' : 'bad';
    return h(NText, { depth: 1, class: className }, { default: () => value });
  }

  if (column.key === 'playerStatus') {
    let className = '';
    if (value === 'Active') { className = 'good' }
    else if (value === 'GTD') { className = 'medium' }
    else if (value === 'OUT') { className = 'bad'}
    return h(NText, { depth: 1, class: className }, { default: () => value });
  }

  return h(NText, { depth: 1 }, { default: () => value });
};

const getLineReports = async (data) => {
  tableLoadingLineReport.value = true;

  const endpoint = process.env.VUE_APP_INTERNAL_API_ENDPOINT;
  const url = `${endpoint}/api/mlb/lines`;

  const response = await fetch(url, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ data }),
  });

  const json = await response.json();

  tableDataLineReportOvers.value = json?.overs;
  tableDataLineReportUnders.value = json?.unders;
  tableLoadingLineReport.value = false;
}

const fetchProp = async (row) => {
  return;
  // if (!FETCH_ON) {
  //   return await getLineReports(testData);
  // }

  const markets = [
    'batter_home_runs',
    // 'batter_hits',
    // 'batter_rbis',
    // 'batter_runs_scored',
    // 'batter_doubles',
    // 'batter_triples',
    // 'batter_walks',
    // 'batter_strikeouts',
    // 'batter_stolen_bases',
    // 'pitcher_strikeouts',
    // 'pitcher_hits_allowed',
    // 'pitcher_walks',
  ].join(',');

  console.log(markets)

  const { id, home_team, away_team, commence_time } = row;

  title.value = `${away_team} @${home_team} | ${commence_time}`;

  if (!id) return;

  const params = new URLSearchParams({
    apiKey: process.env.VUE_APP_ODDS_API_KEY,
    regions: REGIONS,
    //bookmakers: BOOK_MAKERS,
    oddsFormat: ODDS_FORMAT,
    markets: markets, //https://the-odds-api.com/sports-odds-data/betting-markets.html
  });



  const eventId = id;
  const url = `https://api.the-odds-api.com/v4/sports/baseball_mlb/events/${eventId}/odds?${params}`;
  console.log(url)
  const response = await fetch(url);
  const json = await response.json();

  console.log(json);

  if (json?.bookmakers?.find((book) => book.key === 'draftkings')) {
    await getLineReports(json);
  }
}

const fetchEvents = async () => {
  if (!FETCH_ON) {
    tableData.value = todaysData;
    return;
  }

  const MARKETS = 'h2h'; //h2h,spreads,totals

  const params = new URLSearchParams({
    apiKey: process.env.VUE_APP_ODDS_API_KEY,
    regions: REGIONS,
    markets: MARKETS,
    bookmakers: BOOK_MAKERS,
    oddsFormat: ODDS_FORMAT,
  });

  const url = `https://api.the-odds-api.com/v4/sports/baseball_mlb/odds/?${params}`;

  const response = await fetch(url);
  const json = await response.json();

  console.log(json)

  if (json.message) {
    return message.value = json.message;
  }

  tableData.value = json;
};

onMounted(async () => {
  await fetchEvents();
  tableLoading.value = false;
});

</script>

<style>
.space-top {
  margin-top: 20px;
}

.n-text.good {
  color: #1dff26;
}

.n-data-table
.n-data-table-td:has(.n-text.good) {
  background-color: #1b3b1c;
}

.n-text.medium {
  color: #FFC107;
}

.n-data-table
.n-data-table-td:has(.n-text.medium) {
  background-color: #643c00;
}

.n-text.bad {
  color: #ff1100;
}

.n-data-table
.n-data-table-td:has(.n-text.bad) {
  background-color: #331210;
}

@media print {
  .no-print {
    display: none;
  }
}
</style>