<template>
  <div class="app">
    <NConfigProvider :theme="darkTheme">
      <div class="app-header">
        <img class="app-header-icon" src="/icon.png">
        <NButton secondary type="tertiary" @click="router.push('/')">Home</NButton>
        <NButton secondary type="tertiary" @click="router.push('/nba')">NBA</NButton>
        <NButton secondary type="tertiary" @click="router.push('/mlb')">MLB</NButton>
      </div>
      <router-view></router-view>
    </NConfigProvider>
  </div>
</template>

<script setup>
import { useRouter } from 'vue-router';
import { darkTheme, NConfigProvider, NButton } from 'naive-ui';

const router = useRouter();
</script>

<style>
body, html {
  background-color: #1e1e1e;
}

a:-webkit-any-link {
  color: #fff;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: rgba(255, 255, 255, 0.82);
  background-color: #1e1e1e;
}

.app-header {
  height: 40px;
  padding: 10px;
  margin-bottom: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid white;
  border-image: linear-gradient(#967aff, #fdcbff) 30;
  background-color: black;
}

.app-header > * {
  margin: 5px;
}

.app-header-icon {
  height: 40px;
}

.page-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.cards-container {
  width: 98%;
  max-width: 1400px;
}

.lines-container {
  width: 98%;
  max-width: 1200px;
}

.cards-container > * {
  margin-bottom: 10px;
}

.table-controls {
  display: flex;
  align-items: center;
  justify-content: center;
}

.table-controls > * {
  margin: 10px;
}

.n-switch {
  width: 230px;
}

.n-data-table .n-data-table-td {

}

@media print {
  body, html {
    background-color: #1e1e1e;
  }
  .app-header {
    display: none;
  }
  .no-print {
    display: none;
  }
}
</style>