export const testData = {
  "id": "1badbcddd1109879662d15eae3b3de7c",
  "sport_key": "basketball_nba",
  "sport_title": "NBA",
  "commence_time": "2024-03-08T00:00:00Z",
  "home_team": "Detroit Pistons",
  "away_team": "Brooklyn Nets",
  "bookmakers": [
      {
          "key": "draftkings",
          "title": "DraftKings",
          "markets": [
              {
                  "key": "player_assists",
                  "last_update": "2024-03-07T06:17:56Z",
                  "outcomes": [
                      {
                          "name": "Over",
                          "description": "Cade Cunningham",
                          "price": -125,
                          "point": 7.5
                      },
                      {
                          "name": "Under",
                          "description": "Cade Cunningham",
                          "price": -105,
                          "point": 7.5
                      },
                      {
                          "name": "Over",
                          "description": "Dennis Schroder",
                          "price": -120,
                          "point": 6.5
                      },
                      {
                          "name": "Under",
                          "description": "Dennis Schroder",
                          "price": -110,
                          "point": 6.5
                      },
                      {
                          "name": "Over",
                          "description": "Dorian Finney-Smith",
                          "price": 124,
                          "point": 2.5
                      },
                      {
                          "name": "Under",
                          "description": "Dorian Finney-Smith",
                          "price": -160,
                          "point": 2.5
                      },
                      {
                          "name": "Over",
                          "description": "Jaden Ivey",
                          "price": 105,
                          "point": 3.5
                      },
                      {
                          "name": "Under",
                          "description": "Jaden Ivey",
                          "price": -135,
                          "point": 3.5
                      },
                      {
                          "name": "Over",
                          "description": "Jalen Duren",
                          "price": 120,
                          "point": 2.5
                      },
                      {
                          "name": "Under",
                          "description": "Jalen Duren",
                          "price": -150,
                          "point": 2.5
                      },
                      {
                          "name": "Over",
                          "description": "Mikal Bridges",
                          "price": -120,
                          "point": 3.5
                      },
                      {
                          "name": "Under",
                          "description": "Mikal Bridges",
                          "price": -110,
                          "point": 3.5
                      },
                      {
                          "name": "Over",
                          "description": "Nicolas Claxton",
                          "price": 124,
                          "point": 2.5
                      },
                      {
                          "name": "Under",
                          "description": "Nicolas Claxton",
                          "price": -160,
                          "point": 2.5
                      }
                  ]
              },
          ]
      }
  ]
}