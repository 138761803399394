<template>
  <div class="page-container">
    <div class="cards-container">
      <NCard>
        <template #header>
          <div class="team-header">
            <img v-if="teamLogoLink" :src="teamLogoLink" class="logo" /> {{ teamName }}
          </div>
        </template>

        <div><b>Conference:</b> {{ team.conference }}</div>
        <div v-if="team.division"><b>Division:</b> {{ team.division }}</div>

        <div><b>Location:</b> {{ teamLocation }}</div>
        <div><b>Venue:</b> {{ teamVenue }}</div>

        <div><b>Twitter:</b> <a :href="`https://twitter.com/${team.twitter}`">{{ team.twitter }}</a></div>
      </NCard>

      <NTabs type="line" animated>
        <NTabPane name="charts">
          <template #tab><NIcon><ChartBar /></NIcon> Charts</template>

          Test Charts
        </NTabPane>

        <NTabPane name="tables">
          <template #tab><NIcon><VDataTable /></NIcon> Tables</template>

          Tables
        </NTabPane>
      </NTabs>
    </div>
  </div>
</template>

<script setup>
/* eslint-disable */
import { h, onMounted, ref, defineProps, computed } from 'vue';
import { useRoute } from 'vue-router';
import { NCard, NTag, NIcon, NTabs, NTabPane } from 'naive-ui';
import { ChartBar, DataTable as VDataTable } from '@vicons/carbon';
import { STAT_MAP, REPORT_TYPES, reduceStats } from '@/util/util_cfb';
import * as _ from 'lodash';

const route = useRoute();

const team = ref({});
const teamStats = ref([]);
const leagueStats = ref([]);
const columns = ref([]);
const tableData = ref([]);
const tableLoading = ref(true);
const reportTypeSelectValue = ref(['Passing']);
const reportTypeSelectOptions = ref([]);
const showRanks = ref(false);
const showAspects = ref(false);

const updateReportType = (e) => {
  reportTypeSelectValue.value = e;
  setTableData();
};

const updateShowAspects = (e) => {
  showAspects.value = e;
  setTableData();
};

const updateShowRanks = async (e) => {
  showRanks.value = e;

  if (e) {
    await fetchLeagueData();
    return setTableData({ ranked: true });
  }

  setTableData();
};

const setTableData = (options = {}) => {

  // TODO - rank data

  const stats = STAT_MAP.filter((S) => {
    return reportTypeSelectValue.value.includes(S.aspect);
  });

  const statsMapped = stats.map((stat) => {
    const statRow = teamStats.value.find((v) => v.statName === stat.id);
    const { statValue } = statRow;

    return {
      statName: stat.display,
      statValue,
      aspect: stat.aspect,
    };
  });

  columns.value = [
    {
      title: 'Aspect',
      key: 'aspect',
    },
    {
      title: 'Stat',
      key: 'statName',
    },
    {
      title: 'Value',
      key: 'statValue',
      render(row) {
        return h(
          NTag, 
          { type: 'info', bordered: false },
          { default: () => row.statValue }
        );
      },
    },
  ].filter((col) => !showAspects.value ? col.key !== 'aspect' : true);
  tableData.value = statsMapped;
  tableLoading.value = false;
};

reportTypeSelectOptions.value = Object.keys(REPORT_TYPES).map((rt) => {
  return {
    label: REPORT_TYPES[rt],
    value: REPORT_TYPES[rt],
  };
});

const teamName = computed(() => {
  return team.value ? `${team.value.school} ${team.value.mascot}` : undefined;
});

const teamLocation = computed(() => {
  return team.value && team.value.location
    ? `${team.value.location.city}, ${team.value.location.state}`
    : undefined;
});

const teamVenue = computed(() => {
  return team.value && team.value.location
    ? team.value.location.name
    : undefined;
});

const teamLogoLink = computed(() => {
  return team.value && team.value.logos ? team.value.logos[0] : undefined;
});

const fetchLeagueData = async () => {
  if (leagueStats.value?.length) {
    return;
  }

  tableLoading.value = true;

  const apiLeagueData = await api({
    year: 2023,
    type: 'STATS_SEASON',
  });

  leagueStats.value = apiLeagueData;
  tableLoading.value = false;
};

const fetchTeamData = async () => {
  const apiTeamData = await api({
    type: 'TEAMS_FBS',
    year: 2023,
  });

  const teamData = apiTeamData.find((t) => {
    return t.id == route.params.id;
  });

  team.value = teamData;

  if (team.value) {
    const apiTeamStatsData = await api({
      year: 2023,
      team: team.value.school,
      type: 'STATS_SEASON_TEAM',
    });

    teamStats.value = apiTeamStatsData;

    setTableData();
  }
};

const api = async (options = {}) => {
  const params = new URLSearchParams({
    ...options,
  });
  const url = `http://localhost:3000/api?${params}`;
  const response = await fetch(url);
  return await response.json();
};

onMounted(async () => {
  await fetchTeamData();
});

</script>

<style scoped>
.logo {
  height: 45px;
}
.team-header {
  display: flex;
  align-items: center;
}
.team-header > * {
  margin: 10px;
}
</style>


