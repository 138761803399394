/* eslint-disable */

import * as d3 from 'd3';

const classTransform = (tag) => {
  switch(tag) {
    case '3PT':
      return 'THREEPT';
    default:
      return tag;
  }
};

export const scatterChart = (data, options = {}) => {
  const { xExtent = undefined, yExtent = [0, 60] } = options;

  const groups = data.map((datum) => datum.name);
  const keys = data[0].values.map((datum) => datum.key);

  const width = 1400;
  const height = 400;

  const marginTop = 30;
  const marginRight = 40;
  const marginBottom = 30;
  const marginLeft = 40;

  const myColor = d3.scaleOrdinal().domain(groups).range(d3.schemePaired);

  d3.select('div.tooltip').remove();
  d3.select('svg').remove();

  const div = d3.select('body').append('div')
    .attr('class', 'tooltip')
    .style('opacity', 0);

  const svg = d3.create('svg')
    .attr('width', width)
    .attr('height', height)
    .attr('viewBox', [0, 0, width, height])
    .attr('style', 'max-width: 100%; height: auto;');

  const x = d3.scalePoint().domain(keys).range([marginLeft, width - marginRight])
  // hint use scaleBand() for bar charts !
  svg.append('g')
    .attr('transform', `translate(0,${height-marginTop})`)
    .call(d3.axisBottom(x))
    // .selectAll('text')
    // .style('text-anchor', 'end')
    // .attr('dx', '-.8em')
    // .attr('dy', '.15em')
    // .attr('transform', 'rotate(-65)');

  const y = d3.scaleLinear().domain(yExtent).range([height - marginBottom, marginTop]);

  svg.append('g')
    .attr('transform', `translate(${marginLeft},0)`)
    .call(d3.axisLeft(y));

  const line = d3.line()
    .x((d) => x(d['key']))
    .y((d) => y(d['value']));

  //lines
  svg.selectAll('myLines')
    .data(data)
    .enter()
    .append('path')
    .attr('d', (d) => line(d.values))
    .attr('stroke', (d) => myColor(d.name))
    .style('stroke-width', 2)
    .style('fill', 'none')
    .attr('class', (d) => classTransform(d.name));

  //points
  svg
    .selectAll('myDots')
    .data(data)
    .enter()
      .append('g')
      .attr('class', (d) => classTransform(d.name))
      .style('fill', (d) => myColor(d.name))
    .selectAll('myPoints')
    .data((d) => d.values)
    .enter()
      .append('circle')
      .attr('class', (d) => classTransform(d.name))
      .attr('cx', (d) => x(d.key))
      .attr('cy', (d) => y(d.value))
      .attr('r', 5)
      .attr('stroke', 'white')
    .on('mouseover', function (evt, data) {
      d3.select(this).transition().duration('100').attr('r', 7);
      div.transition().duration(100).style('opacity', 1);
      div.html(data.value)
        .style('top', (evt.pageY) + 'px')
        .style('left',(evt.pageX) + 'px')
    })
    .on('mouseout', function (evt, data) {
      d3.select(this).transition().duration('200').attr('r', 5);
      div.transition().duration('200').style('opacity', 0);
    });

  //legend at end of lines
  // svg
  //   .selectAll('myLabels')
  //   .data(data)
  //   .enter()
  //   .append('g')
  //   .append('text')
  //   .datum((d) => {
  //     return {
  //       name: d.name,
  //       value: d.values[d.values.length - 1]
  //     };
  //   })
  //   .attr('transform', (d) => {
  //     //put the text at the position of the last point
  //     return `translate(${x(d.value.key)}, ${y(d.value.value)})`; 
  //   })
  //   .attr('x', 12)
  //   .text((d) => d.name)
  //   .style('fill', (d) => myColor(d.name))
  //   .style('font-size', 15)
  //   .attr('class', (d) => classTransform(d.name));

  //legend (interactive)
  svg
    .selectAll('myLegend')
    .data(data)
    .enter()
    .append('g')
    .append('text')
    .attr('x', (d, i) => marginLeft*2 + i*60)
    .attr('y', 30)
    .text((d) => d.name)
    .style('fill', (d) => myColor(d.name))
    .style('font-size', 15)
    .on('click', (evt, d) => {
      //is the element currently visible ?
      const selector = `.${classTransform(d.name)}`;
      const currentOpacity = d3.selectAll(selector).style('opacity');
      //change the opacity: from 0 to 1 or from 1 to 0
      d3.selectAll(selector).transition().style('opacity', currentOpacity == 1 ? 0 : 1);
    });

  return svg.node();
}