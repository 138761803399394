module.exports.teams = [
  { id: 'chw', name: 'chicago-white-sox', prettyName: 'Chicago White Sox', colors: ['#27251F', '#C4CED4'] },
  { id: 'cle', name: 'cleveland-guardians', prettyName: 'Cleveland Guardians', colors: ['#E31937', '#0C2340', '#FFFFFF'] },
  { id: 'det', name: 'detroit-tigers', prettyName: 'Detroit Tigers', colors: ['#0C2340', '#FA4616', '#FFFFFF'] },
  { id: 'kc', name: 'kansas-city-royals', prettyName: 'Kansas City Royals', colors: ['#004687', '#BD9B60', '#FFFFFF'] },
  { id: 'min', name: 'minnesota-twins', prettyName: 'Minnesota Twins', colors: ['#002B5C', '#D31145', '#FFFFFF'] },

  { id: 'chc', name: 'chicago-cubs', prettyName: 'Chicago Cubs', colors: ['#0E3386', '#CC3433'] },
  { id: 'cin', name: 'cincinnati-reds', prettyName: 'Cincinnati Reds', colors: ['#C6011F', '#000000', '#FFFFFF'] },
  { id: 'mil', name: 'milwaukee-brewers', prettyName: 'Milwaukee Brewers', colors: ['#0A2351', '#B6922C', '#FFFFFF'] },
  { id: 'pit', name: 'pittsburgh-pirates', prettyName: 'Pittsburgh Pirates', colors: ['#FDB827', '#27251F', '#FFFFFF'] },
  { id: 'stl', name: 'st-louis-cardinals', prettyName: 'St. Louis Cardinals', colors: ['#C41E3A', '#0C2340', '#FEDB00', '#FFFFFF'] },

  { id: 'bal', name: 'baltimore-orioles', prettyName: 'Baltimore Orioles', colors: ['#DF4601', '#000000', '#9E1A1A'] },
  { id: 'bos', name: 'boston-red-sox', prettyName: 'Boston Red Sox', colors: ['#BD3039', '#0D2B56', '#FFFFFF'] },
  { id: 'nyy', name: 'new-york-yankees', prettyName: 'New York Yankees', colors: ['#0C2340', '#FFFFFF'] },
  { id: 'tb', name: 'tampa-bay-rays', prettyName: 'Tampa Bay Rays', colors: ['#092C5C', '#8FBCE6', '#F5D130', '#FFFFFF'] },
  { id: 'tor', name: 'toronto-blue-jays', prettyName: 'Toronto Blue Jays', colors: ['#134A8E', '#1D2D5C', '#E8291C', '#FFFFFF'] },

  { id: 'atl', name: 'atlanta-braves', prettyName: 'Atlanta Braves', colors: ['#13274F', '#CE1141', '#EAAA00'] },
  { id: 'mia', name: 'miami-marlins', prettyName: 'Miami Marlins', colors: ['#00A3E0', '#EF3340', '#000000', '#FFFFFF'] },
  { id: 'nym', name: 'new-york-mets', prettyName: 'New York Mets', colors: ['#002D72', '#FF5910', '#FFFFFF'] },
  { id: 'phi', name: 'philadelphia-phillies', prettyName: 'Philadelphia Phillies', colors: ['#E81828', '#002D72', '#FFFFFF'] },
  { id: 'wsh', name: 'washington-nationals', prettyName: 'Washington Nationals', colors: ['#AB0003', '#14225A', '#FFFFFF'] },

  { id: 'oak', name: 'oakland-athletics', prettyName: 'Oakland Athletics', colors: ['#003831', '#EFB21E', '#FFFFFF'] },
  { id: 'hou', name: 'houston-astros', prettyName: 'Houston Astros', colors: ['#002D62', '#EB6E1F', '#FFFFFF'] },
  { id: 'laa', name: 'los-angeles-angels', prettyName: 'Los Angeles Angels', colors: ['#BA0021', '#003263', '#FFFFFF'] },
  { id: 'sea', name: 'seattle-mariners', prettyName: 'Seattle Mariners', colors: ['#0C2C56', '#005C5C', '#C4CED4', '#FFFFFF'] },
  { id: 'tex', name: 'texas-rangers', prettyName: 'Texas Rangers', colors: ['#003278', '#C0111F', '#FFFFFF'] },

  { id: 'ari', name: 'arizona-diamondbacks', prettyName: 'Arizona Diamondbacks', colors: ['#A71930', '#E3D4AD', '#000000'] },
  { id: 'col', name: 'colorado-rockies', prettyName: 'Colorado Rockies', colors: ['#33006F', '#C4CED4', '#FFFFFF'] },
  { id: 'lad', name: 'los-angeles-dodgers', prettyName: 'Los Angeles Dodgers', colors: ['#005A9C', '#FFFFFF'] },
  { id: 'sd', name: 'san-diego-padres', prettyName: 'San Diego Padres', colors: ['#2F241D', '#FEC325', '#7F411C', '#A0AAB2', '#FFFFFF'] },
  { id: 'sf', name: 'san-francisco-giants', prettyName: 'San Francisco Giants', colors: ['#FD5A1E', '#27251F', '#8B6F4E', '#FFFFFF'] },
];

module.exports.players = [
  { id: '40437', name: "Tanner Banks", position: 'RP', teamId: 'chw' },
  { id: '39085', name: "John Brebbia", position: 'RP', teamId: 'chw' },
  { id: '4297835', name: "Garrett Crochet", position: 'RP', teamId: 'chw' },
  { id: '33793', name: "Erick Fedde", position: 'SP', teamId: 'chw' },
  { id: '36067', name: "Chris Flexen", position: 'RP', teamId: 'chw' },
  { id: '41218', name: "Deivi Garcia", position: 'RP', teamId: 'chw' },
  { id: '37005', name: "Tim Hill", position: 'RP', teamId: 'chw' },
  { id: '33763', name: "Michael Kopech", position: 'SP', teamId: 'chw' },
  { id: '5132121', name: "Jordan Leasure", position: 'RP', teamId: 'chw' },
  { id: '33155', name: "Dominic Leone", position: 'RP', teamId: 'chw' },
  { id: '30589', name: "Bryan Shaw", position: 'RP', teamId: 'chw' },
  { id: '34984', name: "Michael Soroka", position: 'SP', teamId: 'chw' },
  { id: '41828', name: "Steven Wilson", position: 'RP', teamId: 'chw' },
  { id: '4418346', name: "Korey Lee", position: 'C', teamId: 'chw' },
  { id: '30289', name: "Martin Maldonado", position: 'C', teamId: 'chw' },
  { id: '35185', name: "Paul DeJong", position: 'SS', teamId: 'chw' },
  { id: '38106', name: "Nicky Lopez", position: '2B', teamId: 'chw' },
  { id: '33984', name: "Yoan Moncada", position: '3B', teamId: 'chw' },
  { id: '40700', name: "Gavin Sheets", position: '1B', teamId: 'chw' },
  { id: '42408', name: "Braden Shewmake", position: 'SS', teamId: 'chw' },
  { id: '42394', name: "Andrew Vaughn", position: '1B', teamId: 'chw' },
  { id: '34986', name: "Andrew Benintendi", position: 'LF', teamId: 'chw' },
  { id: '42984', name: "Dominic Fletcher", position: 'CF', teamId: 'chw' },
  { id: '33867', name: "Eloy Jimenez", position: 'OF', teamId: 'chw' },
  { id: '32859', name: "Kevin Pillar", position: 'LF', teamId: 'chw' },
  { id: '39631', name: "Luis Robert Jr.", position: 'CF', teamId: 'chw' },
  { id: '4683350', name: "Logan Allen", position: 'SP', teamId: 'cle' },
  { id: '39740', name: "Pedro Avila", position: 'RP', teamId: 'cle' },
  { id: '32156', name: "Scott Barlow", position: 'RP', teamId: 'cle' },
  { id: '4345278', name: "Tanner Bibee", position: 'SP', teamId: 'cle' },
  { id: '28968', name: "Carlos Carrasco", position: 'SP', teamId: 'cle' },
  { id: '41743', name: "Emmanuel Clase", position: 'RP', teamId: 'cle' },
  { id: '4187661', name: "Hunter Gaddis", position: 'RP', teamId: 'cle' },
  { id: '4228579', name: "Tim Herrin", position: 'RP', teamId: 'cle' },
  { id: '33194', name: "Ben Lively", position: 'SP', teamId: 'cle' },
  { id: '34954', name: "Triston McKenzie", position: 'SP', teamId: 'cle' },
  { id: '41385', name: "Nick Sandlin", position: 'RP', teamId: 'cle' },
  { id: '4987924', name: "Cade Smith", position: 'RP', teamId: 'cle' },
  { id: '4012625', name: "Peter Strzelecki", position: 'RP', teamId: 'cle' },
  { id: '32168', name: "Austin Hedges", position: 'C', teamId: 'cle' },
  { id: '41183', name: "Bo Naylor", position: 'C', teamId: 'cle' },
  { id: '40344', name: "Gabriel Arias", position: '3B', teamId: 'cle' },
  { id: '41672', name: "David Fry", position: '1B', teamId: 'cle' },
  { id: '37729', name: "Andres Gimenez", position: '2B', teamId: 'cle' },
  { id: '35066', name: "Josh Naylor", position: '1B', teamId: 'cle' },
  { id: '32801', name: "Jose Ramirez", position: '3B', teamId: 'cle' },
  { id: '41217', name: "Brayan Rocchio", position: 'SS', teamId: 'cle' },
  { id: '4088296', name: "Will Brennan", position: 'RF', teamId: 'cle' },
  { id: '36636', name: "Estevan Florial", position: 'RF', teamId: 'cle' },
  { id: '40958', name: "Tyler Freeman", position: 'CF', teamId: 'cle' },
  { id: '41996', name: "Steven Kwan", position: 'LF', teamId: 'cle' },
  { id: '35142', name: "Ramon Laureano", position: 'RF', teamId: 'cle' },
  { id: '32810', name: "Andrew Chafin", position: 'RP', teamId: 'det' },
  { id: '39901', name: "Alex Faedo", position: 'RP', teamId: 'det' },
  { id: '33837', name: "Jack Flaherty", position: 'SP', teamId: 'det' },
  { id: '42698', name: "Jason Foley", position: 'RP', teamId: 'det' },
  { id: '3983833', name: "Tyler Holton", position: 'RP', teamId: 'det' },
  { id: '40976', name: "Alex Lange", position: 'RP', teamId: 'det' },
  { id: '34892', name: "Kenta Maeda", position: 'SP', teamId: 'det' },
  { id: '30738', name: "Shelby Miller", position: 'RP', teamId: 'det' },
  { id: '41167', name: "Casey Mize", position: 'SP', teamId: 'det' },
  { id: '4734319', name: "Reese Olson", position: 'SP', teamId: 'det' },
  { id: '42409', name: "Tarik Skubal", position: 'SP', teamId: 'det' },
  { id: '41606', name: "Will Vest", position: 'RP', teamId: 'det' },
  { id: '40927', name: "Joey Wentz", position: 'RP', teamId: 'det' },
  { id: '32797', name: "Carson Kelly", position: 'C', teamId: 'det' },
  { id: '39900', name: "Jake Rogers", position: 'C', teamId: 'det' },
  { id: '32127', name: "Javier Baez", position: 'SS', teamId: 'det' },
  { id: '34947', name: "Andy Ibanez", position: '2B', teamId: 'det' },
  { id: '4683384', name: "Colt Keith", position: '2B', teamId: 'det' },
  { id: '38420', name: "Zach McKinstry", position: 'SS', teamId: 'det' },
  { id: '4424286', name: "Spencer Torkelson", position: '1B', teamId: 'det' },
  { id: '41636', name: "Matt Vierling", position: '3B', teamId: 'det' },
  { id: '31670', name: "Mark Canha", position: 'LF', teamId: 'det' },
  { id: '42714', name: "Kerry Carpenter", position: 'RF', teamId: 'det' },
  { id: '42179', name: "Riley Greene", position: 'LF', teamId: 'det' },
  { id: '41223', name: "Parker Meadows", position: 'CF', teamId: 'det' },
  { id: '41586', name: "Wenceel Perez", position: 'RF', teamId: 'det' },
  { id: '38102', name: "Nick Anderson", position: 'RP', teamId: 'kc' },
  { id: '33668', name: "Tyler Duffey", position: 'RP', teamId: 'kc' },
  { id: '34873', name: "Seth Lugo", position: 'SP', teamId: 'kc' },
  { id: '31061', name: "Jordan Lyles", position: 'RP', teamId: 'kc' },
  { id: '3960883', name: "James McArthur", position: 'RP', teamId: 'kc' },
  { id: '41054', name: "Cole Ragans", position: 'SP', teamId: 'kc' },
  { id: '41042', name: "Matt Sauer", position: 'RP', teamId: 'kc' },
  { id: '39949', name: "John Schreiber", position: 'RP', teamId: 'kc' },
  { id: '5116841', name: "Colin Selby", position: 'RP', teamId: 'kc' },
  { id: '41172', name: "Brady Singer", position: 'SP', teamId: 'kc' },
  { id: '31549', name: "Will Smith", position: 'RP', teamId: 'kc' },
  { id: '32787', name: "Chris Stratton", position: 'RP', teamId: 'kc' },
  { id: '32640', name: "Michael Wacha", position: 'SP', teamId: 'kc' },
  { id: '4705673', name: "Angel Zerpa", position: 'RP', teamId: 'kc' },
  { id: '41928', name: "Freddy Fermin", position: 'C', teamId: 'kc' },
  { id: '31127', name: "Salvador Perez", position: 'C', teamId: 'kc' },
  { id: '33546', name: "Adam Frazier", position: '2B', teamId: 'kc' },
  { id: '4905884', name: "Maikel Garcia", position: '3B', teamId: 'kc' },
  { id: '4109223', name: "Michael Massey", position: '2B', teamId: 'kc' },
  { id: '4109109', name: "Vinnie Pasquantino", position: '1B', teamId: 'kc' },
  { id: '42403', name: "Bobby Witt Jr.", position: 'SS', teamId: 'kc' },
  { id: '42228', name: "Dairon Blanco", position: 'LF', teamId: 'kc' },
  { id: '39899', name: "Garrett Hampson", position: 'CF', teamId: 'kc' },
  { id: '41263', name: "Kyle Isbel", position: 'CF', teamId: 'kc' },
  { id: '40948', name: "MJ Melendez", position: 'LF', teamId: 'kc' },
  { id: '33205', name: "Hunter Renfroe", position: 'RF', teamId: 'kc' },
  { id: '41047', name: "Nelson Velazquez", position: 'LF', teamId: 'kc' },
  { id: '41031', name: "Jorge Alcala", position: 'RP', teamId: 'min' },
  { id: '41109', name: "Jhoan Duran", position: 'RP', teamId: 'min' },
  { id: '30705', name: "Jay Jackson", position: 'RP', teamId: 'min' },
  { id: '42604', name: "Griffin Jax", position: 'RP', teamId: 'min' },
  { id: '39671', name: "Pablo Lopez", position: 'SP', teamId: 'min' },
  { id: '3107919', name: "Bailey Ober", position: 'SP', teamId: 'min' },
  { id: '33716', name: "Steven Okert", position: 'RP', teamId: 'min' },
  { id: '35999', name: "Chris Paddack", position: 'SP', teamId: 'min' },
  { id: '42450', name: "Joe Ryan", position: 'SP', teamId: 'min' },
  { id: '3983841', name: "Cole Sands", position: 'RP', teamId: 'min' },
  { id: '35997', name: "Brock Stewart", position: 'RP', teamId: 'min' },
  { id: '32440', name: "Caleb Thielbar", position: 'RP', teamId: 'min' },
  { id: '41383', name: "Simeon Woods Richardson", position: 'SP', teamId: 'min' },
  { id: '41587', name: "Ryan Jeffers", position: 'C', teamId: 'min' },
  { id: '31389', name: "Christian Vazquez", position: 'C', teamId: 'min' },
  { id: '34230', name: "Willi Castro", position: 'SS', teamId: 'min' },
  { id: '32653', name: "Carlos Correa", position: 'SS', teamId: 'min' },
  { id: '33287', name: "Kyle Farmer", position: '3B', teamId: 'min' },
  { id: '3985190', name: "Edouard Julien", position: '2B', teamId: 'min' },
  { id: '40636', name: "Alex Kirilloff", position: '1B', teamId: 'min' },
  { id: '41000', name: "Jose Miranda", position: '3B', teamId: 'min' },
  { id: '30280', name: "Carlos Santana", position: '1B', teamId: 'min' },
  { id: '31870', name: "Max Kepler", position: 'RF', teamId: 'min' },
  { id: '41205', name: "Trevor Larnach", position: 'LF', teamId: 'min' },
  { id: '33599', name: "Manuel Margot", position: 'RF', teamId: 'min' },
  { id: '4297998', name: "Austin Martin", position: 'LF', teamId: 'min' },
  { id: '36064', name: "Yency Almonte", position: 'RP', teamId: 'chc' },
  { id: '39802', name: "Adbert Alzolay", position: 'RP', teamId: 'chc' },
  { id: '5002950', name: "Javier Assad", position: 'SP', teamId: 'chc' },
  { id: '36631', name: "Colten Brewer", position: 'RP', teamId: 'chc' },
  { id: '41516', name: "Ben Brown", position: 'SP', teamId: 'chc' },
  { id: '5134630', name: "Shota Imanaga", position: 'SP', teamId: 'chc' },
  { id: '36202', name: "Mark Leiter Jr.", position: 'RP', teamId: 'chc' },
  { id: '39856', name: "Richard Lovelady", position: 'RP', teamId: 'chc' },
  { id: '32377', name: "Hector Neris", position: 'RP', teamId: 'chc' },
  { id: '4875225', name: "Daniel Palencia", position: 'RP', teamId: 'chc' },
  { id: '31258', name: "Jameson Taillon", position: 'SP', teamId: 'chc' },
  { id: '41086', name: "Keegan Thompson", position: 'RP', teamId: 'chc' },
  { id: '42996', name: "Hayden Wesneski", position: 'RP', teamId: 'chc' },
  { id: '38905', name: "Miguel Amaya", position: 'C', teamId: 'chc' },
  { id: '32108', name: "Yan Gomes", position: 'C', teamId: 'chc' },
  { id: '42415', name: "Michael Busch", position: '1B', teamId: 'chc' },
  { id: '41219', name: "Nico Hoerner", position: '2B', teamId: 'chc' },
  { id: '41170', name: "Nick Madrigal", position: '3B', teamId: 'chc' },
  { id: '4297978', name: "Matt Mervis", position: '1B', teamId: 'chc' },
  { id: '42927', name: "Christopher Morel", position: '3B', teamId: 'chc' },
  { id: '34895', name: "Dansby Swanson", position: 'SS', teamId: 'chc' },
  { id: '41239', name: "Alexander Canario", position: 'LF', teamId: 'chc' },
  { id: '4717833', name: "Pete Crow-Armstrong", position: 'CF', teamId: 'chc' },
  { id: '34945', name: "Ian Happ", position: 'LF', teamId: 'chc' },
  { id: '33956', name: "Mike Tauchman", position: 'RF', teamId: 'chc' },
  { id: '32861', name: "Patrick Wisdom", position: 'RF', teamId: 'chc' },
  { id: '4414528', name: "Andrew Abbott", position: 'SP', teamId: 'cin' },
  { id: '4084179', name: "Graham Ashcraft", position: 'SP', teamId: 'cin' },
  { id: '34068', name: "Fernando Cruz", position: 'RP', teamId: 'cin' },
  { id: '4905920', name: "Alexis Diaz", position: 'RP', teamId: 'cin' },
  { id: '33627', name: "Buck Farmer", position: 'RP', teamId: 'cin' },
  { id: '39635', name: "Hunter Greene", position: 'SP', teamId: 'cin' },
  { id: '42433', name: "Nick Lodolo", position: 'SP', teamId: 'cin' },
  { id: '33372', name: "Nick Martinez", position: 'SP', teamId: 'cin' },
  { id: '34928', name: "Sam Moll", position: 'RP', teamId: 'cin' },
  { id: '33403', name: "Emilio Pagan", position: 'RP', teamId: 'cin' },
  { id: '32807', name: "Lucas Sims", position: 'RP', teamId: 'cin' },
  { id: '36023', name: "Brent Suter", position: 'RP', teamId: 'cin' },
  { id: '31026', name: "Justin Wilson", position: 'RP', teamId: 'cin' },
  { id: '33271', name: "Luke Maile", position: 'C', teamId: 'cin' },
  { id: '34975', name: "Tyler Stephenson", position: 'C', teamId: 'cin' },
  { id: '32531', name: "Jeimer Candelario", position: '3B', teamId: 'cin' },
  { id: '4917694', name: "Elly De La Cruz", position: 'SS', teamId: 'cin' },
  { id: '5012106', name: "Christian Encarnacion-Strand", position: '1B', teamId: 'cin' },
  { id: '39924', name: "Santiago Espinal", position: '2B', teamId: 'cin' },
  { id: '41171', name: "Jonathan India", position: '2B', teamId: 'cin' },
  { id: '39216', name: "Will Benson", position: 'CF', teamId: 'cin' },
  { id: '40346', name: "Stuart Fairchild", position: 'CF', teamId: 'cin' },
  { id: '37974', name: "Jake Fraley", position: 'RF', teamId: 'cin' },
  { id: '36497', name: "Nick Martini", position: 'RF', teamId: 'cin' },
  { id: '4722857', name: "Spencer Steer", position: 'LF', teamId: 'cin' },
  { id: '39644', name: "Bubba Thompson", position: 'CF', teamId: 'cin' },
  { id: '4991171', name: "Bryan Hudson", position: 'RP', teamId: 'mil' },
  { id: '4881980', name: "Janson Junk", position: 'RP', teamId: 'mil' },
  { id: '41029', name: "Corbin Martin", position: 'SP', teamId: 'mil' },
  { id: '40080', name: "Trevor Megill", position: 'RP', teamId: 'mil' },
  { id: '36088', name: "Hoby Milner", position: 'RP', teamId: 'mil' },
  { id: '39716', name: "Tobias Myers", position: 'SP', teamId: 'mil' },
  { id: '40021', name: "Joel Payamps", position: 'RP', teamId: 'mil' },
  { id: '4881978', name: "Elvis Peguero", position: 'RP', teamId: 'mil' },
  { id: '39825', name: "Freddy Peralta", position: 'SP', teamId: 'mil' },
  { id: '33950', name: "Colin Rea", position: 'SP', teamId: 'mil' },
  { id: '32684', name: "Joe Ross", position: 'SP', teamId: 'mil' },
  { id: '36048', name: "Thyago Vieira", position: 'RP', teamId: 'mil' },
  { id: '40922', name: "Bryse Wilson", position: 'RP', teamId: 'mil' },
  { id: '39895', name: "William Contreras", position: 'C', teamId: 'mil' },
  { id: '31095', name: "Gary Sanchez", position: 'C', teamId: 'mil' },
  { id: '33675', name: "Willy Adames", position: 'SS', teamId: 'mil' },
  { id: '35013', name: "Jake Bauers", position: '1B', teamId: 'mil' },
  { id: '4414183', name: "Tyler Black", position: '3B', teamId: 'mil' },
  { id: '5001160', name: "Oliver Dunn", position: '3B', teamId: 'mil' },
  { id: '35291', name: "Rhys Hoskins", position: '1B', teamId: 'mil' },
  { id: '42958', name: "Joey Ortiz", position: '3B', teamId: 'mil' },
  { id: '41179', name: "Brice Turang", position: '2B', teamId: 'mil' },
  { id: '4917869', name: "Jackson Chourio", position: 'RF', teamId: 'mil' },
  { id: '4417795', name: "Sal Frelick", position: 'LF', teamId: 'mil' },
  { id: '36974', name: "Blake Perkins", position: 'CF', teamId: 'mil' },
  { id: '4417134', name: "Joey Wiemer", position: 'LF', teamId: 'mil' },
  { id: '38303', name: "David Bednar", position: 'RP', teamId: 'pit' },
  { id: '30442', name: "Aroldis Chapman", position: 'RP', teamId: 'pit' },
  { id: '40967', name: "Roansy Contreras", position: 'RP', teamId: 'pit' },
  { id: '4705677', name: "Bailey Falter", position: 'SP', teamId: 'pit' },
  { id: '42615', name: "Josh Fleming", position: 'RP', teamId: 'pit' },
  { id: '4917897', name: "Colin Holderman", position: 'RP', teamId: 'pit' },
  { id: '4918156', name: "Jared Jones", position: 'SP', teamId: 'pit' },
  { id: '33722', name: "Mitch Keller", position: 'SP', teamId: 'pit' },
  { id: '4415762', name: "Kyle Nicolas", position: 'RP', teamId: 'pit' },
  { id: '5101355', name: "Luis Ortiz", position: 'RP', teamId: 'pit' },
  { id: '31098', name: "Martin Perez", position: 'SP', teamId: 'pit' },
  { id: '42449', name: "Quinn Priester", position: 'SP', teamId: 'pit' },
  { id: '4987652', name: "Hunter Stratton", position: 'RP', teamId: 'pit' },
  { id: '41168', name: "Joey Bart", position: 'C', teamId: 'pit' },
  { id: '30950', name: "Yasmani Grandal", position: 'C', teamId: 'pit' },
  { id: '39712', name: "Oneil Cruz", position: 'SS', teamId: 'pit' },
  { id: '35020', name: "Ke'Bryan Hayes", position: '3B', teamId: 'pit' },
  { id: '34287', name: "Rowdy Tellez", position: '1B', teamId: 'pit' },
  { id: '42933', name: "Jared Triolo", position: '2B', teamId: 'pit' },
  { id: '4424289', name: "Alika Williams", position: '2B', teamId: 'pit' },
  { id: '35440', name: "Connor Joe", position: 'RF', teamId: 'pit' },
  { id: '28701', name: "Andrew McCutchen", position: 'RF', teamId: 'pit' },
  { id: '36463', name: "Edward Olivares", position: 'RF', teamId: 'pit' },
  { id: '38980', name: "Bryan Reynolds", position: 'LF', teamId: 'pit' },
  { id: '36754', name: "Jack Suwinski", position: 'LF', teamId: 'pit' },
  { id: '32219', name: "Michael A. Taylor", position: 'CF', teamId: 'pit' },
  { id: '5002952', name: "Ryan Fernandez", position: 'RP', teamId: 'stl' },
  { id: '36050', name: "Giovanny Gallegos", position: 'RP', teamId: 'stl' },
  { id: '31053', name: "Kyle Gibson", position: 'SP', teamId: 'stl' },
  { id: '32082', name: "Sonny Gray", position: 'SP', teamId: 'stl' },
  { id: '39909', name: "Ryan Helsley", position: 'RP', teamId: 'stl' },
  { id: '41763', name: "John King", position: 'RP', teamId: 'stl' },
  { id: '35872', name: "Andrew Kittredge", position: 'RP', teamId: 'stl' },
  { id: '5006093', name: "Kyle Leahy", position: 'RP', teamId: 'stl' },
  { id: '41173', name: "Matthew Liberatore", position: 'RP', teamId: 'stl' },
  { id: '30820', name: "Lance Lynn", position: 'SP', teamId: 'stl' },
  { id: '32116', name: "Miles Mikolas", position: 'SP', teamId: 'stl' },
  { id: '4311413', name: "Nick Robertson", position: 'RP', teamId: 'stl' },
  { id: '40456', name: "JoJo Romero", position: 'RP', teamId: 'stl' },
  { id: '32107', name: "Ryan Tepera", position: 'RP', teamId: 'stl' },
  { id: '32532', name: "Willson Contreras", position: 'C', teamId: 'stl' },
  { id: '41889', name: "Ivan Herrera", position: 'C', teamId: 'stl' },
  { id: '42608', name: "Pedro Pages", position: 'C', teamId: 'stl' },
  { id: '31261', name: "Nolan Arenado", position: '3B', teamId: 'stl' },
  { id: '30469', name: "Brandon Crawford", position: 'SS', teamId: 'stl' },
  { id: '38851', name: "Jose Fermin", position: '2B', teamId: 'stl' },
  { id: '31027', name: "Paul Goldschmidt", position: '1B', teamId: 'stl' },
  { id: '41174', name: "Nolan Gorman", position: '2B', teamId: 'stl' },
  { id: '4683365', name: "Masyn Winn", position: 'SS', teamId: 'stl' },
  { id: '4345070', name: "Alec Burleson", position: 'RF', teamId: 'stl' },
  { id: '41773', name: "Brendan Donovan", position: 'LF', teamId: 'stl' },
  { id: '4448736', name: "Lars Nootbaar", position: 'RF', teamId: 'stl' },
  { id: '41209', name: "Michael Siani", position: 'CF', teamId: 'stl' },
  { id: '36724', name: "Keegan Akin", position: 'RP', teamId: 'bal' },
  { id: '40543', name: "Mike Baumann", position: 'RP', teamId: 'bal' },
  { id: '4311625', name: "Kyle Bradish", position: 'SP', teamId: 'bal' },
  { id: '39878', name: "Corbin Burnes", position: 'SP', teamId: 'bal' },
  { id: '35536', name: "Yennier Cano", position: 'RP', teamId: 'bal' },
  { id: '33638', name: "Danny Coulombe", position: 'RP', teamId: 'bal' },
  { id: '39869', name: "Cole Irvin", position: 'SP', teamId: 'bal' },
  { id: '30653', name: "Craig Kimbrel", position: 'RP', teamId: 'bal' },
  { id: '38295', name: "Dean Kremer", position: 'SP', teamId: 'bal' },
  { id: '39948', name: "John Means", position: 'SP', teamId: 'bal' },
  { id: '36089', name: "Cionel Perez", position: 'RP', teamId: 'bal' },
  { id: '31109', name: "Albert Suarez", position: 'SP', teamId: 'bal' },
  { id: '40384', name: "Jacob Webb", position: 'RP', teamId: 'bal' },
  { id: '32046', name: "James McCann", position: 'C', teamId: 'bal' },
  { id: '42178', name: "Adley Rutschman", position: 'C', teamId: 'bal' },
  { id: '42507', name: "Gunnar Henderson", position: 'SS', teamId: 'bal' },
  { id: '33832', name: "Jorge Mateo", position: '2B', teamId: 'bal' },
  { id: '34940', name: "Ryan Mountcastle", position: '1B', teamId: 'bal' },
  { id: '35183', name: "Ryan O'Hearn", position: '1B', teamId: 'bal' },
  { id: '40610', name: "Ramon Urias", position: '3B', teamId: 'bal' },
  { id: '4298641', name: "Jordan Westburg", position: '3B', teamId: 'bal' },
  { id: '4416990', name: "Colton Cowser", position: 'LF', teamId: 'bal' },
  { id: '4297758', name: "Heston Kjerstad", position: 'RF', teamId: 'bal' },
  { id: '39147', name: "Ryan McKenna", position: 'RF', teamId: 'bal' },
  { id: '35578', name: "Cedric Mullins", position: 'CF', teamId: 'bal' },
  { id: '36084', name: "Anthony Santander", position: 'RF', teamId: 'bal' },
  { id: '32690', name: "Chase Anderson", position: 'RP', teamId: 'bos' },
  { id: '35876', name: "Brennan Bernardino", position: 'RP', teamId: 'bos' },
  { id: '2560565', name: "Cam Booser", position: 'RP', teamId: 'bos' },
  { id: '41277', name: "Kutter Crawford", position: 'SP', teamId: 'bos' },
  { id: '4326801', name: "Cooper Criswell", position: 'SP', teamId: 'bos' },
  { id: '41009', name: "Tanner Houck", position: 'SP', teamId: 'bos' },
  { id: '29630', name: "Kenley Jansen", position: 'RP', teamId: 'bos' },
  { id: '41569', name: "Zack Kelly", position: 'RP', teamId: 'bos' },
  { id: '32903', name: "Chris Martin", position: 'RP', teamId: 'bos' },
  { id: '4917925', name: "Justin Slaten", position: 'RP', teamId: 'bos' },
  { id: '5194586', name: "Naoyuki Uwasawa", position: 'SP', teamId: 'bos' },
  { id: '41485', name: "Greg Weissert", position: 'RP', teamId: 'bos' },
  { id: '4713488', name: "Josh Winckowski", position: 'RP', teamId: 'bos' },
  { id: '33250', name: "Reese McGuire", position: 'C', teamId: 'bos' },
  { id: '40086', name: "Connor Wong", position: 'C', teamId: 'bos' },
  { id: '33398', name: "Garrett Cooper", position: '1B', teamId: 'bos' },
  { id: '33859', name: "Rafael Devers", position: '3B', teamId: 'bos' },
  { id: '42503', name: "Vaughn Grissom", position: 'SS', teamId: 'bos' },
  { id: '42987', name: "David Hamilton", position: 'SS', teamId: 'bos' },
  { id: '36953', name: "Zack Short", position: '3B', teamId: 'bos' },
  { id: '33218', name: "Dominic Smith", position: '1B', teamId: 'bos' },
  { id: '4990055', name: "Wilyer Abreu", position: 'RF', teamId: 'bos' },
  { id: '41610', name: "Jarren Duran", position: 'LF', teamId: 'bos' },
  { id: '34168', name: "Tyler O'Neill", position: 'RF', teamId: 'bos' },
  { id: '4987382', name: "Ceddanne Rafaela", position: 'CF', teamId: 'bos' },
  { id: '33411', name: "Rob Refsnyder", position: 'RF', teamId: 'bos' },
  { id: '36480', name: "Nestor Cortes", position: 'SP', teamId: 'nyy' },
  { id: '41017', name: "Caleb Ferguson", position: 'RP', teamId: 'nyy' },
  { id: '40626', name: "Luis Gil", position: 'SP', teamId: 'nyy' },
  { id: '42340', name: "Victor Gonzalez", position: 'RP', teamId: 'nyy' },
  { id: '41127', name: "Ian Hamilton", position: 'RP', teamId: 'nyy' },
  { id: '32827', name: "Clay Holmes", position: 'RP', teamId: 'nyy' },
  { id: '4228723', name: "Ron Marinaccio", position: 'RP', teamId: 'nyy' },
  { id: '33696', name: "Carlos Rodon", position: 'SP', teamId: 'nyy' },
  { id: '39801', name: "Dennis Santana", position: 'RP', teamId: 'nyy' },
  { id: '41085', name: "Clarke Schmidt", position: 'SP', teamId: 'nyy' },
  { id: '32815', name: "Marcus Stroman", position: 'SP', teamId: 'nyy' },
  { id: '32594', name: "Michael Tonkin", position: 'RP', teamId: 'nyy' },
  { id: '33770', name: "Luke Weaver", position: 'RP', teamId: 'nyy' },
  { id: '35268', name: "Jose Trevino", position: 'C', teamId: 'nyy' },
  { id: '4683349', name: "Austin Wells", position: 'C', teamId: 'nyy' },
  { id: '40835', name: "Oswaldo Cabrera", position: '3B', teamId: 'nyy' },
  { id: '34958', name: "Jahmai Jones", position: '2B', teamId: 'nyy' },
  { id: '30782', name: "Anthony Rizzo", position: '1B', teamId: 'nyy' },
  { id: '33804', name: "Gleyber Torres", position: '2B', teamId: 'nyy' },
  { id: '42547', name: "Anthony Volpe", position: 'SS', teamId: 'nyy' },
  { id: '34995', name: "Trent Grisham", position: 'CF', teamId: 'nyy' },
  { id: '33192', name: "Aaron Judge", position: 'CF', teamId: 'nyy' },
  { id: '36969', name: "Juan Soto", position: 'RF', teamId: 'nyy' },
  { id: '37773', name: "Taylor Trammell", position: 'LF', teamId: 'nyy' },
  { id: '33786', name: "Alex Verdugo", position: 'LF', teamId: 'nyy' },
  { id: '30583', name: "Giancarlo Stanton", position: 'DH', teamId: 'nyy' },
  { id: '32145', name: "Jason Adam", position: 'RP', teamId: 'tb' },
  { id: '40360', name: "Tyler Alexander", position: 'SP', teamId: 'tb' },
  { id: '33499', name: "Shawn Armstrong", position: 'RP', teamId: 'tb' },
  { id: '40934', name: "Aaron Civale", position: 'SP', teamId: 'tb' },
  { id: '36429', name: "Garrett Cleavinger", position: 'RP', teamId: 'tb' },
  { id: '32804', name: "Zach Eflin", position: 'SP', teamId: 'tb' },
  { id: '5001153', name: "Kevin Kelly", position: 'RP', teamId: 'tb' },
  { id: '36052', name: "Zack Littell", position: 'SP', teamId: 'tb' },
  { id: '42239', name: "Jacob Lopez", position: 'RP', teamId: 'tb' },
  { id: '36161', name: "Phil Maton", position: 'RP', teamId: 'tb' },
  { id: '4208281', name: "Ryan Pepiot", position: 'SP', teamId: 'tb' },
  { id: '31983', name: "Erasmo Ramirez", position: 'RP', teamId: 'tb' },
  { id: '42363', name: "Manuel Rodriguez", position: 'RP', teamId: 'tb' },
  { id: '33707', name: "Alex Jackson", position: 'C', teamId: 'tb' },
  { id: '40869', name: "Ben Rortvedt", position: 'C', teamId: 'tb' },
  { id: '42135', name: "Jose Caballero", position: 'SS', teamId: 'tb' },
  { id: '33481', name: "Yandy Diaz", position: '1B', teamId: 'tb' },
  { id: '42360', name: "Curtis Mead", position: '2B', teamId: 'tb' },
  { id: '39706', name: "Isaac Paredes", position: '3B', teamId: 'tb' },
  { id: '42875', name: "Austin Shenton", position: '1B', teamId: 'tb' },
  { id: '36488', name: "Randy Arozarena", position: 'LF', teamId: 'tb' },
  { id: '5005912', name: "Jonny DeLuca", position: 'LF', teamId: 'tb' },
  { id: '41359', name: "Richie Palacios", position: 'RF', teamId: 'tb' },
  { id: '33259', name: "Harold Ramirez", position: 'LF', teamId: 'tb' },
  { id: '33215', name: "Amed Rosario", position: 'RF', teamId: 'tb' },
  { id: '33148', name: "Chris Bassitt", position: 'SP', teamId: 'tor' },
  { id: '32811', name: "Jose Berrios", position: 'SP', teamId: 'tor' },
  { id: '41061', name: "Genesis Cabrera", position: 'RP', teamId: 'tor' },
  { id: '32888', name: "Yimi Garcia", position: 'RP', teamId: 'tor' },
  { id: '32667', name: "Kevin Gausman", position: 'SP', teamId: 'tor' },
  { id: '41415', name: "Yusei Kikuchi", position: 'SP', teamId: 'tor' },
  { id: '39645', name: "Brendon Little", position: 'RP', teamId: 'tor' },
  { id: '35773', name: "Tim Mayza", position: 'RP', teamId: 'tor' },
  { id: '39646', name: "Nate Pearson", position: 'RP', teamId: 'tor' },
  { id: '41103', name: "Zach Pop", position: 'RP', teamId: 'tor' },
  { id: '39912', name: "Trevor Richards", position: 'RP', teamId: 'tor' },
  { id: '36380', name: "Jordan Romano", position: 'RP', teamId: 'tor' },
  { id: '36013', name: "Erik Swanson", position: 'RP', teamId: 'tor' },
  { id: '35004', name: "Danny Jansen", position: 'C', teamId: 'tor' },
  { id: '42081', name: "Alejandro Kirk", position: 'C', teamId: 'tor' },
  { id: '38904', name: "Bo Bichette", position: 'SS', teamId: 'tor' },
  { id: '37639', name: "Cavan Biggio", position: '2B', teamId: 'tor' },
  { id: '41287', name: "Ernie Clement", position: '3B', teamId: 'tor' },
  { id: '35002', name: "Vladimir Guerrero Jr.", position: '1B', teamId: 'tor' },
  { id: '33572', name: "Isiah Kiner-Falefa", position: '3B', teamId: 'tor' },
  { id: '29607', name: "Justin Turner", position: '3B', teamId: 'tor' },
  { id: '35682', name: "Nathan Lukes", position: 'RF', teamId: 'tor' },
  { id: '4997181', name: "Davis Schneider", position: 'LF', teamId: 'tor' },
  { id: '32078', name: "George Springer", position: 'RF', teamId: 'tor' },
  { id: '40963', name: "Daulton Varsho", position: 'LF', teamId: 'tor' },
  { id: '32851', name: "Daniel Vogelbach", position: 'DH', teamId: 'tor' },
  { id: '36131', name: "Aaron Bummer", position: 'RP', teamId: 'atl' },
  { id: '29220', name: "Jesse Chavez", position: 'RP', teamId: 'atl' },
  { id: '4301067', name: "Bryce Elder", position: 'SP', teamId: 'atl' },
  { id: '32685', name: "Max Fried", position: 'SP', teamId: 'atl' },
  { id: '33618', name: "Raisel Iglesias", position: 'RP', teamId: 'atl' },
  { id: '33760', name: "Joe Jimenez", position: 'RP', teamId: 'atl' },
  { id: '32777', name: "Pierce Johnson", position: 'RP', teamId: 'atl' },
  { id: '41455', name: "Dylan Lee", position: 'RP', teamId: 'atl' },
  { id: '33860', name: "Reynaldo Lopez", position: 'SP', teamId: 'atl' },
  { id: '31264', name: "Tyler Matzek", position: 'RP', teamId: 'atl' },
  { id: '36133', name: "A.J. Minter", position: 'RP', teamId: 'atl' },
  { id: '29155', name: "Charlie Morton", position: 'SP', teamId: 'atl' },
  { id: '30948', name: "Chris Sale", position: 'SP', teamId: 'atl' },
  { id: '29951', name: "Travis d'Arnaud", position: 'C', teamId: 'atl' },
  { id: '35358', name: "Chadwick Tromp", position: 'C', teamId: 'atl' },
  { id: '33783', name: "Ozzie Albies", position: '2B', teamId: 'atl' },
  { id: '32530', name: "Orlando Arcia", position: 'SS', teamId: 'atl' },
  { id: '35149', name: "Luis Guillorme", position: '2B', teamId: 'atl' },
  { id: '32767', name: "Matt Olson", position: '1B', teamId: 'atl' },
  { id: '34982', name: "Austin Riley", position: '3B', teamId: 'atl' },
  { id: '40466', name: "Luke Williams", position: 'SS', teamId: 'atl' },
  { id: '36185', name: "Ronald Acuna Jr.", position: 'RF', teamId: 'atl' },
  { id: '32664', name: "Adam Duvall", position: 'LF', teamId: 'atl' },
  { id: '42470', name: "Michael Harris II", position: 'CF', teamId: 'atl' },
  { id: '41150', name: "Jarred Kelenic", position: 'LF', teamId: 'atl' },
  { id: '31668', name: "Marcell Ozuna", position: 'DH', teamId: 'atl' },
  { id: '42171', name: "Anthony Bender", position: 'RP', teamId: 'mia' },
  { id: '4916509', name: "Huascar Brazoban", position: 'RP', teamId: 'mia' },
  { id: '40944', name: "Edward Cabrera", position: 'SP', teamId: 'mia' },
  { id: '5125258', name: "Declan Cronin", position: 'RP', teamId: 'mia' },
  { id: '3978173', name: "Calvin Faucher", position: 'RP', teamId: 'mia' },
  { id: '4228651', name: "Anthony Maldonado", position: 'RP', teamId: 'mia' },
  { id: '40391', name: "Darren McCaughan", position: 'RP', teamId: 'mia' },
  { id: '5116856', name: "Roddery Munoz", position: 'RP', teamId: 'mia' },
  { id: '3975840', name: "Andrew Nardi", position: 'RP', teamId: 'mia' },
  { id: '39640', name: "Trevor Rogers", position: 'SP', teamId: 'mia' },
  { id: '36186', name: "Sixto Sanchez", position: 'RP', teamId: 'mia' },
  { id: '35135', name: "Tanner Scott", position: 'RP', teamId: 'mia' },
  { id: '33083', name: "Burch Smith", position: 'RP', teamId: 'mia' },
  { id: '41178', name: "Ryan Weathers", position: 'SP', teamId: 'mia' },
  { id: '31758', name: "Christian Bethancourt", position: 'C', teamId: 'mia' },
  { id: '41674', name: "Nick Fortes", position: 'C', teamId: 'mia' },
  { id: '33184', name: "Tim Anderson", position: 'SS', teamId: 'mia' },
  { id: '39572', name: "Luis Arraez", position: '2B', teamId: 'mia' },
  { id: '32517', name: "Josh Bell", position: '1B', teamId: 'mia' },
  { id: '40462', name: "Vidal Brujan", position: '3B', teamId: 'mia' },
  { id: '41917', name: "Otto Lopez", position: '3B', teamId: 'mia' },
  { id: '40519', name: "Emmanuel Rivera", position: '3B', teamId: 'mia' },
  { id: '41433', name: "Jazz Chisholm Jr.", position: 'CF', teamId: 'mia' },
  { id: '40787', name: "Bryan De La Cruz", position: 'LF', teamId: 'mia' },
  { id: '33705', name: "Nick Gordon", position: 'LF', teamId: 'mia' },
  { id: '40048', name: "Dane Myers", position: 'RF', teamId: 'mia' },
  { id: '39957', name: "Jesus Sanchez", position: 'RF', teamId: 'mia' },
  { id: '4905900', name: "Jose Butto", position: 'SP', teamId: 'nym' },
  { id: '35394', name: "Edwin Diaz", position: 'RP', teamId: 'nym' },
  { id: '32178', name: "Jake Diekman", position: 'RP', teamId: 'nym' },
  { id: '36526', name: "Reed Garrett", position: 'RP', teamId: 'nym' },
  { id: '32157', name: "Adrian Houser", position: 'SP', teamId: 'nym' },
  { id: '33833', name: "Jorge Lopez", position: 'RP', teamId: 'nym' },
  { id: '33244', name: "Sean Manaea", position: 'SP', teamId: 'nym' },
  { id: '4717899', name: "Dedniel Nunez", position: 'RP', teamId: 'nym' },
  { id: '29705', name: "Adam Ottavino", position: 'RP', teamId: 'nym' },
  { id: '32106', name: "Jose Quintana", position: 'SP', teamId: 'nym' },
  { id: '33756', name: "Sean Reid-Foley", position: 'RP', teamId: 'nym' },
  { id: '33263', name: "Luis Severino", position: 'SP', teamId: 'nym' },
  { id: '40903', name: "Danny Young", position: 'RP', teamId: 'nym' },
  { id: '34254', name: "Omar Narvaez", position: 'C', teamId: 'nym' },
  { id: '36068', name: "Tomas Nido", position: 'C', teamId: 'nym' },
  { id: '37498', name: "Pete Alonso", position: '1B', teamId: 'nym' },
  { id: '42414', name: "Brett Baty", position: '3B', teamId: 'nym' },
  { id: '32129', name: "Francisco Lindor", position: 'SS', teamId: 'nym' },
  { id: '33900', name: "Jeff McNeil", position: '2B', teamId: 'nym' },
  { id: '33017', name: "Joey Wendle", position: '2B', teamId: 'nym' },
  { id: '35062', name: "Harrison Bader", position: 'CF', teamId: 'nym' },
  { id: '30830', name: "Starling Marte", position: 'RF', teamId: 'nym' },
  { id: '32159', name: "Brandon Nimmo", position: 'LF', teamId: 'nym' },
  { id: '34942', name: "DJ Stewart", position: 'RF', teamId: 'nym' },
  { id: '32783', name: "Tyrone Taylor", position: 'RF', teamId: 'nym' },
  { id: '31065', name: "J.D. Martinez", position: 'DH', teamId: 'nym' },
  { id: '36063', name: "Jose Alvarado", position: 'RP', teamId: 'phi' },
  { id: '37793', name: "Seranthony Dominguez", position: 'RP', teamId: 'phi' },
  { id: '33841', name: "Jeff Hoffman", position: 'RP', teamId: 'phi' },
  { id: '4630789', name: "Orion Kerkering", position: 'RP', teamId: 'phi' },
  { id: '33709', name: "Aaron Nola", position: 'SP', teamId: 'phi' },
  { id: '36053', name: "Jose Ruiz", position: 'RP', teamId: 'phi' },
  { id: '42359', name: "Cristopher Sanchez", position: 'SP', teamId: 'phi' },
  { id: '39804', name: "Gregory Soto", position: 'RP', teamId: 'phi' },
  { id: '34862', name: "Matt Strahm", position: 'RP', teamId: 'phi' },
  { id: '39817', name: "Ranger Suarez", position: 'SP', teamId: 'phi' },
  { id: '33732', name: "Spencer Turnbull", position: 'SP', teamId: 'phi' },
  { id: '31864', name: "Taijuan Walker", position: 'SP', teamId: 'phi' },
  { id: '31267', name: "Zack Wheeler", position: 'SP', teamId: 'phi' },
  { id: '32177', name: "J.T. Realmuto", position: 'C', teamId: 'phi' },
  { id: '35760', name: "Garrett Stubbs", position: 'C', teamId: 'phi' },
  { id: '41169', name: "Alec Bohm", position: '3B', teamId: 'phi' },
  { id: '30951', name: "Bryce Harper", position: '1B', teamId: 'phi' },
  { id: '33809', name: "Edmundo Sosa", position: '3B', teamId: 'phi' },
  { id: '42417', name: "Bryson Stott", position: '2B', teamId: 'phi' },
  { id: '33710', name: "Trea Turner", position: 'SS', teamId: 'phi' },
  { id: '31187', name: "Nick Castellanos", position: 'RF', teamId: 'phi' },
  { id: '40803', name: "Brandon Marsh", position: 'LF', teamId: 'phi' },
  { id: '32422', name: "Whit Merrifield", position: 'LF', teamId: 'phi' },
  { id: '39897', name: "Cristian Pache", position: 'LF', teamId: 'phi' },
  { id: '42829', name: "Johan Rojas", position: 'CF', teamId: 'phi' },
  { id: '33712', name: "Kyle Schwarber", position: 'DH', teamId: 'phi' },
  { id: '34868', name: "Jacob Barnes", position: 'RP', teamId: 'wsh' },
  { id: '32147', name: "Matt Barnes", position: 'RP', teamId: 'wsh' },
  { id: '31313', name: "Patrick Corbin", position: 'SP', teamId: 'wsh' },
  { id: '36543', name: "Kyle Finnegan", position: 'RP', teamId: 'wsh' },
  { id: '33852', name: "Dylan Floro", position: 'RP', teamId: 'wsh' },
  { id: '39636', name: "MacKenzie Gore", position: 'SP', teamId: 'wsh' },
  { id: '33191', name: "Hunter Harvey", position: 'RP', teamId: 'wsh' },
  { id: '41290', name: "Jake Irvin", position: 'SP', teamId: 'wsh' },
  { id: '33139', name: "Derek Law", position: 'RP', teamId: 'wsh' },
  { id: '4683356', name: "Mitchell Parker", position: 'SP', teamId: 'wsh' },
  { id: '38582', name: "Tanner Rainey", position: 'RP', teamId: 'wsh' },
  { id: '40039', name: "Jordan Weems", position: 'RP', teamId: 'wsh' },
  { id: '33305', name: "Trevor Williams", position: 'SP', teamId: 'wsh' },
  { id: '40655', name: "Riley Adams", position: 'C', teamId: 'wsh' },
  { id: '38827', name: "Keibert Ruiz", position: 'C', teamId: 'wsh' },
  { id: '42402', name: "CJ Abrams", position: 'SS', teamId: 'wsh' },
  { id: '40459', name: "Luis Garcia Jr.", position: '2B', teamId: 'wsh' },
  { id: '4413982', name: "Trey Lipscomb", position: '3B', teamId: 'wsh' },
  { id: '34534', name: "Joey Meneses", position: '1B', teamId: 'wsh' },
  { id: '4728688', name: "Nasim Nunez", position: 'SS', teamId: 'wsh' },
  { id: '36180', name: "Nick Senzel", position: '3B', teamId: 'wsh' },
  { id: '32985', name: "Ildemaro Vargas", position: '2B', teamId: 'wsh' },
  { id: '38994', name: "Alex Call", position: 'RF', teamId: 'wsh' },
  { id: '31944', name: "Eddie Rosario", position: 'CF', teamId: 'wsh' },
  { id: '32833', name: "Jesse Winker", position: 'LF', teamId: 'wsh' },
  { id: '4414210', name: "Jacob Young", position: 'CF', teamId: 'wsh' },
  { id: '41208', name: "Bryan Abreu", position: 'RP', teamId: 'hou' },
  { id: '4726080', name: "Spencer Arrighetti", position: 'SP', teamId: 'hou' },
  { id: '41260', name: "Brandon Bielak", position: 'RP', teamId: 'hou' },
  { id: '41829', name: "Ronel Blanco", position: 'SP', teamId: 'hou' },
  { id: '4717803', name: "Hunter Brown", position: 'SP', teamId: 'hou' },
  { id: '42540', name: "Shawn Dubin", position: 'RP', teamId: 'hou' },
  { id: '32760', name: "Josh Hader", position: 'RP', teamId: 'hou' },
  { id: '42742', name: "Seth Martinez", position: 'RP', teamId: 'hou' },
  { id: '32631', name: "Rafael Montero", position: 'RP', teamId: 'hou' },
  { id: '33072', name: "Ryan Pressly", position: 'RP', teamId: 'hou' },
  { id: '39711', name: "Tayler Scott", position: 'RP', teamId: 'hou' },
  { id: '36581', name: "Framber Valdez", position: 'SP', teamId: 'hou' },
  { id: '6341', name: "Justin Verlander", position: 'SP', teamId: 'hou' },
  { id: '33229', name: "Victor Caratini", position: 'C', teamId: 'hou' },
  { id: '4781491', name: "Yainer Diaz", position: 'C', teamId: 'hou' },
  { id: '31662', name: "Jose Altuve", position: '2B', teamId: 'hou' },
  { id: '42106', name: "Jacob Amaya", position: 'SS', teamId: 'hou' },
  { id: '34886', name: "Alex Bregman", position: '3B', teamId: 'hou' },
  { id: '41781', name: "Trey Cabbage", position: '1B', teamId: 'hou' },
  { id: '41273', name: "Jeremy Pena", position: 'SS', teamId: 'hou' },
  { id: '31206', name: "Jon Singleton", position: '1B', teamId: 'hou' },
  { id: '36018', name: "Yordan Alvarez", position: 'LF', teamId: 'hou' },
  { id: '35304', name: "Mauricio Dubon", position: 'CF', teamId: 'hou' },
  { id: '4297976', name: "Joey Loperfido", position: 'CF', teamId: 'hou' },
  { id: '40719', name: "Jake Meyers", position: 'CF', teamId: 'hou' },
  { id: '34967', name: "Kyle Tucker", position: 'RF', teamId: 'hou' },
  { id: '32151', name: "Tyler Anderson", position: 'SP', teamId: 'laa' },
  { id: '41065', name: "Griffin Canning", position: 'SP', teamId: 'laa' },
  { id: '35780', name: "Adam Cimber", position: 'RP', teamId: 'laa' },
  { id: '4326697', name: "Reid Detmers", position: 'SP', teamId: 'laa' },
  { id: '34861', name: "Carlos Estevez", position: 'RP', teamId: 'laa' },
  { id: '34913', name: "Carson Fulmer", position: 'RP', teamId: 'laa' },
  { id: '33089', name: "Luis Garcia", position: 'RP', teamId: 'laa' },
  { id: '33652', name: "Amir Garrett", position: 'RP', teamId: 'laa' },
  { id: '31099', name: "Matt Moore", position: 'RP', teamId: 'laa' },
  { id: '40975', name: "Patrick Sandoval", position: 'SP', teamId: 'laa' },
  { id: '40973', name: "Jose Soriano", position: 'SP', teamId: 'laa' },
  { id: '32589', name: "Hunter Strickland", position: 'RP', teamId: 'laa' },
  { id: '40981', name: "Jose Suarez", position: 'RP', teamId: 'laa' },
  { id: '42047', name: "Logan O'Hoppe", position: 'C', teamId: 'laa' },
  { id: '36176', name: "Matt Thaiss", position: 'C', teamId: 'laa' },
  { id: '30471', name: "Ehire Adrianza", position: '3B', teamId: 'laa' },
  { id: '32269', name: "Brandon Drury", position: '2B', teamId: 'laa' },
  { id: '4666100', name: "Zach Neto", position: 'SS', teamId: 'laa' },
  { id: '37237', name: "Luis Rengifo", position: '2B', teamId: 'laa' },
  { id: '4739755', name: "Nolan Schanuel", position: '1B', teamId: 'laa' },
  { id: '33789', name: "Cole Tucker", position: 'SS', teamId: 'laa' },
  { id: '40854', name: "Jo Adell", position: 'RF', teamId: 'laa' },
  { id: '35238', name: "Willie Calhoun", position: 'RF', teamId: 'laa' },
  { id: '36181', name: "Mickey Moniak", position: 'RF', teamId: 'laa' },
  { id: '32859', name: "Kevin Pillar", position: 'RF', teamId: 'laa' },
  { id: '34923', name: "Taylor Ward", position: 'LF', teamId: 'laa' },
  { id: '36046', name: "Austin Adams", position: 'RP', teamId: 'oak' },
  { id: '32776', name: "Paul Blackburn", position: 'SP', teamId: 'oak' },
  { id: '4298087', name: "Joe Boyle", position: 'SP', teamId: 'oak' },
  { id: '36618', name: "Lucas Erceg", position: 'RP', teamId: 'oak' },
  { id: '42380', name: "Dany Jimenez", position: 'RP', teamId: 'oak' },
  { id: '36156', name: "Michael Kelly", position: 'RP', teamId: 'oak' },
  { id: '32680', name: "T.J. McFarland", position: 'RP', teamId: 'oak' },
  { id: '4730225', name: "Mason Miller", position: 'RP', teamId: 'oak' },
  { id: '41041', name: "Kyle Muller", position: 'RP', teamId: 'oak' },
  { id: '39818', name: "JP Sears", position: 'SP', teamId: 'oak' },
  { id: '4987645', name: "Mitch Spence", position: 'RP', teamId: 'oak' },
  { id: '32789', name: "Ross Stripling", position: 'SP', teamId: 'oak' },
  { id: '32620', name: "Alex Wood", position: 'SP', teamId: 'oak' },
  { id: '42598', name: "Shea Langeliers", position: 'C', teamId: 'oak' },
  { id: '42901', name: "Kyle McCann", position: 'C', teamId: 'oak' },
  { id: '33796', name: "J.D. Davis", position: '3B', teamId: 'oak' },
  { id: '5133869', name: "Brett Harris", position: '3B', teamId: 'oak' },
  { id: '43025', name: "Darell Hernaiz", position: '3B', teamId: 'oak' },
  { id: '3962127', name: "Max Schuemann", position: '2B', teamId: 'oak' },
  { id: '40754', name: "Abraham Toro", position: '3B', teamId: 'oak' },
  { id: '42410', name: "JJ Bleday", position: 'CF', teamId: 'oak' },
  { id: '35676', name: "Seth Brown", position: 'LF', teamId: 'oak' },
  { id: '4917919', name: "Lawrence Butler", position: 'RF', teamId: 'oak' },
  { id: '39608', name: "Tyler Nevin", position: 'RF', teamId: 'oak' },
  { id: '40926', name: "Brent Rooker", position: 'RF', teamId: 'oak' },
  { id: '39680', name: "Esteury Ruiz", position: 'LF', teamId: 'oak' },
  { id: '41407', name: "Cody Bolton", position: 'RP', teamId: 'sea' },
  { id: '35124', name: "Luis Castillo", position: 'SP', teamId: 'sea' },
  { id: '41221', name: "Logan Gilbert", position: 'SP', teamId: 'sea' },
  { id: '4297897', name: "Emerson Hancock", position: 'SP', teamId: 'sea' },
  { id: '42406', name: "George Kirby", position: 'SP', teamId: 'sea' },
  { id: '4654313', name: "Bryce Miller", position: 'SP', teamId: 'sea' },
  { id: '42362', name: "Tyson Miller", position: 'RP', teamId: 'sea' },
  { id: '40939', name: "Andres Munoz", position: 'RP', teamId: 'sea' },
  { id: '42683', name: "Tayler Saucedo", position: 'RP', teamId: 'sea' },
  { id: '40327', name: "Gabe Speier", position: 'RP', teamId: 'sea' },
  { id: '33301', name: "Ryne Stanek", position: 'RP', teamId: 'sea' },
  { id: '36728', name: "Trent Thornton", position: 'RP', teamId: 'sea' },
  { id: '35044', name: "Austin Voth", position: 'RP', teamId: 'sea' },
  { id: '41292', name: "Cal Raleigh", position: 'C', teamId: 'sea' },
  { id: '37919', name: "Seby Zavala", position: 'C', teamId: 'sea' },
  { id: '35591', name: "Ty France", position: '1B', teamId: 'sea' },
  { id: '40593', name: "Dylan Moore", position: 'SS', teamId: 'sea' },
  { id: '32525', name: "Jorge Polanco", position: '2B', teamId: 'sea' },
  { id: '40858', name: "Leonardo Rivas", position: 'SS', teamId: 'sea' },
  { id: '40718', name: "Josh Rojas", position: '3B', teamId: 'sea' },
  { id: '35383', name: "Luis Urias", position: '3B', teamId: 'sea' },
  { id: '38347', name: "Sam Haggerty", position: 'LF', teamId: 'sea' },
  { id: '32771', name: "Mitch Haniger", position: 'RF', teamId: 'sea' },
  { id: '40422', name: "Luke Raley", position: 'RF', teamId: 'sea' },
  { id: '41044', name: "Julio Rodriguez", position: 'CF', teamId: 'sea' },
  { id: '33667', name: "Mitch Garver", position: 'DH', teamId: 'sea' },
  { id: '36080', name: "Dane Dunning", position: 'SP', teamId: 'tex' },
  { id: '33203', name: "Jon Gray", position: 'SP', teamId: 'tex' },
  { id: '32672', name: "Andrew Heaney", position: 'SP', teamId: 'tex' },
  { id: '39814', name: "Jonathan Hernandez", position: 'RP', teamId: 'tex' },
  { id: '3210625', name: "Jacob Latz", position: 'RP', teamId: 'tex' },
  { id: '33926', name: "Jose Leclerc", position: 'RP', teamId: 'tex' },
  { id: '33252', name: "Michael Lorenzen", position: 'SP', teamId: 'tex' },
  { id: '29172', name: "David Robertson", position: 'RP', teamId: 'tex' },
  { id: '41267', name: "Yerry Rodriguez", position: 'RP', teamId: 'tex' },
  { id: '36178', name: "Josh Sborz", position: 'RP', teamId: 'tex' },
  { id: '33107', name: "Jose Urena", position: 'RP', teamId: 'tex' },
  { id: '41177', name: "Cole Winn", position: 'RP', teamId: 'tex' },
  { id: '32623', name: "Kirby Yates", position: 'RP', teamId: 'tex' },
  { id: '33842', name: "Jonah Heim", position: 'C', teamId: 'tex' },
  { id: '36170', name: "Andrew Knizner", position: 'C', teamId: 'tex' },
  { id: '42457', name: "Ezequiel Duran", position: '1B', teamId: 'tex' },
  { id: '40538', name: "Nathaniel Lowe", position: '1B', teamId: 'tex' },
  { id: '32691', name: "Corey Seager", position: 'SS', teamId: 'tex' },
  { id: '32146', name: "Marcus Semien", position: '2B', teamId: 'tex' },
  { id: '42884', name: "Josh Smith", position: '3B', teamId: 'tex' },
  { id: '42435', name: "Davis Wendzel", position: '3B', teamId: 'tex' },
  { id: '4917921', name: "Evan Carter", position: 'LF', teamId: 'tex' },
  { id: '35537', name: "Adolis Garcia", position: 'RF', teamId: 'tex' },
  { id: '32751', name: "Travis Jankowski", position: 'CF', teamId: 'tex' },
  { id: '4719324', name: "Wyatt Langford", position: 'LF', teamId: 'tex' },
  { id: '34951', name: "Leody Taveras", position: 'CF', teamId: 'tex' },
  { id: '33685', name: "Matt Bowman", position: 'RP', teamId: 'ari' },
  { id: '41462', name: "Slade Cecconi", position: 'SP', teamId: 'ari' },
  { id: '39910', name: "Zac Gallen", position: 'SP', teamId: 'ari' },
  { id: '41432', name: "Kevin Ginkel", position: 'RP', teamId: 'ari' },
  { id: '5001301', name: "Brandon Hughes", position: 'RP', teamId: 'ari' },
  { id: '4297972', name: "Bryce Jarvis", position: 'RP', teamId: 'ari' },
  { id: '33691', name: "Joe Mantiply", position: 'RP', teamId: 'ari' },
  { id: '5116851', name: "Justin Martinez", position: 'RP', teamId: 'ari' },
  { id: '34834', name: "Scott McGough", position: 'RP', teamId: 'ari' },
  { id: '38173', name: "Jordan Montgomery", position: 'SP', teamId: 'ari' },
  { id: '4721302', name: "Brandon Pfaadt", position: 'SP', teamId: 'ari' },
  { id: '38628', name: "Ryan Thompson", position: 'RP', teamId: 'ari' },
  { id: '31173', name: "Tucker Barnhart", position: 'C', teamId: 'ari' },
  { id: '42464', name: "Gabriel Moreno", position: 'C', teamId: 'ari' },
  { id: '41345', name: "Blaze Alexander", position: 'SS', teamId: 'ari' },
  { id: '32512', name: "Ketel Marte", position: '2B', teamId: 'ari' },
  { id: '35022', name: "Kevin Newman", position: 'SS', teamId: 'ari' },
  { id: '32367', name: "Eugenio Suarez", position: '3B', teamId: 'ari' },
  { id: '32758', name: "Christian Walker", position: '1B', teamId: 'ari' },
  { id: '42404', name: "Corbin Carroll", position: 'CF', teamId: 'ari' },
  { id: '31399', name: "Randal Grichuk", position: 'RF', teamId: 'ari' },
  { id: '36040', name: "Lourdes Gurriel Jr.", position: 'LF', teamId: 'ari' },
  { id: '41197', name: "Jake McCarthy", position: 'RF', teamId: 'ari' },
  { id: '40767', name: "Pavin Smith", position: 'RF', teamId: 'ari' },
  { id: '31392', name: "Joc Pederson", position: 'DH', teamId: 'ari' },
  { id: '36723', name: "Jalen Beeks", position: 'RP', teamId: 'col' },
  { id: '3459223', name: "Jake Bird", position: 'RP', teamId: 'col' },
  { id: '33138', name: "Ty Blach", position: 'SP', teamId: 'col' },
  { id: '5185106', name: "Angel Chivilli", position: 'RP', teamId: 'col' },
  { id: '4019484', name: "Ryan Feltner", position: 'SP', teamId: 'col' },
  { id: '34897', name: "Austin Gomber", position: 'SP', teamId: 'col' },
  { id: '38680', name: "Dakota Hudson", position: 'SP', teamId: 'col' },
  { id: '34166', name: "Tyler Kinley", position: 'RP', teamId: 'col' },
  { id: '39898', name: "Peter Lambert", position: 'RP', teamId: 'col' },
  { id: '42620', name: "Nick Mears", position: 'RP', teamId: 'col' },
  { id: '5016983', name: "Anthony Molina", position: 'RP', teamId: 'col' },
  { id: '39875', name: "Cal Quantrill", position: 'SP', teamId: 'col' },
  { id: '4722599', name: "Victor Vodnik", position: 'RP', teamId: 'col' },
  { id: '33594', name: "Elias Diaz", position: 'C', teamId: 'col' },
  { id: '34089', name: "Jacob Stallings", position: 'C', teamId: 'col' },
  { id: '33247', name: "Ryan McMahon", position: '3B', teamId: 'col' },
  { id: '41222', name: "Elehuris Montero", position: '1B', teamId: 'col' },
  { id: '35073', name: "Brendan Rodgers", position: '2B', teamId: 'col' },
  { id: '4905919', name: "Ezequiel Tovar", position: 'SS', teamId: 'col' },
  { id: '40572', name: "Alan Trejo", position: '2B', teamId: 'col' },
  { id: '4619460', name: "Jordan Beck", position: 'LF', teamId: 'col' },
  { id: '31084', name: "Charlie Blackmon", position: 'RF', teamId: 'col' },
  { id: '41989', name: "Sean Bouchard", position: 'RF', teamId: 'col' },
  { id: '33417', name: "Jake Cave", position: 'RF', teamId: 'col' },
  { id: '42462', name: "Brenton Doyle", position: 'CF', teamId: 'col' },
  { id: '4416591', name: "Hunter Goodman", position: 'RF', teamId: 'col' },
  { id: '41421', name: "Nabil Crismatt", position: 'RP', teamId: 'lad' },
  { id: '36011', name: "J.P. Feyereisen", position: 'RP', teamId: 'lad' },
  { id: '33190', name: "Tyler Glasnow", position: 'SP', teamId: 'lad' },
  { id: '41242', name: "Michael Grove", position: 'RP', teamId: 'lad' },
  { id: '30376', name: "Daniel Hudson", position: 'RP', teamId: 'lad' },
  { id: '31992', name: "Joe Kelly", position: 'RP', teamId: 'lad' },
  { id: '31980', name: "James Paxton", position: 'SP', teamId: 'lad' },
  { id: '37911', name: "Evan Phillips", position: 'RP', teamId: 'lad' },
  { id: '4417230', name: "Gavin Stone", position: 'SP', teamId: 'lad' },
  { id: '41552', name: "Gus Varland", position: 'RP', teamId: 'lad' },
  { id: '42622', name: "Alex Vesia", position: 'RP', teamId: 'lad' },
  { id: '4872587', name: "Yoshinobu Yamamoto", position: 'SP', teamId: 'lad' },
  { id: '33735', name: "Ryan Yarbrough", position: 'RP', teamId: 'lad' },
  { id: '32856', name: "Austin Barnes", position: 'C', teamId: 'lad' },
  { id: '38309', name: "Will Smith", position: 'C', teamId: 'lad' },
  { id: '33039', name: "Mookie Betts", position: 'SS', teamId: 'lad' },
  { id: '30193', name: "Freddie Freeman", position: '1B', teamId: 'lad' },
  { id: '40423', name: "Gavin Lux", position: '2B', teamId: 'lad' },
  { id: '33303', name: "Max Muncy", position: '3B', teamId: 'lad' },
  { id: '30791', name: "Miguel Rojas", position: 'SS', teamId: 'lad' },
  { id: '31358', name: "Enrique Hernandez", position: 'LF', teamId: 'lad' },
  { id: '33377', name: "Teoscar Hernandez", position: 'RF', teamId: 'lad' },
  { id: '41802', name: "James Outman", position: 'CF', teamId: 'lad' },
  { id: '42468', name: "Andy Pages", position: 'RF', teamId: 'lad' },
  { id: '32900', name: "Chris Taylor", position: 'LF', teamId: 'lad' },
  { id: '39832', name: "Shohei Ohtani", position: 'DH', teamId: 'lad' },
  { id: '42864', name: "Jhony Brito", position: 'RP', teamId: 'sd' },
  { id: '34943', name: "Dylan Cease", position: 'SP', teamId: 'sd' },
  { id: '32055', name: "Yu Darvish", position: 'SP', teamId: 'sd' },
  { id: '35026', name: "Enyel De Los Santos", position: 'RP', teamId: 'sd' },
  { id: '41014', name: "Jeremiah Estrada", position: 'RP', teamId: 'sd' },
  { id: '40429', name: "Michael King", position: 'SP', teamId: 'sd' },
  { id: '5190073', name: "Stephen Kolek", position: 'RP', teamId: 'sd' },
  { id: '4142414', name: "Yuki Matsui", position: 'RP', teamId: 'sd' },
  { id: '40917', name: "Adrian Morejon", position: 'RP', teamId: 'sd' },
  { id: '34848', name: "Joe Musgrove", position: 'SP', teamId: 'sd' },
  { id: '36036', name: "Wandy Peralta", position: 'RP', teamId: 'sd' },
  { id: '4148749', name: "Robert Suarez", position: 'RP', teamId: 'sd' },
  { id: '4020158', name: "Matt Waldron", position: 'SP', teamId: 'sd' },
  { id: '40521', name: "Luis Campusano", position: 'C', teamId: 'sd' },
  { id: '30545', name: "Kyle Higashioka", position: 'C', teamId: 'sd' },
  { id: '31606', name: "Xander Bogaerts", position: '2B', teamId: 'sd' },
  { id: '36364', name: "Jake Cronenworth", position: '1B', teamId: 'sd' },
  { id: '4089862', name: "Ha-Seong Kim", position: 'SS', teamId: 'sd' },
  { id: '31097', name: "Manny Machado", position: '3B', teamId: 'sd' },
  { id: '4622931', name: "Graham Pauley", position: '3B', teamId: 'sd' },
  { id: '36745', name: "Eguy Rosario", position: '3B', teamId: 'sd' },
  { id: '33878', name: "Tyler Wade", position: '3B', teamId: 'sd' },
  { id: '35058', name: "Jose Azocar", position: 'LF', teamId: 'sd' },
  { id: '4872691', name: "Jackson Merrill", position: 'CF', teamId: 'sd' },
  { id: '31117', name: "Jurickson Profar", position: 'LF', teamId: 'sd' },
  { id: '35983', name: "Fernando Tatis Jr.", position: 'RF', teamId: 'sd' },
  { id: '41337', name: "Camilo Doval", position: 'RP', teamId: 'sf' },
  { id: '4683375', name: "Kyle Harrison", position: 'SP', teamId: 'sf' },
  { id: '37909', name: "Jordan Hicks", position: 'SP', teamId: 'sf' },
  { id: '41206', name: "Sean Hjelle", position: 'RP', teamId: 'sf' },
  { id: '31749', name: "Luke Jackson", position: 'RP', teamId: 'sf' },
  { id: '4152950', name: "Erik Miller", position: 'RP', teamId: 'sf' },
  { id: '4919929', name: "Randy Rodriguez", position: 'RP', teamId: 'sf' },
  { id: '33671', name: "Taylor Rogers", position: 'RP', teamId: 'sf' },
  { id: '34026', name: "Tyler Rogers", position: 'RP', teamId: 'sf' },
  { id: '5000950', name: "Ryan Walker", position: 'RP', teamId: 'sf' },
  { id: '41216', name: "Logan Webb", position: 'SP', teamId: 'sf' },
  { id: '39437', name: "Mitch White", position: 'RP', teamId: 'sf' },
  { id: '5116843', name: "Keaton Winn", position: 'SP', teamId: 'sf' },
  { id: '4345843', name: "Patrick Bailey", position: 'C', teamId: 'sf' },
  { id: '33225', name: "Tom Murphy", position: 'C', teamId: 'sf' },
  { id: '32355', name: "Nick Ahmed", position: 'SS', teamId: 'sf' },
  { id: '33857', name: "Matt Chapman", position: '3B', teamId: 'sf' },
  { id: '36485', name: "Thairo Estrada", position: '2B', teamId: 'sf' },
  { id: '43030', name: "Tyler Fitzgerald", position: 'SS', teamId: 'sf' },
  { id: '30627', name: "Wilmer Flores", position: '1B', teamId: 'sf' },
  { id: '37798', name: "LaMonte Wade Jr.", position: '1B', teamId: 'sf' },
  { id: '33711', name: "Michael Conforto", position: 'LF', teamId: 'sf' },
  { id: '5134621', name: "Jung Hoo Lee", position: 'CF', teamId: 'sf' },
  { id: '35265', name: "Austin Slater", position: 'RF', teamId: 'sf' },
  { id: '33341', name: "Mike Yastrzemski", position: 'RF', teamId: 'sf' },
  { id: '32558', name: "Jorge Soler", position: 'DH', teamId: 'sf' },
];