<template>
  <div class="page-container">
    <div class="cards-container">
      <div class="table-controls">
          <NSelect 
            v-model:value="conferenceSelectValue"
            :options="conferenceSelectOptions"
            :on-update:value="updateConference"
            placeholder="Conference"
          />
          <NSelect
            v-model:value="weekSelectValue"
            :options="weekSelectOptions"
            :on-update:value="updateWeek"
            placeholder="Week"
          />
          <NSwitch
            v-model:value="showExpanded"
            :on-update:value="updateShowExpanded"
            size="large"
          >
            <template #checked>Expanded</template>
            <template #unchecked>Standard</template>
          </NSwitch>
      </div>

      <DataTable
        :columns="tableColumns"
        :data="tableData"
        :loading="tableLoading"
      />
    </div>
  </div>
</template>

<script setup>
/* eslint-disable */
import { h, onMounted, ref, defineProps, computed } from 'vue';
import { format, parse } from 'date-fns';
import DataTable from '@/components/DataTable';
import { NCard, NSelect, NTag, NSwitch, NButton, dateEnGB } from 'naive-ui';
import { STAT_MAP, REPORT_TYPES, reduceStats } from '@/util/util_cfb';
import * as _ from 'lodash';

const games = ref([]);
const teams = ref([]);
const calendar = ref([]);

const tableData = ref([]);
const tableColumns = ref([]);
const tableLoading = ref(true);

const showExpanded = ref(false);

const weekSelectValue = ref(null);
const weekSelectOptions = computed(() => {
  if (calendar.value) {
    return calendar.value.map((c) => {
      return { label: c.week, value: c.week };
    });
  }
  return [];
});

const conferenceSelectValue = ref(null);
const conferenceSelectOptions = computed(() => {
  if (games.value) {
    const groupedData = _.groupBy(games.value, 'home_conference');
    const conferences = Object.keys(groupedData);
    const mappedConferences = conferences.map((c) => {
      return { label: c, value: c };
    });
    return [{ label: 'All Conferences', value: 'all' }, ...mappedConferences];
  }
  return [];
});

const updateShowExpanded = (e) => {
  showExpanded.value = e;
  setTableData()
};

const updateWeek = (e) => {
  weekSelectValue.value = e;
  setTableData();
};

const updateConference = (e) => {
  conferenceSelectValue.value = e;
  setTableData();
};

const renderTeam = (row, isHome) => {
  const {
    logos,
    home_team,
    home_points,
    away_team,
    away_points,
  } = row;

  const homeWinner = home_points > away_points;
  const team = isHome ? home_team : away_team;
  const logo = isHome ? (logos.home[0]) : (logos.away[0]);
  const qualifier = isHome ? '@' : '';

  const divStyle = {
    display: 'flex',
    alignItems: 'center',
  };

  const imgStyle = {
    height: '20px',
    marginLeft: '6px',
    marginRight: '6px'
  };

  return h(
    'div',
    { style: divStyle },
    [ qualifier, h('img', { style: imgStyle, src: logo }), team ],
  );


  // const homeTag = h(
  //         NTag,
  //         { type: homeTagType, bordered: false, style: { marginLeft: '6px' } },
  //         { default: () => `@ ${row.away_team}` }
  //       );
};

const setTableData = () => {
  const filteredData = games.value.filter((g) => {
    const shouldFilterConferences = conferenceSelectValue.value
      && conferenceSelectValue.value != 'all';

    return shouldFilterConferences
      ? g.home_conference === conferenceSelectValue.value
      : true;
  });

  const transformedTableData = filteredData.map((g) => {
    const awayTeam = teams.value.find((t) => t.id === g.away_id);
    const homeTeam = teams.value.find((t) => t.id === g.home_id);

    const derivedComplete = g.away_points != null || g.home_points != null;
    const matchup = `${g.away_team} @ ${g.home_team}`;
    const date = new Date(g.start_date);
    const formattedDate = format(date, 'EEE LLL dd p' );
    const finalScore = `${g.away_points} - ${g.home_points}`;
    const result = derivedComplete ? finalScore : '-';

    return {
      ...g,
      matchup,
      result,
      date: formattedDate,
      derivedComplete,
      logos: {
        away: awayTeam?.logos || [''],
        home: homeTeam?.logos || [''],
      },
    };
  });

  const actionsColumn = [
    {
      title: 'Actions',
      key: 'actions',
      render(row) {
        return h(
          NButton,
          {
            size: 'small',
            onClick: () => {},
          },
          { default: () => 'Matchup Report' }
        );
      }
    },
  ];

  const expandedColumns = [
    {
      title: 'Away Pregame Elo',
      key: 'away_pregame_elo',
      sorter: 'default',
    },
    {
      title: 'Home Pregame Elo',
      key: 'home_pregame_elo',
      sorter: 'default',
    },
  ];

  const standardColumns = [
    {
      title: 'Matchup',
      key: 'away_team',
      render(row) {
        return renderTeam(row, false);
      },
    },
    {
      title: '',
      key: 'home_team',
      render(row) {
        return renderTeam(row, true);
      },
    },
    {
      title: 'Date',
      key: 'date',
      sorter(a, b) {
        return new Date(a.start_date) - new Date(b.start_date);
      },
    },
    {
      title: 'Result',
      key: 'result',
    },
  ];

  tableData.value = transformedTableData;

  if (showExpanded.value) {
    tableColumns.value = [
      ...standardColumns,
      ...expandedColumns,
      ...actionsColumn,
    ];
  } else {
    tableColumns.value = [
      ...standardColumns,
      ...actionsColumn,
    ];
  }

  tableLoading.value = false;
};

const fetchGamesData = async () => {
  tableLoading.value = true;

  const apiGamesData = await api({
    type: 'GAMES',
    year: 2023,
    week: 7,
    division: 'fbs',
    seasonType: 'regular',
  });

  const apiTeamsData = await api({
    type: 'TEAMS_FBS',
    year: 2023,
  });

  const apiCalendarData = await api({
    type: 'CALENDAR',
    year: 2023,
  });

  console.log(apiGamesData)
  console.log(apiCalendarData)

  games.value = apiGamesData;
  teams.value = apiTeamsData;
  calendar.value = apiCalendarData;
};

const api = async (options = {}) => {
  const params = new URLSearchParams({
    ...options,
  });
  const url = `http://localhost:3000/api?${params}`;
  const response = await fetch(url);
  return await response.json();
};

onMounted(async () => {
  await fetchGamesData();
  setTableData();
});

</script>

<style scoped>
.logo {
  height: 45px;
}
.n-switch {
  width: 350px;
}
</style>


