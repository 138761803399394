export const data = [
  {
      "id": "0712c8d73f82fce49c9dbbb923ce2323",
      "sport_key": "baseball_mlb",
      "sport_title": "MLB",
      "commence_time": "2024-05-08T19:38:00Z",
      "home_team": "Oakland Athletics",
      "away_team": "Texas Rangers",
      "bookmakers": [
          {
              "key": "draftkings",
              "title": "DraftKings",
              "last_update": "2024-05-08T21:34:17Z",
              "markets": [
                  {
                      "key": "h2h",
                      "last_update": "2024-05-08T21:34:17Z",
                      "outcomes": [
                          {
                              "name": "Oakland Athletics",
                              "price": -20000
                          },
                          {
                              "name": "Texas Rangers",
                              "price": 3000
                          }
                      ]
                  }
              ]
          }
      ]
  },
  {
      "id": "25ed3144984b4c06047482b33176b969",
      "sport_key": "baseball_mlb",
      "sport_title": "MLB",
      "commence_time": "2024-05-08T22:37:00Z",
      "home_team": "Oakland Athletics",
      "away_team": "Texas Rangers",
      "bookmakers": [
          {
              "key": "draftkings",
              "title": "DraftKings",
              "last_update": "2024-05-08T21:34:17Z",
              "markets": [
                  {
                      "key": "h2h",
                      "last_update": "2024-05-08T21:34:17Z",
                      "outcomes": [
                          {
                              "name": "Oakland Athletics",
                              "price": 114
                          },
                          {
                              "name": "Texas Rangers",
                              "price": -135
                          }
                      ]
                  }
              ]
          }
      ]
  },
  {
      "id": "aae36296012c13f63b9b17e7183bd876",
      "sport_key": "baseball_mlb",
      "sport_title": "MLB",
      "commence_time": "2024-05-08T22:41:00Z",
      "home_team": "Cincinnati Reds",
      "away_team": "Arizona Diamondbacks",
      "bookmakers": [
          {
              "key": "draftkings",
              "title": "DraftKings",
              "last_update": "2024-05-08T21:34:17Z",
              "markets": [
                  {
                      "key": "h2h",
                      "last_update": "2024-05-08T21:34:17Z",
                      "outcomes": [
                          {
                              "name": "Arizona Diamondbacks",
                              "price": -110
                          },
                          {
                              "name": "Cincinnati Reds",
                              "price": -110
                          }
                      ]
                  }
              ]
          }
      ]
  },
  {
      "id": "272f2e6aebb5fc2633f929cfc6fa7dde",
      "sport_key": "baseball_mlb",
      "sport_title": "MLB",
      "commence_time": "2024-05-08T22:46:00Z",
      "home_team": "Washington Nationals",
      "away_team": "Baltimore Orioles",
      "bookmakers": [
          {
              "key": "draftkings",
              "title": "DraftKings",
              "last_update": "2024-05-08T21:34:17Z",
              "markets": [
                  {
                      "key": "h2h",
                      "last_update": "2024-05-08T21:34:17Z",
                      "outcomes": [
                          {
                              "name": "Baltimore Orioles",
                              "price": -170
                          },
                          {
                              "name": "Washington Nationals",
                              "price": 142
                          }
                      ]
                  }
              ]
          }
      ]
  },
  {
      "id": "6dcc03b8aa0973b27a798549683b18ac",
      "sport_key": "baseball_mlb",
      "sport_title": "MLB",
      "commence_time": "2024-05-08T22:51:00Z",
      "home_team": "Tampa Bay Rays",
      "away_team": "Chicago White Sox",
      "bookmakers": [
          {
              "key": "draftkings",
              "title": "DraftKings",
              "last_update": "2024-05-08T21:34:17Z",
              "markets": [
                  {
                      "key": "h2h",
                      "last_update": "2024-05-08T21:34:17Z",
                      "outcomes": [
                          {
                              "name": "Chicago White Sox",
                              "price": 210
                          },
                          {
                              "name": "Tampa Bay Rays",
                              "price": -258
                          }
                      ]
                  }
              ]
          }
      ]
  },
  {
      "id": "7397061b41b616d97aee1c31594e1df1",
      "sport_key": "baseball_mlb",
      "sport_title": "MLB",
      "commence_time": "2024-05-08T23:06:00Z",
      "home_team": "New York Yankees",
      "away_team": "Houston Astros",
      "bookmakers": [
          {
              "key": "draftkings",
              "title": "DraftKings",
              "last_update": "2024-05-08T21:34:17Z",
              "markets": [
                  {
                      "key": "h2h",
                      "last_update": "2024-05-08T21:34:17Z",
                      "outcomes": [
                          {
                              "name": "Houston Astros",
                              "price": 136
                          },
                          {
                              "name": "New York Yankees",
                              "price": -162
                          }
                      ]
                  }
              ]
          }
      ]
  },
  {
      "id": "50cbdd8b8e4688e32685a692b6525ef6",
      "sport_key": "baseball_mlb",
      "sport_title": "MLB",
      "commence_time": "2024-05-08T23:20:00Z",
      "home_team": "Atlanta Braves",
      "away_team": "Boston Red Sox",
      "bookmakers": [
          {
              "key": "draftkings",
              "title": "DraftKings",
              "last_update": "2024-05-08T21:34:17Z",
              "markets": [
                  {
                      "key": "h2h",
                      "last_update": "2024-05-08T21:34:17Z",
                      "outcomes": [
                          {
                              "name": "Atlanta Braves",
                              "price": -218
                          },
                          {
                              "name": "Boston Red Sox",
                              "price": 180
                          }
                      ]
                  }
              ]
          }
      ]
  },
  {
      "id": "a82dd670921248f3774c1478e9099389",
      "sport_key": "baseball_mlb",
      "sport_title": "MLB",
      "commence_time": "2024-05-08T23:41:00Z",
      "home_team": "Minnesota Twins",
      "away_team": "Seattle Mariners",
      "bookmakers": [
          {
              "key": "draftkings",
              "title": "DraftKings",
              "last_update": "2024-05-08T21:34:17Z",
              "markets": [
                  {
                      "key": "h2h",
                      "last_update": "2024-05-08T21:34:17Z",
                      "outcomes": [
                          {
                              "name": "Minnesota Twins",
                              "price": -110
                          },
                          {
                              "name": "Seattle Mariners",
                              "price": -110
                          }
                      ]
                  }
              ]
          }
      ]
  },
  {
      "id": "f96f24b72dc3bbc928a084f1c4a53027",
      "sport_key": "baseball_mlb",
      "sport_title": "MLB",
      "commence_time": "2024-05-09T00:41:00Z",
      "home_team": "Colorado Rockies",
      "away_team": "San Francisco Giants",
      "bookmakers": [
          {
              "key": "draftkings",
              "title": "DraftKings",
              "last_update": "2024-05-08T21:34:17Z",
              "markets": [
                  {
                      "key": "h2h",
                      "last_update": "2024-05-08T21:34:17Z",
                      "outcomes": [
                          {
                              "name": "Colorado Rockies",
                              "price": 145
                          },
                          {
                              "name": "San Francisco Giants",
                              "price": -175
                          }
                      ]
                  }
              ]
          }
      ]
  },
  {
      "id": "6bfac1a7d0d1fee92ab65739bb3a7289",
      "sport_key": "baseball_mlb",
      "sport_title": "MLB",
      "commence_time": "2024-05-09T17:10:00Z",
      "home_team": "Cincinnati Reds",
      "away_team": "Arizona Diamondbacks",
      "bookmakers": [
          {
              "key": "draftkings",
              "title": "DraftKings",
              "last_update": "2024-05-08T21:34:17Z",
              "markets": [
                  {
                      "key": "h2h",
                      "last_update": "2024-05-08T21:34:17Z",
                      "outcomes": [
                          {
                              "name": "Arizona Diamondbacks",
                              "price": 102
                          },
                          {
                              "name": "Cincinnati Reds",
                              "price": -122
                          }
                      ]
                  }
              ]
          }
      ]
  },
  {
      "id": "2ce2ee34bf588d8febff4c329081e53e",
      "sport_key": "baseball_mlb",
      "sport_title": "MLB",
      "commence_time": "2024-05-09T17:10:00Z",
      "home_team": "Minnesota Twins",
      "away_team": "Seattle Mariners",
      "bookmakers": [
          {
              "key": "draftkings",
              "title": "DraftKings",
              "last_update": "2024-05-08T21:34:17Z",
              "markets": [
                  {
                      "key": "h2h",
                      "last_update": "2024-05-08T21:34:17Z",
                      "outcomes": [
                          {
                              "name": "Minnesota Twins",
                              "price": -130
                          },
                          {
                              "name": "Seattle Mariners",
                              "price": 110
                          }
                      ]
                  }
              ]
          }
      ]
  },
  {
      "id": "523cacaa0dfd246d7b1e3960ec462953",
      "sport_key": "baseball_mlb",
      "sport_title": "MLB",
      "commence_time": "2024-05-09T19:10:00Z",
      "home_team": "Colorado Rockies",
      "away_team": "San Francisco Giants",
      "bookmakers": [
          {
              "key": "draftkings",
              "title": "DraftKings",
              "last_update": "2024-05-08T21:34:17Z",
              "markets": [
                  {
                      "key": "h2h",
                      "last_update": "2024-05-08T21:34:17Z",
                      "outcomes": [
                          {
                              "name": "Colorado Rockies",
                              "price": 136
                          },
                          {
                              "name": "San Francisco Giants",
                              "price": -162
                          }
                      ]
                  }
              ]
          }
      ]
  },
  {
      "id": "e110e66cccb0c94f933832ba0c61349c",
      "sport_key": "baseball_mlb",
      "sport_title": "MLB",
      "commence_time": "2024-05-09T21:05:00Z",
      "home_team": "New York Yankees",
      "away_team": "Houston Astros",
      "bookmakers": [
          {
              "key": "draftkings",
              "title": "DraftKings",
              "last_update": "2024-05-08T21:34:17Z",
              "markets": [
                  {
                      "key": "h2h",
                      "last_update": "2024-05-08T21:34:17Z",
                      "outcomes": [
                          {
                              "name": "Houston Astros",
                              "price": 110
                          },
                          {
                              "name": "New York Yankees",
                              "price": -130
                          }
                      ]
                  }
              ]
          }
      ]
  },
  {
      "id": "ae2d0f3702e79c1435a04a8270dd97a1",
      "sport_key": "baseball_mlb",
      "sport_title": "MLB",
      "commence_time": "2024-05-09T23:40:00Z",
      "home_team": "Chicago White Sox",
      "away_team": "Cleveland Guardians",
      "bookmakers": [
          {
              "key": "draftkings",
              "title": "DraftKings",
              "last_update": "2024-05-08T21:34:17Z",
              "markets": [
                  {
                      "key": "h2h",
                      "last_update": "2024-05-08T21:34:17Z",
                      "outcomes": [
                          {
                              "name": "Chicago White Sox",
                              "price": 124
                          },
                          {
                              "name": "Cleveland Guardians",
                              "price": -148
                          }
                      ]
                  }
              ]
          }
      ]
  },
  {
      "id": "4eb9afb3f31367141ca170448f9a6c42",
      "sport_key": "baseball_mlb",
      "sport_title": "MLB",
      "commence_time": "2024-05-09T23:40:00Z",
      "home_team": "Milwaukee Brewers",
      "away_team": "St. Louis Cardinals",
      "bookmakers": [
          {
              "key": "draftkings",
              "title": "DraftKings",
              "last_update": "2024-05-08T21:34:17Z",
              "markets": [
                  {
                      "key": "h2h",
                      "last_update": "2024-05-08T21:34:17Z",
                      "outcomes": [
                          {
                              "name": "Milwaukee Brewers",
                              "price": -118
                          },
                          {
                              "name": "St. Louis Cardinals",
                              "price": -102
                          }
                      ]
                  }
              ]
          }
      ]
  },
  {
      "id": "cdfce13533ae13dd6b10955f948def7b",
      "sport_key": "baseball_mlb",
      "sport_title": "MLB",
      "commence_time": "2024-05-10T01:38:00Z",
      "home_team": "Los Angeles Angels",
      "away_team": "Kansas City Royals",
      "bookmakers": [
          {
              "key": "draftkings",
              "title": "DraftKings",
              "last_update": "2024-05-08T21:34:17Z",
              "markets": [
                  {
                      "key": "h2h",
                      "last_update": "2024-05-08T21:34:17Z",
                      "outcomes": [
                          {
                              "name": "Kansas City Royals",
                              "price": -112
                          },
                          {
                              "name": "Los Angeles Angels",
                              "price": -108
                          }
                      ]
                  }
              ]
          }
      ]
  }
]