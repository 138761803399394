import { teams } from '@/assets/nba_data';

export const formatName = (name) => {
  let parts = String(name).split('-');
  parts = parts.map((part) => part.toUpperCase());
  return parts.join(' ');
};

export const formatTeam = (teamId) => {
  const team = teams.find((team) => team.id === teamId)?.name;
  let parts = String(team).split('-');
  parts = parts.map((part) => part.toUpperCase());
  return parts.join(' ');
};