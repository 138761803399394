export const data = [
  {
      "id": "78a3a48434085cfbb5f7983d42dc179c",
      "sport_key": "basketball_nba",
      "sport_title": "NBA",
      "commence_time": "2024-03-14T23:30:00Z",
      "home_team": "Boston Celtics",
      "away_team": "Phoenix Suns",
      "bookmakers": [
          {
              "key": "draftkings",
              "title": "DraftKings",
              "last_update": "2024-03-14T14:17:13Z",
              "markets": [
                  {
                      "key": "h2h",
                      "last_update": "2024-03-14T14:17:13Z",
                      "outcomes": [
                          {
                              "name": "Boston Celtics",
                              "price": -238
                          },
                          {
                              "name": "Phoenix Suns",
                              "price": 195
                          }
                      ]
                  }
              ]
          }
      ]
  },
  {
      "id": "7cbfb7873ff913f858faa45de8542292",
      "sport_key": "basketball_nba",
      "sport_title": "NBA",
      "commence_time": "2024-03-15T00:10:00Z",
      "home_team": "Chicago Bulls",
      "away_team": "Los Angeles Clippers",
      "bookmakers": [
          {
              "key": "draftkings",
              "title": "DraftKings",
              "last_update": "2024-03-14T14:17:13Z",
              "markets": [
                  {
                      "key": "h2h",
                      "last_update": "2024-03-14T14:17:13Z",
                      "outcomes": [
                          {
                              "name": "Chicago Bulls",
                              "price": 200
                          },
                          {
                              "name": "Los Angeles Clippers",
                              "price": -245
                          }
                      ]
                  }
              ]
          }
      ]
  },
  {
      "id": "d117baaccf00e2edea89c26f4c601970",
      "sport_key": "basketball_nba",
      "sport_title": "NBA",
      "commence_time": "2024-03-15T00:10:00Z",
      "home_team": "Houston Rockets",
      "away_team": "Washington Wizards",
      "bookmakers": [
          {
              "key": "draftkings",
              "title": "DraftKings",
              "last_update": "2024-03-14T14:17:13Z",
              "markets": [
                  {
                      "key": "h2h",
                      "last_update": "2024-03-14T14:17:13Z",
                      "outcomes": [
                          {
                              "name": "Houston Rockets",
                              "price": -325
                          },
                          {
                              "name": "Washington Wizards",
                              "price": 260
                          }
                      ]
                  }
              ]
          }
      ]
  },
  {
      "id": "bfea34ef8e7f43f99339ea64b3839edc",
      "sport_key": "basketball_nba",
      "sport_title": "NBA",
      "commence_time": "2024-03-15T00:10:00Z",
      "home_team": "Milwaukee Bucks",
      "away_team": "Philadelphia 76ers",
      "bookmakers": [
          {
              "key": "draftkings",
              "title": "DraftKings",
              "last_update": "2024-03-14T14:17:13Z",
              "markets": [
                  {
                      "key": "h2h",
                      "last_update": "2024-03-14T14:17:13Z",
                      "outcomes": [
                          {
                              "name": "Milwaukee Bucks",
                              "price": -355
                          },
                          {
                              "name": "Philadelphia 76ers",
                              "price": 280
                          }
                      ]
                  }
              ]
          }
      ]
  },
  {
      "id": "2a046e868d0d19e025226a23072fb883",
      "sport_key": "basketball_nba",
      "sport_title": "NBA",
      "commence_time": "2024-03-15T02:00:00Z",
      "home_team": "Oklahoma City Thunder",
      "away_team": "Dallas Mavericks",
      "bookmakers": [
          {
              "key": "draftkings",
              "title": "DraftKings",
              "last_update": "2024-03-14T14:17:13Z",
              "markets": [
                  {
                      "key": "h2h",
                      "last_update": "2024-03-14T14:17:13Z",
                      "outcomes": [
                          {
                              "name": "Dallas Mavericks",
                              "price": 390
                          },
                          {
                              "name": "Oklahoma City Thunder",
                              "price": -520
                          }
                      ]
                  }
              ]
          }
      ]
  },
  {
      "id": "7afe77d7815ae0e81f928a83681d2d4d",
      "sport_key": "basketball_nba",
      "sport_title": "NBA",
      "commence_time": "2024-03-15T02:10:00Z",
      "home_team": "Portland Trail Blazers",
      "away_team": "New York Knicks",
      "bookmakers": [
          {
              "key": "draftkings",
              "title": "DraftKings",
              "last_update": "2024-03-14T14:17:13Z",
              "markets": [
                  {
                      "key": "h2h",
                      "last_update": "2024-03-14T14:17:13Z",
                      "outcomes": [
                          {
                              "name": "New York Knicks",
                              "price": -600
                          },
                          {
                              "name": "Portland Trail Blazers",
                              "price": 440
                          }
                      ]
                  }
              ]
          }
      ]
  }
]