<template>
  <div class="page-container">
    <div class="cards-container">
      <div v-for="conference in conferences" :key="conference" class="conference-cards">
        <NCard :title="conference" class="conference-card">
          <div v-for="team in teamDataByConferences[conference]" :key="team">
            <router-link :to="`/cfb/team/${team.id}`">
              {{ `${team.school} ${team.mascot}` }}
            </router-link>
          </div>
        </NCard>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { NCard } from 'naive-ui';
import * as _ from 'lodash';

const conferences = ref([]);
const teamDataByConferences = ref({});

const api = async () => {
  const params = new URLSearchParams({
    type: 'TEAMS_FBS',
    year: 2023,
  });
  const url = `http://localhost:3000/api?${params}`;
  const response = await fetch(url);
  return await response.json();
};

onMounted(async () => {
  const data = await api();
  const groupedData = _.groupBy(data, 'conference');
  conferences.value = Object.keys(groupedData);
  teamDataByConferences.value = groupedData;
});

</script>

<style>
.conference-cards {
}

.conference-card {
  margin: 5px;
}
</style>