<template>
  <div class="page-container">
    <div class="cards-container">
      <DataTable
        :data="tableData"
        :columns="tableColumns"
        :render-cell="renderFormattedCell"
      />
    </div>
  </div>
</template>

<script setup>
/* eslint-disable */
import { h, ref } from 'vue';
import { RouterLink } from 'vue-router';

import { teams } from '@/assets/cbb_data';
import { formatTeam, formatName } from '@/util/util_nba';
import DataTable from '@/components/DataTable';

const tableData = ref(teams);
const tableColumns = ref([
  { title: 'Team', key: 'name', align: 'right' },
  { title: 'Code', key: 'code', align: 'center' },
]);

const renderFormattedCell = (value, rowData, column) => {
  const to = `/cbb/team/${rowData.code}`;
  return h(RouterLink, { to }, { default: () => value });
}
</script>