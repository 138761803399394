<template>
  <div class="page-container">
    <div class="cards-container">
      <NSelect
        v-model:value="teamSelect"
        :options="teamSelectOptions"
        :on-update:value="updateTeam"
        placeholder="Team"
      />
      <NInput
        v-model:value="playerInput"
        :on-update:value="updatePlayer"
        type="text"
        placeholder="Player Search"
      />
      <DataTable
        :data="tableData"
        :columns="tableColumns"
        :render-cell="renderFormattedCell"
      />
    </div>
  </div>
</template>

<script setup>
/* eslint-disable */
import { h, ref } from 'vue';
import { RouterLink } from 'vue-router';
import { NSelect, NInput } from 'naive-ui';
import { teams, players } from '@/assets/nba_data';
import { formatTeam, formatName } from '@/util/util_nba';
import DataTable from '@/components/DataTable';
import _ from 'lodash';

const orderPlayers = (players) => {
  return _.orderBy(players, ['teamId', 'position'], ['asc', 'asc']);
};

const playersSorted = orderPlayers(players);

const tableData = ref(playersSorted);
const tableColumns = ref([
  { title: 'Player', key: 'name', align: 'right' },
  { title: 'Position', key: 'position', align: 'center' },
  { title: 'Team', key: 'teamId', align: 'center' },
]);

const playerInput = ref('');
const teamSelect = ref('all');

const teamSelectOptions = [{ id: 'all', name: 'all' }, ...teams].map((team) => {
  return {
    value: team.id,
    label: formatName(team.name),
  };
});

const updateTeam = (e) => {
  const selections = e === 'all' ? players : players.filter((player) => player.teamId === e);
  teamSelect.value = e;
  playerInput.value = '';
  tableData.value = orderPlayers(selections);
};

const updatePlayer = (e) => {
  const selections = players.filter((player) => {
    const searchValue = e?.toLowerCase();
    return player.name?.toLowerCase().includes(searchValue);
  });
  playerInput.value = e;
  teamSelect.value = 'all';
  tableData.value = orderPlayers(selections);
};

const renderFormattedCell = (value, rowData, column) => {
  if (column.key === 'name') {
    const to = `/nba/player/${rowData.id}`;
    return h(RouterLink, { to }, { default: () => value });
  } else if (column.key === 'position') {
    return value;
  }
  return formatTeam(value);
}
</script>
