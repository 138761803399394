<template>
  <div class="page-container">
    <div class="cards-container">
      <DataTable
        :data="tableData"
        :columns="tableColumns"
        :loading="tableLoading"
      />
    </div>
  </div>
</template>

<script setup>
/* eslint-disable */
import { h, ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { NText, NRadio, NSpace } from 'naive-ui';

import { teams } from '@/assets/cbb_data';
import { scatterChart } from '@/charts/scatter-chart';
import { formatName, formatTeam } from '@/util/util_nba';
import DataTable from '@/components/DataTable';

const apiData = ref({});
const tableData = ref([]);
const tableColumns = ref([]);
const tableLoading = ref(true);

const setTable = () => {
  tableData.value = apiData.value?.matchups;
  tableColumns.value = [
    { title: 'Team', key: 'team', align: 'right' },
  ];
  tableLoading.value = false;
};

const api = async () => {
  const endpoint = process.env.VUE_APP_INTERNAL_API_ENDPOINT;
  const url = `${endpoint}/api/cbb/matchups`;
  const response = await fetch(url);

  return await response.json();
};

const tryFetch = async () => {
  const data = await api();
  apiData.value = data;
  setTable();
};

onMounted(async () => {
  await tryFetch();
});
</script>

<style scoped>
img {
  margin-left: 10px;
}
.img-container {
  height: 125px;
  border-bottom: 2px solid #3f3f3f;
}
</style>