<template>
  <div class="page-container">
    <div class="cards-container">
      <NCard
        hoverable
        title="Players"
        @click="router.push('nba/players')"
      ></NCard>
      <NCard
        hoverable
        title="Today's Lines"
        @click="router.push('nba/lines')"
      ></NCard>
    </div>
  </div>
</template>

<script setup>
/* eslint-disable */
import { h, ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { NCard } from 'naive-ui';

const router = useRouter();
</script>

<style scoped>

</style>
