import { createRouter, createWebHashHistory } from 'vue-router';

import HomePage from '@/pages/HomePage';

import HomePageMLB from '@/pages/mlb/HomePageMLB';
import LinesPageMLB from '@/pages/mlb/LinesPageMLB';
import PlayerPageMLB from '@/pages/mlb/PlayerPageMLB';
import PlayersPageMLB from '@/pages/mlb/PlayersPageMLB';

import HomePageCFB from '@/pages/cfb/HomePageCFB';
import TeamPageCFB from '@/pages/cfb/TeamPageCFB';
import GamesPageCFB from '@/pages/cfb/GamesPageCFB';

import HomePageNBA from './pages/nba/HomePageNBA';
import LinesPageNBA from './pages/nba/LinesPageNBA';
import PlayerPageNBA from '@/pages/nba/PlayerPageNBA';
import PlayersPageNBA from '@/pages/nba/PlayersPageNBA';

import TeamPageCBB from '@/pages/cbb/TeamPageCBB';
import TeamsPageCBB from '@/pages/cbb/TeamsPageCBB';
import MatchupsPageCBB from '@/pages/cbb/MatchupsPageCBB';

const baseRoutes = [
  { path: '/', component: HomePage },
];

const mlbRoutes = [
  { path: '/mlb', component: HomePageMLB },
  { path: '/mlb/lines', component: LinesPageMLB },
  { path: '/mlb/players', component: PlayersPageMLB },
  { path: '/mlb/player/:id', component: PlayerPageMLB },
];

const nbaRoutes = [
  { path: '/nba', component: HomePageNBA },
  { path: '/nba/lines', component: LinesPageNBA },
  { path: '/nba/players', component: PlayersPageNBA },
  { path: '/nba/player/:id', component: PlayerPageNBA },
];

const cfbRoutes = [
  { path: '/cfb', component: HomePageCFB },
  { path: '/cfb/games', component: TeamPageCFB },
  { path: '/cfb/team/:id', component: GamesPageCFB },
];

const cbbRoutes = [
  { path: '/cbb/teams', component: TeamsPageCBB },
  { path: '/cbb/team/:id', component: TeamPageCBB },
  { path: '/cbb/matchups', component: MatchupsPageCBB },
];

const routes = [...baseRoutes, ...mlbRoutes, ...nbaRoutes, ...cfbRoutes, ...cbbRoutes];

export const router = createRouter({
  routes,
  history: createWebHashHistory(),
});
