<template>
  <div class="page-container">
    <div class="cards-container">
    </div>
  </div>
</template>

<style>
.home-page-container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 120px;
}
</style>
