export const teams = [
  {
    id: 'atl',
    name: 'atlanta-hawks',
    prettyName: 'Atlanta Hawks',
    colors: ['#C8102E', '#FDB927', '#000000'],
  },
  {
    id: 'bos',
    name: 'boston-celtics',
    prettyName: 'Boston Celtics',
    colors: ['#007A33', '#BA9653', '#963821'],
  },
  {
    id: 'bkn',
    name: 'brooklyn-nets',
    prettyName: 'Brooklyn Nets',
    colors: ['#000000', '#ffffff'],
  },
  {
    id: 'cha',
    name: 'charlotte-hornets',
    prettyName: 'Charlotte Hornets',
    colors: ['#1d1160', '#00788c', '#a1a1a4'],
  },
  {
    id: 'chi',
    name: 'chicago-bulls',
    prettyName: 'Chicago Bulls',
    colors: ['#ce1141', '#000000'],
  },
  {
    id: 'cle',
    name: 'cleveland-cavaliers',
    prettyName: 'Cleveland Cavaliers',
    colors: ['#860038', '#FDBB30', '#041E42'],
  },
  {
    id: 'dal',
    name: 'dallas-mavericks',
    prettyName: 'Dallas Mavericks',
    colors: ['#00538C', '#002B5E', '#B8C4CA'],
  },
  {
    id: 'den',
    name: 'denver-nuggets',
    prettyName: 'Denver Nuggets',
    colors: ['#0E2240', '#FEC524'],
  },
  {
    id: 'det',
    name: 'detroit-pistons',
    prettyName: 'Detroit Pistons',
    colors: ['#C8102E', '#1D42BA'],
  },
  {
    id: 'gs',
    name: 'golden-state-warriors',
    prettyName: 'Golden State Warriors',
    colors: ['#1D428A', '#FFC72C'],
  },
  {
    id: 'hou',
    name: 'houston-rockets',
    prettyName: 'Houston Rockets',
    colors: ['#CE1141', '#000000'],
  },
  {
    id: 'ind',
    name: 'indiana-pacers',
    prettyName: 'Indiana Pacers',
    colors: ['#002D62', '#FDBB30'],
  },
  {
    id: 'lac',
    name: 'la-clippers',
    prettyName: 'Los Angeles Clippers',
    colors: ['#C8102E', '#1D428A'],
  },
  {
    id: 'lal',
    name: 'los-angeles-lakers',
    prettyName: 'Los Angeles Lakers',
    colors: ['#552583', '#FDB927']
  },
  {
    id: 'mem',
    name: 'memphis-grizzlies',
    prettyName: 'Memphis Grizzlies',
    colors: ['#5D76A9', '#12173F'],
  },
  {
    id: 'mia',
    name: 'miami-heat',
    prettyName: 'Miami Heat',
    colors: ['#98002E', '#F9A01B'],
  },
  {
    id: 'mil',
    name: 'milwaukee-bucks',
    prettyName: 'Milwaukee Bucks',
    colors: ['#00471B', '#EEE1C6'],
  },
  {
    id: 'min',
    name: 'minnesota-timberwolves',
    prettyName: 'Minnesota Timberwolves',
    colors: ['#0C2340', '#23619'],
  },
  {
    id: 'no',
    name: 'new-orleans-pelicans',
    prettyName: 'New Orleans Pelicans',
    colors: ['#0C2340', '#85714D', '#C8102E'],
  },
  {
    id: 'ny',
    name: 'new-york-knicks',
    prettyName: 'New York Knicks',
    colors: ['#006BB6', '#F58426'],
  },
  {
    id: 'okc',
    name: 'oklahoma-city-thunder',
    prettyName: 'Oklahoma City Thunder',
    colors: ['#007AC1', '#EF3B24'],
  },
  {
    id: 'orl',
    name: 'orlando-magic',
    prettyName: 'Orlando Magic',
    colors: ['#0077C0', '#C4CED4'],
  },
  {
    id: 'phi',
    name: 'philadelphia-76ers',
    prettyName: 'Philadelphia 76ers',
    colors: ['#006BB6', '#ED174C'],
  },
  {
    id: 'phx',
    name: 'phoenix-suns',
    prettyName: 'Phoenix Suns',
    colors: ['#1D1160', '#E56020']
  },
  {
    id: 'por',
    name: 'portland-trail-blazers',
    prettyName: 'Portland Trail Blazers',
    colors: ['#E03A3E', '#000000'] },
  {
    id: 'sac',
    name: 'sacramento-kings',
    prettyName: 'Sacramento Kings',
    colors: ['#5A2D81', '#63727A'],
  },
  { 
    id: 'sa',
    name: 'san-antonio-spurs',
    prettyName: 'San Antonio Spurs',
    colors: ['#C4CED4', '#000000'],
  },
  {
    id: 'tor',
    name: 'toronto-raptors',
    prettyName: 'Toronto Raptors',
    colors: ['#CE1141', '#000000'],
  },
  {
    id: 'utah',
    name: 'utah-jazz',
    prettyName: 'Utah Jazz',
    colors: ['#002B5C', '#F9A01B'],
  },
  {
    id: 'wsh',
    name: 'washington-wizards',
    prettyName: 'Washington Wizards',
    colors: ['#002B5C', '#E31837'],
  },
];

export const players = [
  {
    id: '4278031',
    name: 'Oshae Brissett',
    position: 'SF',
    teamId: 'bos'
  },
  { id: '3917376', name: 'Jaylen Brown', position: 'SG', teamId: 'bos' },
  { id: '4576085', name: 'JD Davison', position: 'SG', teamId: 'bos' },
  { id: '4065804', name: 'Sam Hauser', position: 'SF', teamId: 'bos' },
  { id: '3995', name: 'Jrue Holiday', position: 'PG', teamId: 'bos' },
  { id: '3213', name: 'Al Horford', position: 'C', teamId: 'bos' },
  { id: '3064560', name: 'Luke Kornet', position: 'C', teamId: 'bos' },
  {
    id: '3133602',
    name: 'Svi Mykhailiuk',
    position: 'SG',
    teamId: 'bos'
  },
  { id: '4397689', name: 'Drew Peterson', position: 'F', teamId: 'bos' },
  {
    id: '3102531',
    name: 'Kristaps Porzingis',
    position: 'C',
    teamId: 'bos'
  },
  {
    id: '4066354',
    name: 'Payton Pritchard',
    position: 'PG',
    teamId: 'bos'
  },
  { id: '3136779', name: 'Keita Bates-Diop', position: 'SF', teamId: 'bkn' },
  { id: '3147657', name: 'Mikal Bridges', position: 'SF', teamId: 'bkn' },
  { id: '4278067', name: 'Nic Claxton', position: 'C', teamId: 'bkn' },
  { id: '4712896', name: 'Noah Clowney', position: 'F', teamId: 'bkn' },
  { id: '2578185', name: 'Dorian Finney-Smith', position: 'PF', teamId: 'bkn' },
  { id: '4278378', name: 'Jacob Gilyard', position: 'G', teamId: 'bkn' },
  { id: '3138196', name: 'Cameron Johnson', position: 'SF', teamId: 'bkn' },
  { id: '4432192', name: 'Keon Johnson', position: 'G', teamId: 'bkn' },
  { id: '5149711', name: 'Jaylen Martin', position: 'G', teamId: 'bkn' },
  { id: '3032979', name: 'Dennis Schroder', position: 'PG', teamId: 'bkn' },
  { id: '4432194', name: "Day'Ron Sharpe", position: 'C', teamId: 'bkn' },
  { id: '3907387', name: 'Ben Simmons', position: 'PG', teamId: 'bkn' },
  { id: '4065697', name: 'Dennis Smith Jr.', position: 'PG', teamId: 'bkn' },
  { id: '4432174', name: 'Cam Thomas', position: 'SG', teamId: 'bkn' },
  { id: '4277890', name: 'Lonnie Walker IV', position: 'G', teamId: 'bkn' },
  { id: '4431675', name: 'Trendon Watford', position: 'PF', teamId: 'bkn' },
  { id: '4432735', name: 'Dariq Whitehead', position: 'F', teamId: 'bkn' },
  { id: '4431714', name: 'Jalen Wilson', position: 'F', teamId: 'bkn' },
  { id: '4431679', name: 'Precious Achiuwa', position: 'PF', teamId: 'ny' },
  { id: '3934719', name: 'OG Anunoby', position: 'SF', teamId: 'ny' },
  { id: '3593', name: 'Bojan Bogdanovic', position: 'SF', teamId: 'ny' },
  { id: '4066508', name: 'Charlie Brown Jr.', position: 'G', teamId: 'ny' },
  { id: '3934672', name: 'Jalen Brunson', position: 'PG', teamId: 'ny' },
  { id: '6429', name: 'Alec Burks', position: 'SG', teamId: 'ny' },
  { id: '3934673', name: 'Donte DiVincenzo', position: 'SG', teamId: 'ny' },
  { id: '3062679', name: 'Josh Hart', position: 'SG', teamId: 'ny' },
  { id: '4222252', name: 'Isaiah Hartenstein', position: 'C', teamId: 'ny' },
  { id: '3913220', name: 'DaQuan Jeffries', position: 'SG', teamId: 'ny' },
  { id: '4431823', name: 'Miles McBride', position: 'PG', teamId: 'ny' },
  { id: '3064514', name: 'Julius Randle', position: 'PF', teamId: 'ny' },
  { id: '4351852', name: 'Mitchell Robinson', position: 'C', teamId: 'ny' },
  { id: '4277922', name: 'Jericho Sims', position: 'C', teamId: 'ny' },
  { id: '4397235', name: 'Duane Washington Jr.', position: 'PG', teamId: 'ny' },
  { id: '4277919', name: 'Mo Bamba', position: 'C', teamId: 'phi' },
  { id: '3416', name: 'Nicolas Batum', position: 'PF', teamId: 'phi' },
  { id: '4423545', name: 'Darius Bazley', position: 'PF', teamId: 'phi' },
  { id: '4701234', name: 'Ricky Council IV', position: 'G', teamId: 'phi' },
  { id: '2490620', name: 'Robert Covington', position: 'PF', teamId: 'phi' },
  { id: '4066786', name: 'Jeff Dowtin Jr.', position: 'G', teamId: 'phi' },
  { id: '3059318', name: 'Joel Embiid', position: 'C', teamId: 'phi' },
  { id: '6440', name: 'Tobias Harris', position: 'PF', teamId: 'phi' },
  { id: '2990984', name: 'Buddy Hield', position: 'SG', teamId: 'phi' },
  { id: '3012', name: 'Kyle Lowry', position: 'PG', teamId: 'phi' },
  { id: '4431828', name: 'KJ Martin', position: 'F', teamId: 'phi' },
  { id: '4431678', name: 'Tyrese Maxey', position: 'PG', teamId: 'phi' },
  { id: '4066436', name: "De'Anthony Melton", position: 'SG', teamId: 'phi' },
  { id: '3133603', name: 'Kelly Oubre Jr.', position: 'SG', teamId: 'phi' },
  { id: '3064230', name: 'Cameron Payne', position: 'PG', teamId: 'phi' },
  { id: '4278562', name: 'Paul Reed', position: 'SF', teamId: 'phi' },
  { id: '4683940', name: 'Terquavion Smith', position: 'G', teamId: 'phi' },
  { id: '4397018', name: 'Ochai Agbaji', position: 'G', teamId: 'tor' },
  { id: '4433134', name: 'Scottie Barnes', position: 'SF', teamId: 'tor' },
  { id: '4395625', name: 'RJ Barrett', position: 'SG', teamId: 'tor' },
  { id: '3948153', name: 'Chris Boucher', position: 'PF', teamId: 'tor' },
  { id: '4065670', name: 'Bruce Brown', position: 'SF', teamId: 'tor' },
  { id: '4432820', name: 'D.J. Carton', position: 'G', teamId: 'tor' },
  { id: '5106258', name: 'Gradey Dick', position: 'G', teamId: 'tor' },
  { id: '4397511', name: 'Javon Freeman-Liberty', position: 'G', teamId: 'tor' },
  { id: '4431778', name: 'Christian Koloko', position: 'C', teamId: 'tor' },
  { id: '4066731', name: 'Jalen McDaniels', position: 'PF', teamId: 'tor' },
  { id: '4397554', name: 'Markquis Nowell', position: 'G', teamId: 'tor' },
  { id: '4277883', name: 'Jordan Nwora', position: 'SF', teamId: 'tor' },
  { id: '2489663', name: 'Kelly Olynyk', position: 'PF', teamId: 'tor' },
  { id: '3134908', name: 'Jakob Poeltl', position: 'C', teamId: 'tor' },
  { id: '4278103', name: 'Jontay Porter', position: 'C', teamId: 'tor' },
  { id: '4395724', name: 'Immanuel Quickley', position: 'SG', teamId: 'tor' },
  { id: '4023', name: 'Garrett Temple', position: 'SF', teamId: 'tor' },
  { id: '4277843', name: 'Gary Trent Jr.', position: 'SG', teamId: 'tor' },
  { id: '3975', name: 'Stephen Curry', position: 'PG', teamId: 'gs' },
  { id: '4871143', name: 'Usman Garuba', position: 'PF', teamId: 'gs' },
  { id: '6589', name: 'Draymond Green', position: 'PF', teamId: 'gs' },
  { id: '4431684', name: 'Trayce Jackson-Davis', position: 'F', teamId: 'gs' },
  { id: '4433247', name: 'Jonathan Kuminga', position: 'PF', teamId: 'gs' },
  { id: '3155535', name: 'Kevon Looney', position: 'F', teamId: 'gs' },
  { id: '4432171', name: 'Moses Moody', position: 'SG', teamId: 'gs' },
  { id: '2779', name: 'Chris Paul', position: 'PG', teamId: 'gs' },
  { id: '3134903', name: 'Gary Payton II', position: 'SG', teamId: 'gs' },
  { id: '4709138', name: 'Brandin Podziemski', position: 'G', teamId: 'gs' },
  { id: '4431720', name: 'Lester Quinones', position: 'G', teamId: 'gs' },
  { id: '3943606', name: 'Jerome Robinson', position: 'SG', teamId: 'gs' },
  { id: '4997536', name: 'Gui Santos', position: 'F', teamId: 'gs' },
  { id: '3032978', name: 'Dario Saric', position: 'PF', teamId: 'gs' },
  { id: '4592714', name: 'Pat Spencer', position: 'G', teamId: 'gs' },
  { id: '6475', name: 'Klay Thompson', position: 'SG', teamId: 'gs' },
  { id: '3059319', name: 'Andrew Wiggins', position: 'SF', teamId: 'gs' },
  { id: '4432162', name: 'Brandon Boston Jr.', position: 'SG', teamId: 'lac' },
  { id: '4431752', name: 'Kobe Brown', position: 'G', teamId: 'lac' },
  { id: '4066387', name: 'Amir Coffey', position: 'SG', teamId: 'lac' },
  { id: '4433249', name: 'Moussa Diabate', position: 'F', teamId: 'lac' },
  { id: '4251', name: 'Paul George', position: 'F', teamId: 'lac' },
  { id: '3992', name: 'James Harden', position: 'SG', teamId: 'lac' },
  { id: '4592492', name: 'Bones Hyland', position: 'PG', teamId: 'lac' },
  { id: '6450', name: 'Kawhi Leonard', position: 'SF', teamId: 'lac' },
  { id: '3907823', name: 'Terance Mann', position: 'SG', teamId: 'lac' },
  { id: '4396818', name: 'Jordan Miller', position: 'G', teamId: 'lac' },
  { id: '3914075', name: 'Xavier Moon', position: 'G', teamId: 'lac' },
  { id: '2488653', name: 'Mason Plumlee', position: 'C', teamId: 'lac' },
  { id: '2595516', name: 'Norman Powell', position: 'G', teamId: 'lac' },
  { id: '4702176', name: 'Joshua Primo', position: 'G', teamId: 'lac' },
  { id: '2451037', name: 'Daniel Theis', position: 'C', teamId: 'lac' },
  { id: '3033', name: 'P.J. Tucker', position: 'PF', teamId: 'lac' },
  { id: '3468', name: 'Russell Westbrook', position: 'PG', teamId: 'lac' },
  { id: '4017837', name: 'Ivica Zubac', position: 'C', teamId: 'lac' },
  { id: '4397204', name: 'Colin Castleton', position: 'C', teamId: 'lal' },
  { id: '4432582', name: 'Max Christie', position: 'G', teamId: 'lal' },
  { id: '6583', name: 'Anthony Davis', position: 'PF', teamId: 'lal' },
  { id: '2580782', name: 'Spencer Dinwiddie', position: 'PG', teamId: 'lal' },
  { id: '4065649', name: 'Harry Giles III', position: 'F', teamId: 'lal' },
  { id: '4066648', name: 'Rui Hachimura', position: 'PF', teamId: 'lal' },
  { id: '4397077', name: 'Jaxson Hayes', position: 'C', teamId: 'lal' },
  { id: '4702702', name: "D'Moi Hodge", position: 'G', teamId: 'lal' },
  { id: '5105797', name: 'Jalen Hood-Schifino', position: 'G', teamId: 'lal' },
  { id: '1966', name: 'LeBron James', position: 'SF', teamId: 'lal' },
  { id: '4684286', name: 'Maxwell Lewis', position: 'F', teamId: 'lal' },
  { id: '4066269', name: 'Skylar Mays', position: 'SG', teamId: 'lal' },
  { id: '2990962', name: 'Taurean Prince', position: 'PF', teamId: 'lal' },
  { id: '4066457', name: 'Austin Reaves', position: 'SG', teamId: 'lal' },
  { id: '4395627', name: 'Cam Reddish', position: 'SF', teamId: 'lal' },
  { id: '3136776', name: "D'Angelo Russell", position: 'PG', teamId: 'lal' },
  { id: '4278077', name: 'Jarred Vanderbilt', position: 'PF', teamId: 'lal' },
  { id: '3137259', name: 'Gabe Vincent', position: 'PG', teamId: 'lal' },
  { id: '3058254', name: 'Christian Wood', position: 'F', teamId: 'lal' },
  { id: '3135045', name: "Grayson Allen", position: 'SG', teamId: 'phx' },
  { id: '4066299', name: "Udoka Azubuike", position: 'C', teamId: 'phx' },
  { id: '6580', name: "Bradley Beal", position: 'SG', teamId: 'phx' },
  { id: '4397102', name: "Bol Bol", position: 'C', teamId: 'phx' },
  { id: '3136193', name: "Devin Booker", position: 'SG', teamId: 'phx' },
  { id: '3202', name: "Kevin Durant", position: 'PF', teamId: 'phx' },
  { id: '3914285', name: "Drew Eubanks", position: 'PF', teamId: 'phx' },
  { id: '3431', name: "Eric Gordon", position: 'SG', teamId: 'phx' },
  { id: '2595209', name: "Damion Lee", position: 'SG', teamId: 'phx' },
  { id: '4278124', name: "Saben Lee", position: 'G', teamId: 'phx' },
  { id: '4395652', name: "Nassir Little", position: 'SF', teamId: 'phx' },
  { id: '4683027', name: "Theo Maledon", position: 'PG', teamId: 'phx' },
  { id: '3102530', name: "Jusuf Nurkic", position: 'C', teamId: 'phx' },
  { id: '2583632', name: "Royce O'Neale", position: 'PF', teamId: 'phx' },
  { id: '4065663', name: "Josh Okogie", position: 'SF', teamId: 'phx' },
  { id: '4593041', name: "David Roddy", position: 'F', teamId: 'phx' },
  { id: '3059307', name: "Ish Wainright", position: 'F', teamId: 'phx' },
  { id: '3244', name: "Thaddeus Young", position: 'F', teamId: 'phx' },
  { id: '6578', name: "Harrison Barnes", position: 'SF', teamId: 'sac' },
  { id: '4592402', name: "Chris Duarte", position: 'SG', teamId: 'sac' },
  { id: '4397693', name: "Kessler Edwards", position: 'SF', teamId: 'sac' },
  { id: '4702177', name: "Keon Ellis", position: 'G', teamId: 'sac' },
  { id: '4066736', name: "Jordan Ford", position: 'G', teamId: 'sac' },
  { id: '4066259', name: "De'Aaron Fox", position: 'PG', teamId: 'sac' },
  { id: '4066372', name: "Kevin Huerter", position: 'SG', teamId: 'sac' },
  { id: '4433378', name: "Colby Jones", position: 'G', teamId: 'sac' },
  { id: '4395704', name: "Mason Jones", position: 'G', teamId: 'sac' },
  { id: '2596107', name: "Alex Len", position: 'C', teamId: 'sac' },
  { id: '3136196', name: "Trey Lyles", position: 'PF', teamId: 'sac' },
  { id: '3452', name: "JaVale McGee", position: 'C', teamId: 'sac' },
  { id: '4278053', name: "Davion Mitchell", position: 'PG', teamId: 'sac' },
  { id: '4066262', name: "Malik Monk", position: 'SG', teamId: 'sac' },
  { id: '4594327', name: "Keegan Murray", position: 'SF', teamId: 'sac' },
  { id: '3155942', name: "Domantas Sabonis", position: 'PF', teamId: 'sac' },
  { id: '4398207', name: "Jalen Slawson", position: 'F', teamId: 'sac' },
  { id: '3893014', name: "Sasha Vezenkov", position: 'F', teamId: 'sac' },
  { id: '4066421', name: "Lonzo Ball", position: 'PG', teamId: 'chi' },
  { id: '4438552', name: "Onuralp Bitim", position: 'SF', teamId: 'chi' },
  { id: '3133635', name: "Jevon Carter", position: 'PG', teamId: 'chi' },
  { id: '2991350', name: "Alex Caruso", position: 'SG', teamId: 'chi' },
  { id: '2528693', name: "Torrey Craig", position: 'SF', teamId: 'chi' },
  { id: '3978', name: "DeMar DeRozan", position: 'SF', teamId: 'chi' },
  { id: '4397002', name: "Ayo Dosunmu", position: 'SG', teamId: 'chi' },
  { id: '4423884', name: "Henri Drell", position: 'F', teamId: 'chi' },
  { id: '6585', name: "Andre Drummond", position: 'C', teamId: 'chi' },
  { id: '4397650', name: "Andrew Funk", position: 'G', teamId: 'chi' },
  { id: '3064440', name: "Zach LaVine", position: 'SG', teamId: 'chi' },
  { id: '5105553', name: "Julian Phillips", position: 'F', teamId: 'chi' },
  { id: '4702023', name: "Adama Sanogo", position: 'C', teamId: 'chi' },
  { id: '4279815', name: "Terry Taylor", position: 'F', teamId: 'chi' },
  { id: '4433159', name: "Dalen Terry", position: 'F', teamId: 'chi' },
  { id: '6478', name: "Nikola Vucevic", position: 'C', teamId: 'chi' },
  { id: '4395651', name: "Coby White", position: 'PG', teamId: 'chi' },
  { id: '4431687', name: "Patrick Williams", position: 'PF', teamId: 'chi' },
  { id: '4066328', name: "Jarrett Allen", position: 'C', teamId: 'cle' },
  { id: '4433620', name: "Emoni Bates", position: 'F', teamId: 'cle' },
  { id: '4432173', name: "Sharife Cooper", position: 'G', teamId: 'cle' },
  { id: '4396907', name: "Darius Garland", position: 'PG', teamId: 'cle' },
  { id: '4065733', name: "Ty Jerome", position: 'SG', teamId: 'cle' },
  { id: '3064559', name: "Damian Jones", position: 'C', teamId: 'cle' },
  { id: '2991043', name: "Caris LeVert", position: 'SG', teamId: 'cle' },
  { id: '4066757', name: "Sam Merrill", position: 'SG', teamId: 'cle' },
  { id: '3908809', name: "Donovan Mitchell", position: 'SG', teamId: 'cle' },
  { id: '4432158', name: "Evan Mobley", position: 'PF', teamId: 'cle' },
  { id: '4432815', name: "Isaiah Mobley", position: 'F', teamId: 'cle' },
  { id: '4397233', name: "Pete Nance", position: 'F', teamId: 'cle' },
  { id: '2990969', name: "Georges Niang", position: 'SF', teamId: 'cle' },
  { id: '4432822', name: "Isaac Okoro", position: 'SF', teamId: 'cle' },
  { id: '4701233', name: "Craig Porter", position: 'G', teamId: 'cle' },
  { id: '4065778', name: "Max Strus", position: 'SG', teamId: 'cle' },
  { id: '6474', name: "Tristan Thompson", position: 'C', teamId: 'cle' },
  { id: '3912848', name: "Dean Wade", position: 'PF', teamId: 'cle' },
  { id: '4395676', name: "Buddy Boeheim", position: 'F', teamId: 'det' },
  { id: '4278508', name: "Troy Brown Jr.", position: 'SF', teamId: 'det' },
  { id: '4432166', name: "Cade Cunningham", position: 'PG', teamId: 'det' },
  { id: '4433621', name: "Jalen Duren", position: 'C', teamId: 'det' },
  { id: '4597865', name: "Tosan Evbuomwan", position: 'F', teamId: 'det' },
  { id: '4066668', name: "Malachi Flynn", position: 'PG', teamId: 'det' },
  { id: '3899664', name: "Simone Fontecchio", position: 'F', teamId: 'det' },
  { id: '6588', name: "Evan Fournier", position: 'SG', teamId: 'det' },
  { id: '4397014', name: "Quentin Grimes", position: 'SG', teamId: 'det' },
  { id: '4433218', name: "Jaden Ivey", position: 'PG', teamId: 'det' },
  { id: '4397119', name: "Jared Rhoden", position: 'G', teamId: 'det' },
  { id: '4432107', name: "Marcus Sasser", position: 'G', teamId: 'det' },
  { id: '4432810', name: "Isaiah Stewart", position: 'C', teamId: 'det' },
  { id: '4684742', name: "Ausar Thompson", position: 'F', teamId: 'det' },
  { id: '4279532', name: "Stanley Umude", position: 'G', teamId: 'det' },
  { id: '4432808', name: "James Wiseman", position: 'C', teamId: 'det' },
  { id: '4433254', name: "Kendall Brown", position: 'SG', teamId: 'ind' },
  { id: '4396993', name: "Tyrese Haliburton", position: 'PG', teamId: 'ind' },
  { id: '4432170', name: "Isaiah Jackson", position: 'SF', teamId: 'ind' },
  { id: '3999', name: "James Johnson", position: 'PF', teamId: 'ind' },
  { id: '4683634', name: "Bennedict Mathurin", position: 'SG', teamId: 'ind' },
  { id: '2530530', name: "T.J. McConnell", position: 'PG', teamId: 'ind' },
  { id: '2528588', name: "Doug McDermott", position: 'SF', teamId: 'ind' },
  { id: '4395712', name: "Andrew Nembhard", position: 'PG', teamId: 'ind' },
  { id: '4396909', name: "Aaron Nesmith", position: 'SF', teamId: 'ind' },
  { id: '4433076', name: "Ben Sheppard", position: 'G', teamId: 'ind' },
  { id: '3149673', name: "Pascal Siakam", position: 'PF', teamId: 'ind' },
  { id: '4397189', name: "Jalen Smith", position: 'PF', teamId: 'ind' },
  { id: '4278355', name: "Obi Toppin", position: 'PF', teamId: 'ind' },
  { id: '4432827', name: "Oscar Tshiebwe", position: 'C', teamId: 'ind' },
  { id: '3133628', name: "Myles Turner", position: 'C', teamId: 'ind' },
  { id: '5106060', name: "Jarace Walker", position: 'F', teamId: 'ind' },
  { id: '4431727', name: "Isaiah Wong", position: 'G', teamId: 'ind' },
  { id: '3032977', name: "Giannis Antetokounmpo", position: 'PF', teamId: 'mil' },
  { id: '3102533', name: "Thanasis Antetokounmpo", position: 'SF', teamId: 'mil' },
  { id: '3907822', name: "Malik Beasley", position: 'SG', teamId: 'mil' },
  { id: '4432179', name: "MarJon Beauchamp", position: 'F', teamId: 'mil' },
  { id: '3964', name: "Patrick Beverley", position: 'PG', teamId: 'mil' },
  { id: '2578239', name: "Pat Connaughton", position: 'SG', teamId: 'mil' },
  { id: '6581', name: "Jae Crowder", position: 'PF', teamId: 'mil' },
  { id: '3428', name: "Danilo Gallinari", position: 'PF', teamId: 'mil' },
  { id: '5090967', name: "Jaylin Galloway", position: 'F', teamId: 'mil' },
  { id: '4397475', name: "AJ Green", position: 'G', teamId: 'mil' },
  { id: '4432190', name: "Andre Jackson Jr.", position: 'G', teamId: 'mil' },
  { id: '6606', name: "Damian Lillard", position: 'PG', teamId: 'mil' },
  { id: '4433622', name: "Chris Livingston", position: 'F', teamId: 'mil' },
  { id: '3448', name: "Brook Lopez", position: 'C', teamId: 'mil' },
  { id: '6609', name: "Khris Middleton", position: 'SF', teamId: 'mil' },
  { id: '3064482', name: "Bobby Portis", position: 'F', teamId: 'mil' },
  { id: '4591725', name: "Ryan Rollins", position: 'G', teamId: 'mil' },
  { id: '4683749', name: "TyTy Washington Jr.", position: 'G', teamId: 'mil' },
  { id: '4397136', name: "Saddiq Bey", position: 'SF', teamId: 'atl' },
  { id: '3037789', name: "Bogdan Bogdanovic", position: 'SG', teamId: 'atl' },
  { id: '4683736', name: "Kobe Bufkin", position: 'G', teamId: 'atl' },
  { id: '3102529', name: "Clint Capela", position: 'C', teamId: 'atl' },
  { id: '4277952', name: "Bruno Fernando", position: 'PF', teamId: 'atl' },
  { id: '4065656', name: "Trent Forrest", position: 'G', teamId: 'atl' },
  { id: '4432585', name: "AJ Griffin", position: 'F', teamId: 'atl' },
  { id: '4712863', name: "Mouhamed Gueye", position: 'F', teamId: 'atl' },
  { id: '4065732', name: "De'Andre Hunter", position: 'SF', teamId: 'atl' },
  { id: '4701230', name: "Jalen Johnson", position: 'SF', teamId: 'atl' },
  { id: '4578893', name: "Vit Krejci", position: 'G', teamId: 'atl' },
  { id: '4431775', name: "Seth Lundy", position: 'G', teamId: 'atl' },
  { id: '3913180', name: "Garrison Mathews", position: 'SG', teamId: 'atl' },
  { id: '4032', name: "Wesley Matthews", position: 'SG', teamId: 'atl' },
  { id: '3907497', name: "Dejounte Murray", position: 'SG', teamId: 'atl' },
  { id: '4431680', name: "Onyeka Okongwu", position: 'PF', teamId: 'atl' },
  { id: '3906786', name: "Dylan Windler", position: 'G', teamId: 'atl' },
  { id: '4277905', name: "Trae Young", position: 'PG', teamId: 'atl' },
  { id: '5105638', name: "Amari Bailey", position: 'G', teamId: 'cha' },
  { id: '4432816', name: "LaMelo Ball", position: 'PG', teamId: 'cha' },
  { id: '6426', name: "Davis Bertans", position: 'SF', teamId: 'cha' },
  { id: '4395650', name: "Leaky Black", position: 'F', teamId: 'cha' },
  { id: '4065652', name: "Marques Bolden", position: 'C', teamId: 'cha' },
  { id: '4066383', name: "Miles Bridges", position: 'SF', teamId: 'cha' },
  { id: '2326307', name: "Seth Curry", position: 'SG', teamId: 'cha' },
  { id: '4432819', name: "Tre Mann", position: 'PG', teamId: 'cha' },
  { id: '3138161', name: "Cody Martin", position: 'SF', teamId: 'cha' },
  { id: '4576086', name: "Bryce McGowens", position: 'G', teamId: 'cha' },
  { id: '3102532', name: "Vasilije Micic", position: 'G', teamId: 'cha' },
  { id: '4433287', name: "Brandon Miller", position: 'F', teamId: 'cha' },
  { id: '4683018', name: "Aleksej Pokusevski", position: 'F', teamId: 'cha' },
  { id: '4278076', name: "Nick Richards", position: 'C', teamId: 'cha' },
  { id: '4683686', name: "Nick Smith Jr.", position: 'G', teamId: 'cha' },
  { id: '4702233', name: "JT Thor", position: 'F', teamId: 'cha' },
  { id: '4066218', name: "Grant Williams", position: 'PF', teamId: 'cha' },
  { id: '4701232', name: "Mark Williams", position: 'C', teamId: 'cha' },
  { id: '4066261', name: "Bam Adebayo", position: 'C', teamId: 'mia' },
  { id: '3934723', name: "Thomas Bryant", position: 'C', teamId: 'mia' },
  { id: '6430', name: "Jimmy Butler", position: 'SF', teamId: 'mia' },
  { id: '4278572', name: "Jamal Cain", position: 'F', teamId: 'mia' },
  { id: '4395725', name: "Tyler Herro", position: 'PG', teamId: 'mia' },
  { id: '4291678', name: "Haywood Highsmith", position: 'F', teamId: 'mia' },
  { id: '4432848', name: "Jaime Jaquez Jr.", position: 'G', teamId: 'mia' },
  { id: '4997528', name: "Nikola Jovic", position: 'F', teamId: 'mia' },
  { id: '3449', name: "Kevin Love", position: 'PF', teamId: 'mia' },
  { id: '3138160', name: "Caleb Martin", position: 'SF', teamId: 'mia' },
  { id: '2581190', name: "Josh Richardson", position: 'SG', teamId: 'mia' },
  { id: '3157465', name: "Duncan Robinson", position: 'F', teamId: 'mia' },
  { id: '4593049', name: "Orlando Robinson", position: 'C', teamId: 'mia' },
  { id: '3074752', name: "Terry Rozier", position: 'SG', teamId: 'mia' },
  { id: '4066993', name: "Dru Smith", position: 'G', teamId: 'mia' },
  { id: '4397134', name: "Cole Swider", position: 'F', teamId: 'mia' },
  { id: '4592216', name: "Alondes Williams", position: 'G', teamId: 'mia' },
  { id: '3064447', name: "Delon Wright", position: 'SG', teamId: 'mia' },
  { id: '4432809', name: "Cole Anthony", position: 'PG', teamId: 'orl' },
  { id: '4432573', name: "Paolo Banchero", position: 'PF', teamId: 'orl' },
  { id: '4348700', name: "Goga Bitadze", position: 'C', teamId: 'orl' },
  { id: '4712849', name: "Anthony Black", position: 'G', teamId: 'orl' },
  { id: '4277847', name: "Wendell Carter Jr.", position: 'C', teamId: 'orl' },
  { id: '4066636', name: "Markelle Fultz", position: 'PG', teamId: 'orl' },
  { id: '2999547', name: "Gary Harris", position: 'SG', teamId: 'orl' },
  { id: '4067858', name: "Kevon Harris", position: 'G', teamId: 'orl' },
  { id: '4433623', name: "Caleb Houstan", position: 'G', teamId: 'orl' },
  { id: '5105806', name: "Jett Howard", position: 'G', teamId: 'orl' },
  { id: '2968436', name: "Joe Ingles", position: 'SG', teamId: 'orl' },
  { id: '4065654', name: "Jonathan Isaac", position: 'PF', teamId: 'orl' },
  { id: '4278052', name: "Chuma Okeke", position: 'PF', teamId: 'orl' },
  { id: '4409222', name: "Trevelin Queen", position: 'SG', teamId: 'orl' },
  { id: '3918423', name: "Admiral Schofield", position: 'SF', teamId: 'orl' },
  { id: '4432165', name: "Jalen Suggs", position: 'SG', teamId: 'orl' },
  { id: '4566434', name: "Franz Wagner", position: 'SF', teamId: 'orl' },
  { id: '3150844', name: "Moritz Wagner", position: 'C', teamId: 'orl' },
  { id: '4683021', name: "Deni Avdija", position: 'SF', teamId: 'wsh' },
  { id: '4277848', name: "Marvin Bagley III", position: 'PF', teamId: 'wsh' },
  { id: '4433246', name: "Patrick Baldwin Jr.", position: 'F', teamId: 'wsh' },
  { id: '4397127', name: "Jules Bernard", position: 'G', teamId: 'wsh' },
  { id: '4395695', name: "Jared Butler", position: 'SG', teamId: 'wsh' },
  { id: '4432907', name: "Justin Champagnie", position: 'SF', teamId: 'wsh' },
  { id: '5104155', name: "Bilal Coulibaly", position: 'SG', teamId: 'wsh' },
  { id: '4432356', name: "Johnny Davis", position: 'G', teamId: 'wsh' },
  { id: '2581184', name: "Anthony Gill", position: 'PF', teamId: 'wsh' },
  { id: '4590530', name: "R.J. Hampton", position: 'G', teamId: 'wsh' },
  { id: '2993370', name: "Richaun Holmes", position: 'F', teamId: 'wsh' },
  { id: '3135046', name: "Tyus Jones", position: 'PG', teamId: 'wsh' },
  { id: '4280151', name: "Corey Kispert", position: 'SF', teamId: 'wsh' },
  { id: '3134907', name: "Kyle Kuzma", position: 'SF', teamId: 'wsh' },
  { id: '4277957', name: "Isaiah Livers", position: 'PF', teamId: 'wsh' },
  { id: '4066410', name: "Eugene Omoruyi", position: 'F', teamId: 'wsh' },
  { id: '4277956', name: "Jordan Poole", position: 'SG', teamId: 'wsh' },
  { id: '3914044', name: "Landry Shamet", position: 'SG', teamId: 'wsh' },
  { id: '4431767', name: "Christian Braun", position: 'G', teamId: 'den' },
  { id: '2581018', name: "Kentavious Caldwell-Pope", position: 'SG', teamId: 'den' },
  { id: '4230557', name: "Vlatko Cancar", position: 'SF', teamId: 'den' },
  { id: '4278585', name: "Collin Gillespie", position: 'G', teamId: 'den' },
  { id: '3064290', name: "Aaron Gordon", position: 'PF', teamId: 'den' },
  { id: '2284101', name: "Justin Holiday", position: 'SF', teamId: 'den' },
  { id: '4065731', name: "Jay Huff", position: 'C', teamId: 'den' },
  { id: '6443', name: "Reggie Jackson", position: 'PG', teamId: 'den' },
  { id: '3112335', name: "Nikola Jokic", position: 'C', teamId: 'den' },
  { id: '3442', name: "DeAndre Jordan", position: 'C', teamId: 'den' },
  { id: '4066240', name: "Braxton Key", position: 'F', teamId: 'den' },
  { id: '3936299', name: "Jamal Murray", position: 'PG', teamId: 'den' },
  { id: '4431690', name: "Zeke Nnaji", position: 'PF', teamId: 'den' },
  { id: '4398390', name: "Jalen Pickett", position: 'G', teamId: 'den' },
  { id: '4278104', name: "Michael Porter Jr.", position: 'SF', teamId: 'den' },
  { id: '4432181', name: "Julian Strawther", position: 'G', teamId: 'den' },
  { id: '4395620', name: "Hunter Tyson", position: 'F', teamId: 'den' },
  { id: '4576087', name: "Peyton Watson", position: 'F', teamId: 'den' },
  { id: '4278039', name: "Nickeil Alexander-Walker", position: 'SG', teamId: 'min' },
  { id: '2993874', name: "Kyle Anderson", position: 'SF', teamId: 'min' },
  { id: '4432247', name: "Jaylen Clark", position: 'G', teamId: 'min' },
  { id: '3195', name: "Mike Conley", position: 'PG', teamId: 'min' },
  { id: '4594268', name: "Anthony Edwards", position: 'SG', teamId: 'min' },
  { id: '4277951', name: "Luka Garza", position: 'C', teamId: 'min' },
  { id: '3032976', name: "Rudy Gobert", position: 'C', teamId: 'min' },
  { id: '3138156', name: "Justin Jackson", position: 'F', teamId: 'min' },
  { id: '4431671', name: "Jaden McDaniels", position: 'PF', teamId: 'min' },
  { id: '3134916', name: "Jordan McLaughlin", position: 'PG', teamId: 'min' },
  { id: '5044385', name: "Leonard Miller", position: 'F', teamId: 'min' },
  { id: '4687718', name: "Josh Minott", position: 'F', teamId: 'min' },
  { id: '4592187', name: "Wendell Moore Jr.", position: 'G', teamId: 'min' },
  { id: '3059310', name: "Monte Morris", position: 'PG', teamId: 'min' },
  { id: '4432176', name: "Daishen Nix", position: 'SG', teamId: 'min' },
  { id: '4396971', name: "Naz Reid", position: 'C', teamId: 'min' },
  { id: '3136195', name: "Karl-Anthony Towns", position: 'C', teamId: 'min' },
  { id: '6427', name: "Bismack Biyombo", position: 'C', teamId: 'okc' },
  { id: '4997526', name: "Ousmane Dieng", position: 'F', teamId: 'okc' },
  { id: '4397020', name: "Luguentz Dort", position: 'G', teamId: 'okc' },
  { id: '4397654', name: "Adam Flagler", position: 'F', teamId: 'okc' },
  { id: '4871145', name: "Josh Giddey", position: 'SG', teamId: 'okc' },
  { id: '4278073', name: "Shai Gilgeous-Alexander", position: 'PG', teamId: 'okc' },
  { id: '4249', name: "Gordon Hayward", position: 'SF', teamId: 'okc' },
  { id: '4433255', name: "Chet Holmgren", position: 'PF', teamId: 'okc' },
  { id: '4395702', name: "Isaiah Joe", position: 'SG', teamId: 'okc' },
  { id: '4395714', name: "Keyontae Johnson", position: 'F', teamId: 'okc' },
  { id: '2490089', name: "Mike Muscala", position: 'C', teamId: 'okc' },
  { id: '4278046', name: "Olivier Sarr", position: 'C', teamId: 'okc' },
  { id: '4683692', name: "Cason Wallace", position: 'G', teamId: 'okc' },
  { id: '4066317', name: "Lindy Waters III", position: 'F', teamId: 'okc' },
  { id: '4397183', name: "Aaron Wiggins", position: 'SG', teamId: 'okc' },
  { id: '4593803', name: "Jalen Williams", position: 'F', teamId: 'okc' },
  { id: '4432823', name: "Jaylin Williams", position: 'F', teamId: 'okc' },
  { id: '3133626', name: "Kenrich Williams", position: 'SF', teamId: 'okc' },
  { id: '4278129', name: "Deandre Ayton", position: 'C', teamId: 'por' },
  { id: '4871140', name: "Ibou Badji", position: 'C', teamId: 'por' },
  { id: '4397885', name: "Dalano Banton", position: 'G', teamId: 'por' },
  { id: '2566769', name: "Malcolm Brogdon", position: 'PG', teamId: 'por' },
  { id: '4397126', name: "Moses Brown", position: 'C', teamId: 'por' },
  { id: '4431736', name: "Toumani Camara", position: 'F', teamId: 'por' },
  { id: '2991070', name: "Jerami Grant", position: 'SF', teamId: 'por' },
  { id: '4395722', name: "Ashton Hagans", position: 'G', teamId: 'por' },
  { id: '4683678', name: "Scoot Henderson", position: 'G', teamId: 'por' },
  { id: '4278111', name: "Justin Minaya", position: 'F', teamId: 'por' },
  { id: '4594326', name: "Kris Murray", position: 'F', teamId: 'por' },
  { id: '4066268', name: "Duop Reath", position: 'C', teamId: 'por' },
  { id: '5099752', name: "Rayan Rupert", position: 'G', teamId: 'por' },
  { id: '4914336', name: "Shaedon Sharpe", position: 'SG', teamId: 'por' },
  { id: '4351851', name: "Anfernee Simons", position: 'SG', teamId: 'por' },
  { id: '3907498', name: "Matisse Thybulle", position: 'SG', teamId: 'por' },
  { id: '4432446', name: "Jabari Walker", position: 'F', teamId: 'por' },
  { id: '4066211', name: "Robert Williams III", position: 'C', teamId: 'por' },
  { id: '2528426', name: "Jordan Clarkson", position: 'PG', teamId: 'utah' },
  { id: '3908845', name: "John Collins", position: 'PF', teamId: 'utah' },
  { id: '2991139', name: "Kris Dunn", position: 'G', teamId: 'utah' },
  { id: '4433627', name: "Keyonte George", position: 'G', teamId: 'utah' },
  { id: '4684806', name: "Taylor Hendricks", position: 'F', teamId: 'utah' },
  { id: '4396991', name: "Talen Horton-Tucker", position: 'SG', teamId: 'utah' },
  { id: '4432817', name: "Johnny Juzang", position: 'G', teamId: 'utah' },
  { id: '4433136', name: "Walker Kessler", position: 'C', teamId: 'utah' },
  { id: '4395698', name: "Kira Lewis Jr.", position: 'PG', teamId: 'utah' },
  { id: '4066336', name: "Lauri Markkanen", position: 'PF', teamId: 'utah' },
  { id: '2594922', name: "Otto Porter Jr.", position: 'SF', teamId: 'utah' },
  { id: '4066399', name: "Micah Potter", position: 'C', teamId: 'utah' },
  { id: '4397916', name: "Jason Preston", position: 'G', teamId: 'utah' },
  { id: '4423309', name: "Luka Samanic", position: 'F', teamId: 'utah' },
  { id: '5105839', name: "Brice Sensabaugh", position: 'F', teamId: 'utah' },
  { id: '4277811', name: "Collin Sexton", position: 'PG', teamId: 'utah' },
  { id: '3074213', name: "Omer Yurtseven", position: 'C', teamId: 'utah' },
  { id: '4432161', name: "Greg Brown III", position: 'PF', teamId: 'dal' },
  { id: '3945274', name: "Luka Doncic", position: 'PG', teamId: 'dal' },
  { id: '3102528', name: "Dante Exum", position: 'G', teamId: 'dal' },
  { id: '4896481', name: "Alex Fudge", position: 'F', teamId: 'dal' },
  { id: '4278049', name: "Daniel Gafford", position: 'C', teamId: 'dal' },
  { id: '4432811', name: "Josh Green", position: 'SG', teamId: 'dal' },
  { id: '2528210', name: "Tim Hardaway Jr.", position: 'SF', teamId: 'dal' },
  { id: '4868423', name: "Jaden Hardy", position: 'G', teamId: 'dal' },
  { id: '6442', name: "Kyrie Irving", position: 'PG', teamId: 'dal' },
  { id: '3936099', name: "Derrick Jones Jr.", position: 'SF', teamId: 'dal' },
  { id: '2960236', name: "Maxi Kleber", position: 'PF', teamId: 'dal' },
  { id: '4067017', name: "A.J. Lawson", position: 'G', teamId: 'dal' },
  { id: '4683688', name: "Dereck Lively II", position: 'C', teamId: 'dal' },
  { id: '6461', name: "Markieff Morris", position: 'PF', teamId: 'dal' },
  { id: '2531367', name: "Dwight Powell", position: 'C', teamId: 'dal' },
  { id: '4595400', name: "Olivier-Maxence Prosper", position: 'F', teamId: 'dal' },
  { id: '4278078', name: "P.J. Washington", position: 'PF', teamId: 'dal' },
  { id: '4397040', name: "Brandon Williams", position: 'G', teamId: 'dal' },
  { id: '2991235', name: "Steven Adams", position: 'C', teamId: 'hou' },
  { id: '3155526', name: "Dillon Brooks", position: 'SF', teamId: 'hou' },
  { id: '2528779', name: "Reggie Bullock Jr.", position: 'SF', teamId: 'hou' },
  { id: '4433192', name: "Tari Eason", position: 'F', teamId: 'hou' },
  { id: '4437244', name: "Jalen Green", position: 'SG', teamId: 'hou' },
  { id: '3209', name: "Jeff Green", position: 'PF', teamId: 'hou' },
  { id: '4396629', name: "Nate Hinton", position: 'G', teamId: 'hou' },
  { id: '3922230', name: "Aaron Holiday", position: 'G', teamId: 'hou' },
  { id: '3146557', name: "Jock Landale", position: 'C', teamId: 'hou' },
  { id: '4376', name: "Boban Marjanovic", position: 'C', teamId: 'hou' },
  { id: '4278588', name: "Jermaine Samuels Jr.", position: 'F', teamId: 'hou' },
  { id: '4871144', name: "Alperen Sengun", position: 'C', teamId: 'hou' },
  { id: '4432639', name: "Jabari Smith Jr.", position: 'PF', teamId: 'hou' },
  { id: '3136777', name: "Jae'Sean Tate", position: 'SF', teamId: 'hou' },
  { id: '4684740', name: "Amen Thompson", position: 'F', teamId: 'hou' },
  { id: '2991230', name: "Fred VanVleet", position: 'PG', teamId: 'hou' },
  { id: '5105592', name: "Cam Whitmore", position: 'F', teamId: 'hou' },
  { id: '4397821', name: "Nate Williams", position: 'G', teamId: 'hou' },
  { id: '4593125', name: "Santi Aldama", position: 'PF', teamId: 'mem' },
  { id: '4066320', name: "Desmond Bane", position: 'SG', teamId: 'mem' },
  { id: '3906665', name: "Brandon Clarke", position: 'PF', teamId: 'mem' },
  { id: '4278402', name: "Jordan Goodwin", position: 'G', teamId: 'mem' },
  { id: '4431673', name: "Matt Hurt", position: 'F', teamId: 'mem' },
  { id: '5105550', name: "GG Jackson", position: 'F', teamId: 'mem' },
  { id: '4277961', name: "Jaren Jackson Jr.", position: 'PF', teamId: 'mem' },
  { id: '4395623', name: "Trey Jemison", position: 'C', teamId: 'mem' },
  { id: '3913174', name: "Luke Kennard", position: 'SG', teamId: 'mem' },
  { id: '3134932', name: "John Konchar", position: 'SG', teamId: 'mem' },
  { id: '4592691', name: "Jake LaRavia", position: 'F', teamId: 'mem' },
  { id: '4279888', name: "Ja Morant", position: 'PG', teamId: 'mem' },
  { id: '4431785', name: "Scotty Pippen Jr.", position: 'G', teamId: 'mem' },
  { id: '3456', name: "Derrick Rose", position: 'PG', teamId: 'mem' },
  { id: '2990992', name: "Marcus Smart", position: 'PG', teamId: 'mem' },
  { id: '4066405', name: "Lamar Stevens", position: 'PF', teamId: 'mem' },
  { id: '3133838', name: "Yuta Watanabe", position: 'SF', teamId: 'mem' },
  { id: '4433137', name: "Ziaire Williams", position: 'F', teamId: 'mem' },
  { id: '4397227', name: "Vince Williams Jr.", position: 'G', teamId: 'mem' },
  { id: '4277869', name: "Jose Alvarado", position: 'PG', teamId: 'no' },
  { id: '4278412', name: "Izaiah Brockington", position: 'G', teamId: 'no' },
  { id: '4278352', name: "Jalen Crutcher", position: 'G', teamId: 'no' },
  { id: '4869342', name: "Dyson Daniels", position: 'G', teamId: 'no' },
  { id: '4683750', name: "Jordan Hawkins", position: 'G', teamId: 'no' },
  { id: '3056245', name: "Malcolm Hill", position: 'G', teamId: 'no' },
  { id: '3913176', name: "Brandon Ingram", position: 'SF', teamId: 'no' },
  { id: '4277813', name: "Herbert Jones", position: 'SF', teamId: 'no' },
  { id: '4432821', name: "E.J. Liddell", position: 'F', teamId: 'no' },
  { id: '4278594', name: "Naji Marshall", position: 'SF', teamId: 'no' },
  { id: '2490149', name: "CJ McCollum", position: 'SG', teamId: 'no' },
  { id: '4397688', name: "Trey Murphy III", position: 'SG', teamId: 'no' },
  { id: '2580365', name: "Larry Nance Jr.", position: 'PF', teamId: 'no' },
  { id: '4432813', name: "Jeremiah Robinson-Earl", position: 'F', teamId: 'no' },
  { id: '3908336', name: "Matt Ryan", position: 'F', teamId: 'no' },
  { id: '4431811', name: "Dereon Seabron", position: 'G', teamId: 'no' },
  { id: '6477', name: "Jonas Valanciunas", position: 'C', teamId: 'no' },
  { id: '4395628', name: "Zion Williamson", position: 'PF', teamId: 'no' },
  { id: '2579258', name: "Cody Zeller", position: 'C', teamId: 'no' },
  { id: '4870562', name: "Dominick Barlow", position: 'F', teamId: 'sa' },
  { id: '4397886', name: "Charles Bassey", position: 'C', teamId: 'sa' },
  { id: '4280245', name: "Jamaree Bouyea", position: 'G', teamId: 'sa' },
  { id: '4565201', name: "Malaki Branham", position: 'G', teamId: 'sa' },
  { id: '4592479', name: "Julian Champagnie", position: 'F', teamId: 'sa' },
  { id: '5081727', name: "Sidy Cissoko", position: 'G', teamId: 'sa' },
  { id: '4066650', name: "Zach Collins", position: 'PF', teamId: 'sa' },
  { id: '4397107', name: "David Duke Jr.", position: 'G', teamId: 'sa' },
  { id: '3133601', name: "Devonte' Graham", position: 'PG', teamId: 'sa' },
  { id: '4277850', name: "RaiQuan Gray", position: 'F', teamId: 'sa' },
  { id: '4395723', name: "Keldon Johnson", position: 'SF', teamId: 'sa' },
  { id: '4395626', name: "Tre Jones", position: 'PG', teamId: 'sa' },
  { id: '4278580', name: "Sandro Mamukelashvili", position: 'PF', teamId: 'sa' },
  { id: '3893016', name: "Cedi Osman", position: 'SF', teamId: 'sa' },
  { id: '4610139', name: "Jeremy Sochan", position: 'F', teamId: 'sa' },
  { id: '4395630', name: "Devin Vassell", position: 'SG', teamId: 'sa' },
  { id: '5104157', name: "Victor Wembanyama", position: 'C', teamId: 'sa' },
  { id: '4683935', name: "Blake Wesley", position: 'G', teamId: 'sa' },
];
