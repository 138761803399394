<template>
  <div class="page-container">
    <div class="cards-container">
      <div class="img-container">
        <div>
          <NText :strong="true"> <b>{{ player.name }}</b> ({{ player?.position }}) | <span :style="getColor(player.teamId)">{{ formatTeam(player.teamId) }}</span></NText>
          <img :src="`https://a.espncdn.com/combiner/i?img=/i/headshots/mlb/players/full/${player.id}.png&w=138&h=100`">
        </div>
        <NText v-if="playerStatus === 'active'" type="success" class="status"><b>ACTIVE</b></NText>
        <NText v-else-if="playerStatus === 'gtd'" type="warning" class="status"><b>GTD</b></NText>
        <NText v-else-if="playerStatus === 'out'" type="error" class="status"><b>OUT</b></NText>
      </div>

      <DataTable
        :data="averagesTableData"
        :columns="averagesTableColumns"
        :loading="averagesTableLoading"
      />

      <NSpace>
        <NRadio class="no-print" :checked="rateTableStat === 'AB'" value="AB" name="stat" @change="handleChangeRateTableStat">AB</NRadio>
        <NRadio class="no-print" :checked="rateTableStat === 'R'" value="R" name="stat" @change="handleChangeRateTableStat">R</NRadio>
        <NRadio class="no-print" :checked="rateTableStat === 'H'" value="H" name="stat" @change="handleChangeRateTableStat">H</NRadio>
        <NRadio class="no-print" :checked="rateTableStat === 'TWOB'" value="TWOB" name="stat" @change="handleChangeRateTableStat">2B</NRadio>
        <NRadio class="no-print" :checked="rateTableStat === 'THREEB'" value="THREEB" name="stat" @change="handleChangeRateTableStat">3B</NRadio>
        <NRadio class="no-print" :checked="rateTableStat === 'HR'" value="HR" name="stat" @change="handleChangeRateTableStat">HR</NRadio>
        <NRadio class="no-print" :checked="rateTableStat === 'RBI'" value="RBI" name="stat" @change="handleChangeRateTableStat">RBI</NRadio>
        <NRadio class="no-print" :checked="rateTableStat === 'BB'" value="BB" name="stat" @change="handleChangeRateTableStat">BB</NRadio>
        <NRadio class="no-print" :checked="rateTableStat === 'HBP'" value="HBP" name="stat" @change="handleChangeRateTableStat">HBP</NRadio>
        <NRadio class="no-print" :checked="rateTableStat === 'SO'" value="SO" name="stat" @change="handleChangeRateTableStat">SO</NRadio>
        <NRadio class="no-print" :checked="rateTableStat === 'SB'" value="SB" name="stat" @change="handleChangeRateTableStat">SB</NRadio>
        <NRadio class="no-print" :checked="rateTableStat === 'CS'" value="CS" name="stat" @change="handleChangeRateTableStat">CS</NRadio>
      </NSpace>

      <DataTable
        :data="rateTableData"
        :columns="rateTableColumns"
        :loading="rateTableLoading"
        :render-cell="renderFormattedCell"
      />

      <div class="chart" ref="chartContainer"></div>
    </div>
  </div>
</template>

<script setup>
/* eslint-disable */
import { h, ref, computed, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { NText, NRadio, NSpace } from 'naive-ui';
import _ from 'lodash';

import { teams, players } from '@/assets/mlb_data';
import { scatterChart } from '@/charts/scatter-chart';
import { formatTeam } from '@/util/util_mlb';
import DataTable from '@/components/DataTable';

const route = useRoute();
const { id } = route.params;
const player = players.find((player) => player.id === id);

const apiData = ref({});

const chartContainer = ref(null);

const rateTableStat = ref('AB');
const rateTableData = ref([]);
const rateTableColumns = ref([]);
const rateTableLoading = ref(true);

const averagesTableData = ref([]);
const averagesTableColumns = ref([]);
const averagesTableLoading = ref(true);

const playerStatus = computed(() => {
  return apiData?.value?.meta?.status?.toLowerCase();
});

const getColor = (teamId) => {
  const useTertiary = [
    'tor',
  ];
  const useSecondary = [
    'det',
    'chw',
    'kc',
    'min',
    'chi',
    'chc',
    'mil',
    'nyy',
    'tb',
    'atl',
    'nym',
    'col',
    'tex',
    'hou',
    'sea',
  ];

  const team = teams.find((team) => team.id === teamId);

  if (useTertiary.includes(team.id)) {
    return `color: ${team.colors[2]};`;
  }

  if (useSecondary.includes(team.id)) {
    return `color: ${team.colors[1]};`;
  }

  return `color: ${team.colors[0]};`;
};

const handleChangeRateTableStat = (e) => {
  rateTableStat.value = e.target.value;
  setRateTable();
  renderChart({});
};

const setRateTable = () => {
  const rateTable = apiData.value?.rate_tables.find((table) => {
    return table.key === rateTableStat.value;
  });

  rateTableData.value = rateTable.data;
  rateTableColumns.value = [
    { title: rateTable.name, key: 'rate', align: 'center', className: 'headers' },
    { title: 'L10', key: 'L10HitPercent', align: 'center' },
    { title: 'L9', key: 'L9HitPercent', align: 'center' },
    { title: 'L8', key: 'L8HitPercent', align: 'center' },
    { title: 'L7', key: 'L7HitPercent', align: 'center' },
    { title: 'L6', key: 'L6HitPercent', align: 'center' },
    { title: 'L5', key: 'L5HitPercent', align: 'center' },
    { title: 'L4', key: 'L4HitPercent', align: 'center' },
    { title: 'L3', key: 'L3HitPercent', align: 'center' },
    { title: 'L2', key: 'L2HitPercent', align: 'center' },
    { title: 'L1', key: 'L1HitPercent', align: 'center' },
  ];
  rateTableLoading.value = false;
}

const setAveragesTable = () => {
  averagesTableData.value = apiData.value?.averages;
  averagesTableColumns.value = [
    { title: 'Stat', key: 'stat', align: 'right' },
    { title: 'Season Average', key: 'avg', align: 'center' },
    { title: 'Last 10 Average', key: 'L10Avg', align: 'center' },
    { title: 'Last 5 Average', key: 'L5Avg', align: 'center' },
    { title: 'Last 3 Average', key: 'L3Avg', align: 'center' },
  ];
  averagesTableLoading.value = false;
};

const renderFormattedCell = (value, rowData, column) => {
  const plainValue = value.split('%')
  const parsedValue = parseFloat(plainValue)

  if (column.className === 'headers') {
    const rowValues = Object.values(rowData);
    rowValues.shift();
    if (rowValues.every((datum) => datum === '100%')) {
      return h(NText, { depth: 2, class: 'l100' }, { default: () => value });
    }
    return h(NText, { depth: 2 }, { default: () => value });
  }

  let className = '';

  if (parsedValue >= 100) {
    className = 'l10';
  } else if (parsedValue >= 90) {
    className = 'l9';
  } else if (parsedValue >= 80) {
    className = 'l8';
  } else if (parsedValue >= 70) {
    className = 'l7';
  } else if (parsedValue >= 60) {
    className = 'l6';
  }  else if (parsedValue === 50) {
    className = '';
  } else if (parsedValue >= 40) {
    className = 'l5';
  } else if (parsedValue >= 30) {
    className = 'l4';
  } else if (parsedValue >= 20) {
    className = 'l3';
  } else if (parsedValue >= 10) {
    className = 'l2';
  } else {
    className = 'l1';
  }

  return h(NText, { depth: 1, class: className }, { default: () => value });
};

const renderChart = async({ stats }) => {
  if (!apiData.value?.charts?.scatter) {
    return;
  }

  if (rateTableStat.value === 'TWOB') {
    stats = ['2B'];
  }

  if (rateTableStat.value === 'THREEB') {
    stats = ['3B'];
  }

  stats = stats ? stats : [rateTableStat.value];

  const data = [...apiData.value.charts.scatter].filter((group) => stats.includes(group.name));

  if (!data.length) {
    return;
  }

  const groupValues = data.map((group) => group.values);
  const flattenedValues = _.flatten(groupValues);
  const max = _.maxBy(flattenedValues, (o) => o.value);
  const min = _.minBy(flattenedValues, (o) => o.value);

  const adjustedMax = max.value + 2;
  const adjustedMin = min.value - 5 < 0 ? 0 : min.value - 5;

  const svg = scatterChart(data, {
    xAccessor: 'key',
    yAccessor: 'value',
    yExtent: [adjustedMin, adjustedMax],
    stats,
  });

  chartContainer.value.appendChild(svg);
};

const api = async () => {
  const endpoint = process.env.VUE_APP_INTERNAL_API_ENDPOINT;

  const params = new URLSearchParams({
    id: player.id || '39832',
    name: player.name || 'shohei-ohtani',
  });

  const url = `${endpoint}/api/mlb/player?${params}`;
  const response = await fetch(url);

  return await response.json();
};

const tryFetch = async () => {
  const data = await api();
  apiData.value = data;

  setRateTable();
  setAveragesTable();
};

onMounted(async () => {
  await tryFetch();
  renderChart({});
});
</script>

<style>
  div.tooltip {
    position: absolute;
    text-align: center;
    padding: .2rem;
    background: #313639;
    color: #f9f9f9;
    border: 0px;
    pointer-events: none;
  }
  .img-container {
    height: 100px;
    font-size: 18px;
    border-bottom: 2px solid #3f3f3f;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }
  .chart {
    margin-top: 10px;
    border: 1px solid #3f3f3f;
    border-radius: 3px;
    background-color: #18181c;
  }
  .n-data-table .n-data-table-td:has(.n-text.l1) {
    background-color: #87899A;
  }
  .n-data-table .n-data-table-td:has(.n-text.l2) {
    background-color: #787A8E;
  }
  .n-data-table .n-data-table-td:has(.n-text.l3) {
    background-color: #696B81;
  }
  .n-data-table .n-data-table-td:has(.n-text.l4) {
    background-color: #5A5D75;
  }
  .n-data-table .n-data-table-td:has(.n-text.l5) {
    background-color: #4B4E68;
  }
  .n-data-table .n-data-table-td:has(.n-text.l6) {
    background-color: #3C3F5B;
  }
  .n-data-table .n-data-table-td:has(.n-text.l7) {
    background-color: #2D304F;
  }
  .n-data-table .n-data-table-td:has(.n-text.l8) {
    background-color: #1E2242;
  }
  .n-data-table .n-data-table-td:has(.n-text.l9) {
    background-color: #0F1336;
  }
  .n-data-table .n-data-table-td:has(.n-text.l10) {
    background-color: #000429;
  }
  .button {
    position: absolute;
    top: 0;
  }
  @media print {
    .no-print {
      display: none;
    }
  }
</style>
